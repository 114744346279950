import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCard from '@components/ProductCard';
import {
  CartProductItem,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION,
  PRODUCT_VERSION_ID,
} from '@utils/types/store';
import LabelWithTooltip from '@components/LabelWithTooltip';
import Select from '@components/Select';
import Counter from '@components/Counter';
import { multiply, noop } from 'lodash';
import { toast } from 'react-toastify';
import ToastCart from '@components/ToastCart';
import { dataLayerPushAddToCart } from '@utils/tagManagerHelper';

type Props = {
  handleAddCart: (CartProductItem: CartProductItem) => void;
  renewOrUpgrade?: string;
  onlyProVersionProducts?: any;
  showPromoDesc: boolean;
};

const UraniumProProductCard = ({
  handleAddCart,
  renewOrUpgrade,
  onlyProVersionProducts,
  showPromoDesc,
}: Props) => {
  const { t } = useTranslation(['common', 'uraniumBackup']);
  const [isUpgrade, setIsUpgrade] = React.useState<string | boolean>();
  const [onlyProVersion] = React.useState(onlyProVersionProducts);
  const [productDetails, setProductDetails] = React.useState<ProductVersion>(
    onlyProVersionProducts.find(
      (el: ProductVersion) =>
        el?.id ===
        (renewOrUpgrade === 'UPGRADE'
          ? PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_TAPE
          : PRODUCT_VERSION_ID.PRO_TAPE)
    )
  );

  const versionPro = [
    {
      label: 'Tape',
      id:
        renewOrUpgrade === 'UPGRADE'
          ? PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_TAPE
          : PRODUCT_VERSION_ID.PRO_TAPE,
    },
    {
      label: 'DB',
      id:
        renewOrUpgrade === 'UPGRADE'
          ? PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_DB
          : PRODUCT_VERSION_ID.PRO_DB,
    },
    {
      label: 'Shadow',
      id:
        renewOrUpgrade === 'UPGRADE'
          ? PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_SHADOW
          : PRODUCT_VERSION_ID.PRO_SHADOW,
    },
  ];

  const [versionValue, setVersionValue] = React.useState({
    productID: productDetails?.id,
    id: versionPro[0].id,
    label: versionPro[0].label,
  });

  const versionInfoTooltip = '';

  const uraniumProFeatures = React.useMemo(
    () => [
      t('uraniumBackup:featuresList.schedulerLogEmail'),
      t('uraniumBackup:featuresList.basicFunctions'),
      t('uraniumBackup:featuresList.ftpBkpDriveImageCloud'),
      t('uraniumBackup:featuresList.tapeBkp'),
    ],
    [t]
  );

  const onChooseVersion = React.useCallback(
    ({ id, label }: { id: number; label: string }) => {
      const matchedProduct = onlyProVersion.find((el: any) => el.id === id);
      setProductDetails(matchedProduct);
      setVersionValue({ productID: id, id, label });
    },
    [onlyProVersion]
  );

  const [count, setCount] = React.useState(1);

  const finalPrice = multiply(productDetails?.price, count)?.toLocaleString(
    'it-IT'
  );

  const handleAddCartFn = React.useCallback(() => {
    const name = `${productDetails?.name}`;
    if (isUpgrade === true) {
      handleAddCart({
        productType: PRODUCT_TYPE.URANIUM,
        id: Number(versionValue?.id) ?? productDetails.id,
        quantity: count,
        isCumulable: productDetails?.isCumulable,
        upgradeId: productDetails?.upgradeId,
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: productDetails.upgradeId,
      });
      toast(<ToastCart />, {
        data: {
          quantity: count,
          price: finalPrice,
          title: name,
        },
      });
      dataLayerPushAddToCart(productDetails?.id, name, productDetails?.price, count);
    } else {
      handleAddCart({
        productType: PRODUCT_TYPE.URANIUM,
        id: Number(versionValue?.id) ?? productDetails.id,
        quantity: count,
        isCumulable: productDetails?.isCumulable,
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: productDetails.upgradeId,
      });
      toast(<ToastCart />, {
        data: {
          quantity: count,
          price: productDetails?.price,
          title: name,
        },
      });
      dataLayerPushAddToCart(productDetails?.id, name, productDetails?.price, count);
    }
  }, [
    renewOrUpgrade,
    handleAddCart,
    versionValue?.id,
    productDetails.id,
    productDetails?.isCumulable,
    productDetails?.price,
    productDetails?.name,
    count,
    isUpgrade,
    finalPrice,
    productDetails.upgradeId,
  ]);

  // USE EFFECT

  React.useEffect(() => {
    const isUpgradeRenew =
      renewOrUpgrade === 'UPGRADE'
        ? true
        : renewOrUpgrade === 'RENEW'
        ? false
        : 'shop';

    setIsUpgrade(isUpgradeRenew);
  }, [renewOrUpgrade]);

  return (
    <ProductCard
      showPromoDesc={showPromoDesc}
      onAddCart={handleAddCartFn}
      description={productDetails?.description}
      key={productDetails?.id}
      price={finalPrice}
      monthlyPrice={productDetails?.price}
      id={productDetails?.id}
      promoDesc={productDetails?.promoDesc}
      upgradeId={productDetails?.upgradeId}
      currency={<span>&euro;</span>}
      isUpgrade={isUpgrade}
      showPricePerYear={false}
      subscriptionDurationType={t('common:shops.forever')}
      version={PRODUCT_VERSION.PRO}
      features={uraniumProFeatures}
      versionLabel={
        <LabelWithTooltip
          text={t('common:shops.versions')}
          tooltipOverlay={versionInfoTooltip}
        />
      }
      versions={
        <Select
          options={versionPro}
          onChange={onChooseVersion}
          value={{
            id: versionValue?.id ?? 0,
            label: versionValue?.label ?? '',
          }}
        />
      }
      counter={
        <Counter
          count={count}
          onChange={setCount}
          onAdd={setCount}
          onRemove={count > 1 ? setCount : noop}
        />
      }
    />
  );
};

export default UraniumProProductCard;

import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';
import * as React from 'react';
import classNames from 'classnames';
type Props = WithChildren<{
  onCheck: (check: boolean) => void;
  isChecked: boolean;
  disabled?: boolean;
  error?: boolean;
}>;

export default function Checkbox({
  onCheck,
  isChecked,
  children,
  disabled,
  error,
}: Props) {
  const onChangeFn = React.useCallback(() => {
    onCheck(!isChecked);
  }, [onCheck, isChecked]);
  return (
    <label
      className={classNames(styles.container, { [styles.disabled]: disabled })}
    >
      {children}
      <input
        type="checkbox"
        disabled={disabled}
        onChange={onChangeFn}
        checked={isChecked}
      />
      <span className={classNames(styles.checkmark, { [styles.checkmarkError]: error })}></span>
    </label>
  );
}

import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_STYLE } from '@components/Button';
import { camelCase, startCase } from 'lodash';
import 'rc-tooltip/assets/bootstrap_white.css';
import {
  PRODUCT_TYPE,
  PRODUCT_VERSION,
  PRODUCT_VERSION_ID,
} from '@utils/types/store';
import { forceToFloat, formatDate, formatPrice } from '@utils/types/helpers';
import Spinner from '@components/Spinner';
import { CartContext } from '@contexts/CartContext';
import { SUBSCRIPTION_DURATION } from '@services/normalize';

type Props = {
  onAddCart?: () => void;
  price: string;
  version?: PRODUCT_VERSION;
  goldVersion?: any;
  isActive?: boolean;
  isHorizontal?: boolean;
  logo?: string;
  id: number;
  versions?: any;
  versionLabel?: any;
  description?: string;
  features?: string[];
  connections?: any;
  connectionsLabel?: any;
  counter?: any;
  teamAssistance?: any;
  teamLabel?: any;
  priceType?: string | null;
  monthlyPrice?: number;
  currency?: any;
  showPricePerYear?: boolean;
  subscriptionDurationType?: string;
  isLoadingUpgradePrice?: boolean;
  expirationDate?: string;
  isUpgrade?: string | boolean;
  upgradeId?: number;
  isAusilio?: boolean;
  promoDesc?: string;
  showPromoDesc?: boolean;
  subscription?: SUBSCRIPTION_DURATION;
  priceDesc?: string;
};

const ProductCard = ({
  onAddCart,
  subscription,
  isActive,
  version,
  goldVersion = false,
  isAusilio = false,
  description,
  features,
  price,
  connections,
  connectionsLabel,
  versions,
  versionLabel,
  isHorizontal = false,
  logo,
  teamAssistance,
  teamLabel,
  showPromoDesc,
  priceType,
  currency,
  upgradeId,
  id,
  monthlyPrice,
  subscriptionDurationType,
  isLoadingUpgradePrice,
  showPricePerYear,
  counter,
  expirationDate,
  isUpgrade,
  promoDesc,
  priceDesc
}: Props) => {
  const { t } = useTranslation([
    'routes',
    'common',
    'shop',
    'uraniumBackup',
    'license',
    'supremoControl',
  ]);

  const { cartItems } = React.useContext(CartContext);

  function renderFeatures(featuresItem: any) {
    return featuresItem.map((singleFeature: any, key: number) => {
      return (
        <li key={key} className={styles.featuresItem}>
          {singleFeature}
        </li>
      );
    });
  }
  const notSupremoIdsFromCart = cartItems
    .filter((item) => item.productType !== PRODUCT_TYPE.SUPREMO)
    .map((el) => {
      return {
        id: el?.id,
        upgradeId: el?.fromLicense,
      };
    });

  const matchedIdsFromCart = cartItems
    .filter((item) => item.productType === PRODUCT_TYPE.SUPREMO)
    .map((el) => {
      return {
        id: isUpgrade ? el?.matchedId : el?.id,
        upgradeId: el?.fromLicense,
      };
    });

  const allIds = [...notSupremoIdsFromCart, ...matchedIdsFromCart];
  const filtered = allIds.filter((el) => el.id !== undefined);

  const checkIfIsProductAlreadyInCart = React.useCallback(
    (products: any) =>
      products.some(
        (product: any) => product.id === id && product.upgradeId === upgradeId
      ),
    [id, upgradeId]
  );

  const alreadyInCart = (isUpgrade !== 'shop' && isUpgrade !== undefined && filtered && checkIfIsProductAlreadyInCart(filtered));

  return (
    <div
      className={`${!isHorizontal
        ? styles.container
        : `${styles.container} ${styles.isHorizontal}`
        } ${isActive && isUpgrade === 'shop' ? styles.active : ''}`}
    >
      <div
        className={`${!isHorizontal ? styles.body : `${styles.body} ${styles.isHorizontal}`
          }`}
      >
        <div
          className={`${styles.header}  ${isAusilio ? styles.ausilioMaxWidth : ''
            }`}
        >
          {logo && (
            <div className={styles.savingsHorizontal}>
              <img
                style={{ maxWidth: '347px' }}
                className={styles.logoProduct}
                src={logo}
                alt="logo"
              />
              {isAusilio && (
                <>
                  {promoDesc !== '' && isHorizontal && (
                    <div className={styles.savings} style={{ marginBottom: 0 }}>
                      <p>{promoDesc}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {promoDesc !== '' && showPromoDesc && !isHorizontal && (
            <div className={styles.savings}>
              <p>{promoDesc}</p>
            </div>
          )}

          {promoDesc === '' && showPromoDesc && !isHorizontal && (
            <div className={styles.savings} style={{ visibility: 'hidden' }}>
              <p>Tera sconto</p>
            </div>
          )}

          {version && !goldVersion && !isAusilio && (
            <h4>{startCase(camelCase(version))}</h4>
          )}
          {goldVersion && (
            <>
              <div className={styles.savingsHorizontal}>
                <h4 className={styles.version}>{goldVersion}</h4>

                {promoDesc !== '' && isHorizontal && (
                  <div className={styles.savings} style={{ marginBottom: 0 }}>
                    <p>{promoDesc}</p>
                  </div>
                )}
              </div>
              {isUpgrade === 'shop' && (
                <span className={styles.descriptionHorizontal}>
                  {description}
                </span>
              )}
            </>
          )}
          {isAusilio && (
            <>
              <p className={styles.descriptionHorizontal}>{description}</p>
            </>
          )}
        </div>

        {isLoadingUpgradePrice ? (
          <Spinner isSmall isGrey />
        ) : (
          <p
            className={`${styles.price} ${isLoadingUpgradePrice ? styles.flexCenter : ''
              }`}
          >
            <span>
              {monthlyPrice && Number(monthlyPrice).toFixed()}
              {currency && currency}
            </span>
            <span className={styles.perMonth}>
              {subscriptionDurationType &&
                subscriptionDurationType.toLowerCase()}
            </span>
          </p>
        )}
        <p className={styles.perYear}>
          {showPricePerYear &&
            subscription !== 3 &&
            t('common:shops.calculationPerYear', {
              value: (subscription ? formatPrice(forceToFloat(price) / ((subscription || 12) / 12), 'EUR', 0, 2, false) : price),
            })}
          {showPricePerYear &&
            subscription === 3 &&
            t('common:shops.calculationPerQuarter', {
              value: price,
            })}
          {priceDesc && priceDesc?.length > 0 && <><br />{priceDesc}</>}
        </p>

        {!isHorizontal && (
          <div className={styles.options}>
            {teamAssistance && teamLabel && (
              <div className={styles.assistance}>
                {teamLabel}
                {teamAssistance}
              </div>
            )}
            {connectionsLabel && connections && (
              <div className={styles.connections}>
                {connectionsLabel}
                {connections}
              </div>
            )}
            {versionLabel && versions && (
              <div
                className={`${styles.connections} ${version === PRODUCT_VERSION.PRO ? styles.noMargin : ''
                  }`}
              >
                {versionLabel}
                {versions}
              </div>
            )}
            {
              <>
                {!versions &&
                  (version === PRODUCT_VERSION.BASE ||
                    version === PRODUCT_VERSION.PRO_VIRTUAL ||
                    version === PRODUCT_VERSION.GOLD ||
                    version === PRODUCT_VERSION.AUSILIO) && (
                    <div className={`${styles.connections} ${styles.noMargin}`}>
                      <div>
                        <p>{t('common:shops.versions')}</p>
                      </div>
                      <p className={styles.nameVersions}>
                        {version === PRODUCT_VERSION.BASE
                          ? 'Base'
                          : version === PRODUCT_VERSION.PRO_VIRTUAL
                            ? 'Pro Virtual'
                            : version === PRODUCT_VERSION.GOLD
                              ? 'Gold'
                              : 'Uranium Backup Professional'}
                      </p>
                    </div>
                  )}
              </>
            }
          </div>
        )}
      </div>
      <div
        className={`${!isHorizontal
          ? styles.footer
          : `${styles.footer} ${styles.isHorizontal}`
          } ${version === PRODUCT_VERSION.BASE ||
            version === PRODUCT_VERSION.PRO_VIRTUAL ||
            version === PRODUCT_VERSION.PRO
            ? styles.lessMargin
            : ''
          }    ${isUpgrade !== 'shop' &&
            isUpgrade !== undefined &&
            (PRODUCT_VERSION_ID.GOLD || PRODUCT_VERSION_ID.AUSILIO)
            ? `${styles.lessMargin}`
            : ''
          }`}
      >
        <div
          className={`${styles.quantity} ${isUpgrade !== 'shop' && isUpgrade !== undefined
            ? styles.flexEnd
            : ''
            }`}
        >
          {isUpgrade !== 'shop' && isUpgrade !== undefined ? (
            <></>
          ) : (
            <div style={{ flex: 1 }}>
              {!isHorizontal && <p> {t('common:shops.quantity')}</p>}
              {counter && counter}
            </div>
          )}
          <div>
            {!isHorizontal && <p> {t('common:shops.total')}</p>}
            <p
              className={styles.total}
              style={{ marginLeft: !isHorizontal ? 0 : 50 }}
            >
              {price ? price : <Spinner isSmall />} {currency}
            </p>
          </div>
        </div>
        <Button
          onClick={onAddCart}
          styleBtn={BUTTON_STYLE.PRIMARY}
          fullWidth={!isHorizontal ? true : false}
          disabled={alreadyInCart || Number(price) === 0}
        >
          {t(alreadyInCart ? 'common:shops.alreadyInCart' : 'common:shops.addToCart')}
        </Button>
        {!isHorizontal && (
          <div
            className={
              version === PRODUCT_VERSION.BASE ||
                version === PRODUCT_VERSION.PRO_VIRTUAL ||
                version === PRODUCT_VERSION.PRO
                ? styles.minHeight
                : ''
            }
          >
            <p className={styles.description}>{description}</p>
          </div>
        )}

        {features && (
          <ul className={styles.featuresList}>{renderFeatures(features)}</ul>
        )}

        {isUpgrade !== 'shop' && isUpgrade !== undefined && (
          <>
            {expirationDate && (
              <div className={styles.futureExpiration}>
                <p>
                  {t('license:nextEpirationDate', {
                    date: formatDate(new Date(expirationDate)),
                  })}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;

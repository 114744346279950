import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import styles from './styles.module.scss';

import Button from '@components/Button';
import Input, { INPUT_SIZE } from '@components/Input';
import Spinner from '@components/Spinner';

import { emailRegex } from '@utils/formUtils';
import { registerPhase1Response } from '@utils/types/user';

type Props = {
  onSubmit: any;
  isLoading: boolean;
  errorsFromApi?: string[] | null;
  userInfoPhase1?: registerPhase1Response | null;
  isError: boolean;
};

export default function MyAccountFormUserInfo({
  onSubmit,
  isLoading,
  errorsFromApi,
  userInfoPhase1,
  isError
}: Props) {
  const { t } = useTranslation(['forms']);

  const {
    watch,
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
    password: boolean;
    username: boolean;
    confirmPassword: boolean;
  }>({
    email: false,
    password: false,
    username: false,
    confirmPassword: false,
  });

  const password = watch('password');
  const email = watch('email');

  React.useEffect(() => {
    if (userInfoPhase1) {
      setValue('username', userInfoPhase1.username);
      setValue('email', userInfoPhase1.email);
      setValue('password', '');
    }
  }, [userInfoPhase1, setValue]);

  const { ref: usernameRef, ...usernameInputProps } = register('username', {
    required: t('forms:errorsState.required.username')
  });

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('forms:errorsState.required.email'),
    pattern: {
      value: emailRegex,
      message: t('forms:errorsState.invalid.email'),
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: false,
    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.passwordMinLength'),
    },
  });

  const { ref: confirmPasswordRef, ...confirmPasswordInputProps } = register(
    'confirmPassword',
    {
      validate: (value) =>
        value === password || t('forms:errorsState.invalid.passwordMismatch'),
      minLength: {
        value: 6,
        message: t('forms:errorsState.invalid.passwordMinLength'),
      },
    }
  );

  React.useEffect(() => {
    errorsFromApi
      ? errorsFromApi?.forEach((element: string) => {
        const message = element
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (t(`forms:errorsState.errorsFromApi.${element}`) as string)
          : t('forms:errorsState.errorsFromApi.generic');
        setError(element, { type: 'error', message });
      })
      : clearErrors();
  }, [setError, errorsFromApi, t, clearErrors]);

  const normalizeFormData = (formData: any) => {
    if (email === userInfoPhase1?.email) {
      delete formData.email;
    }
  };

  const onSubmitForm = ({
    email,
    password,
    username,
    confirmPassword,
  }: {
    email: string;
    password: string;
    username: string;
    confirmPassword: string;
  }) => {
    setIsDirty({
      email: email.length > 0,
      password: password.length > 0,
      confirmPassword: confirmPassword.length > 0,
      username: username.length > 0,
    });

    const formData = { email, password, username };

    normalizeFormData(formData);
    onSubmit(formData);
  };

  return (
    <div className={styles.container}>
      <form key={1} onSubmit={handleSubmit(onSubmitForm as any)}>
        <Input
          label={t('forms:label.username')}
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          hasValue={isDirty?.username ?? false}
          type="text"
          placeholder={t('forms:placeholder.username')}
          inputRef={usernameRef}
          {...usernameInputProps}
          error={errors?.username?.message as any}
          isRequired
          readOnly
          isHorizontal
          defaultValue={userInfoPhase1?.username}
        />

        <Input
          label={t('forms:label.email')}
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          hasValue={isDirty?.email ?? false}
          type="email"
          placeholder={t('forms:placeholder.email')}
          inputRef={emailRef}
          {...emailInputProps}
          error={errors?.email?.message as any}
          isRequired
          isHorizontal
          defaultValue={get(userInfoPhase1, 'email')}
        />

        <Input
          label={t('forms:label.password')}
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          hasValue={isDirty?.password ?? false}
          type="password"
          placeholder={t('forms:placeholder.password')}
          inputRef={passwordRef}
          {...passwordInputProps}
          error={errors?.password?.message as any}
          isHorizontal
        />

        <Input
          label={t('forms:label.confirmPassword')}
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          hasValue={isDirty?.confirmPassword ?? false}
          type="password"
          placeholder={t('forms:placeholder.confirmPassword')}
          inputRef={confirmPasswordRef}
          {...confirmPasswordInputProps}
          error={errors?.confirmPassword?.message as any}
          isHorizontal
        />

        <Button
          disabled={
            userInfoPhase1?.email === email &&
            userInfoPhase1?.password === password
          }
        >
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner isWhite isSmall />
            </div>
          ) : (
            t('forms:update')
          )}
        </Button>
      </form>
      {isError && (
        <p className={styles.error}>{t('forms:errorsState.general.submit')}</p>
      )}
    </div>
  );
}

import { useMutation } from '@tanstack/react-query';
import { IUpgradeProductForEvalPriceResponse } from '@utils/types/licenses';
import { noop } from 'lodash';
import { addUpgradeToCart } from '@services/api';
import { ProductVersion } from '@utils/types/store';
import { useTranslation } from 'react-i18next';

const KEY = 'upgradePriceEval';
type Props = {
  product: ProductVersion;
  connections: number;
  teamId: number;
};
export const useUpgradeAddToCart = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<IUpgradeProductForEvalPriceResponse, Error, Props>(
      [KEY],
      async ({
        product,
        connections,
        teamId,
      }: Props): Promise<IUpgradeProductForEvalPriceResponse> => {
        const data = await addUpgradeToCart(
          currentLanguage,
          product,
          connections,
          teamId
        );

        return data;
      },
      { onError, onSuccess }
    ),
  };
};

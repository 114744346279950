import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input, { INPUT_SIZE } from '@components/Input';
import styles from './styles.module.scss';
import Button from '@components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '@components/Spinner';
import { emailRegex } from '@utils/formUtils';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import { ResponseError } from '@utils/types/generic';

type Props = {
  isLoading: boolean;
  isError?: boolean;
  errorFromApi?: ResponseError | null;
  isEmailSent: boolean;

  onSubmit: ({
    email,
    recaptcha = '',
  }: {
    email: string;
    recaptcha: string;
  }) => void;
};

export default function PasswordRecoveryForm({
  isLoading,
  isError,
  errorFromApi,
  isEmailSent,
  onSubmit
}: Props) {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });
  const isCompactCaptcha = windowWidth < 370;
  const [captchaToken, setCaptchaToken] = React.useState<string>('');

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
  }>({
    email: false,
  });

  const { t } = useTranslation(['forms', 'routes']);

  function handleOnChange(value: any) {
    setCaptchaToken(value);
  }

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('forms:errorsState.required.email'),
    pattern: {
      value: emailRegex,
      message: t('forms:errorsState.invalid.email'),
    },
  });

  function handleOnSubmit({ email }: { email: string }) {
    setIsDirty({ email: email.length > 0 });

    if (captchaToken) {
      onSubmit({ email, ...{ recaptcha: captchaToken } });
    } else {
      onSubmit({ email, recaptcha: '' });
    }
  }

  React.useEffect(() => {
    if (isError) {
      recaptchaRef.current?.reset();
    }
  }, [isError]);

  return (
    <>
      <div className={`${styles.container} container`}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <Input
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            label={t('forms:label.email')}
            hasValue={isDirty?.email ?? false}
            type="email"
            placeholder={t('forms:placeholder.email')}
            inputRef={emailRef}
            {...emailInputProps}
            error={errors?.email?.message as any}
          />

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            onChange={handleOnChange}
            className={styles.recaptcha}
            size={isCompactCaptcha ? 'compact' : 'normal'}
          />

          <Button
            style={{ marginTop: '24px' }}
            fullWidth
            disabled={!captchaToken || isLoading || isEmailSent}
          >
            {isLoading ? (
              <div className={styles.spinnerContainer}>
                <Spinner isWhite isSmall />
              </div>
            ) : (
              t('forms:signIn')
            )}
          </Button>
        </form>

        {isEmailSent && (
          <p className={styles.success}>
            {t('forms:submitedState.emailSent')}{' '}
            <span className="icon-check-circle"></span>
          </p>
        )}
        {isError && (
          <p className={styles.error}>
            {errorFromApi ? errorFromApi.message : t('forms:errorsState.general.submit')}
          </p>
        )}
      </div>
    </>
  );
}

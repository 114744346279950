import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styles from './styles.module.scss';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import PurchasesTable from '@components/PurchasesTable';
import { usePurchases } from '@hooks/usePurchases';
import queryString from 'query-string';
import React from 'react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import Pagination from '@components/Pagination';
import { SORT_FIELD_PURCHASE, SORT_ORDER_LICENSE } from '@utils/types/licenses';

export default function Purchases() {
  const { t } = useTranslation(['common', 'routes', 'purchases', 'dashboard']);
  const ref = React.useRef<HTMLInputElement>(null);
  const { data, mutate: onGetPurchase, isLoading } = usePurchases();

  const location = useLocation();
  const navigate = useNavigate();
  const {
    page = 1,
    sortField = SORT_FIELD_PURCHASE.ID,
    sortDir = SORT_ORDER_LICENSE.DESC,
  } = queryString.parse(location?.search) as any;

  const [currentPaginationIndex, setCurrentPaginationIndex] = React.useState(
    Number(page)
  );

  const [currentQuery, setCurrentQuery] = React.useState('');
  const [queryValue, setQueryValue] = React.useState('');
  const [currentSortField, setCurrentSortField] =
    React.useState<string>(sortField);

  const [currentSortDir, setCurrentSortDir] = React.useState<string>(sortDir);

  //** Update all parameters on query string change */
  React.useEffect(() => {
    const {
      query = '',
      page = 1,
      sortField = SORT_FIELD_PURCHASE.ID,
      sortDir = SORT_ORDER_LICENSE.DESC,
    } = queryString.parse(location?.search) as any;
    setCurrentQuery(query);
    setCurrentPaginationIndex(Number(page));
    setCurrentSortField(sortField);
    setCurrentSortDir(sortDir);
  }, [location?.search]);

  React.useEffect(() => {
    onGetPurchase({
      query: currentQuery,
      itemsPerPage: 10,
      currentPage: Number(currentPaginationIndex),
      sortDir: currentSortDir as SORT_ORDER_LICENSE,
      sortField: currentSortField as SORT_FIELD_PURCHASE,
    });
  }, [
    currentQuery,
    currentPaginationIndex,
    currentSortDir,
    currentSortField,
    onGetPurchase,
  ]);

  const onChangePaginationFn = React.useCallback(
    (currentIndex = 1) => {
      navigate(
        {
          pathname: location?.pathname,
          search: `?${createSearchParams({
            page: currentIndex,
            query: currentQuery,
            sortDir,
            sortField,
          } as any)}`,
        },
        { replace: true }
      );
    },
    [location?.pathname, sortDir, sortField, currentQuery, navigate]
  );

  const onChangeSorting = React.useCallback(
    (sortField: string, sortDir: string) => {
      navigate(
        {
          pathname: location?.pathname,
          search: `?${createSearchParams({
            page,
            sortField: sortField,
            sortDir: sortDir,
            query: currentQuery,
          } as any)}`,
        },
        { replace: true }
      );
      setCurrentSortDir(sortDir);
      setCurrentSortField(sortField);
    },
    [location?.pathname, currentQuery, navigate, page]
  );

  const debouncedQuery = useDebouncedCallback(
    (query) => {
      setCurrentQuery(query);
      navigate(
        {
          pathname: location?.pathname,
          search: `?${createSearchParams({
            page: 1,
            query: query,
            sortField,
            sortDir,
          } as any)}`,
        },
        { replace: true }
      );
    },
    [],
    200
  );

  const onChangeQueryFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQueryValue(e?.target?.value ?? '');
      debouncedQuery(e?.target?.value ?? '');
    },
    [debouncedQuery]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection isCentered>
          <div className={styles.header}>
            <div>
              <Breadcrumbs
                data={[
                  { to: t('routes:dashboard'), label: 'Dashboard' },
                  { label: t('dashboard:purchases.title') },
                ]}
              />
              <h2>{t('dashboard:purchases.title')}</h2>
            </div>
            <div className={styles.searchContainer}>
              <input
                placeholder={t('purchases:placeholderInputQuery')}
                ref={ref}
                onChange={onChangeQueryFn}
                value={queryValue}
              />
              <span className="icon-magnifying-glass"></span>
            </div>
          </div>
          <div className={styles.container}>
            <PurchasesTable
              data={data?.purchases ?? []}
              isLoading={isLoading}
              onChangeSorting={onChangeSorting}
              currentSortDir={currentSortDir}
              currentSortField={currentSortField}
            />
            <div className={styles.pagination}>
              <Pagination
                pages={data?.maxPage ?? 0}
                currentPage={currentPaginationIndex}
                onChangeFn={onChangePaginationFn}
              />
            </div>
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import { InputHTMLAttributes } from 'react';
import styles from './styles.module.scss';
import { noop, omit } from 'lodash';
import { RefCallBack } from 'react-hook-form';
import classNames from 'classnames';

export enum INPUT_SIZE {
  SMALL = 'small',
  BIG = 'big',
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: RefCallBack;
  onChange?: typeof noop;
  error?: string;
  help?: string;
  prefix?: string;
  hasValue?: boolean;
  isFullWidth?: boolean;
  inputSize?: INPUT_SIZE;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  isHorizontal?: boolean;
  isRequired?: boolean;
}

export default function Input(props: InputProps) {
  const {
    error = '',
    help = '',
    prefix = '',
    isFullWidth = false,
    hasValue = false,
    onChange = noop,
    placeholder,
    inputSize,
    isDisabled = false,
    label,
    isHorizontal = false,
    isRequired = false,
  } = props;

  return (
    <div
      className={`${styles.container} ${isHorizontal ? styles.horizontal : ''}`}
    >
      <div
        className={`wrapper-input ${error ? 'error-input' : ''}  ${hasValue ? styles.isDirty : ''
          }`}
      >
        {label && (
          <label className={styles.inputLabel}>
            {label}
            {isRequired ? '*' : ''}
          </label>
        )}
        <div className={styles.inputGroup}>
          {prefix && <span className={styles.inputGroupPrefix}>{prefix}</span>}
          <input
            disabled={isDisabled}
            className={classNames(inputSize, {
              full: isFullWidth && prefix.length === 0,
              disabled: isDisabled,
              [styles.inputWithPrefix]: prefix.length > 0,
              [styles.error]: error,
            })}
            tabIndex={0}
            ref={props.inputRef}
            placeholder={placeholder}
            onChange={onChange}
            {...omit(props, [
              'inputRef',
              'hasValue',
              'error',
              'isFullWidth',
              'inputSize',
              'isDisabled',
              'isHorizontal',
              'isRequired',
            ])}
          />
        </div>

        {help ? (
          <label className={styles.inputHelp}>
            {help}
          </label>
        ) : null}
      </div>

      {error ? (
        <p className={isHorizontal ? styles.horizontalError : 'left'}>
          {error}
        </p>
      ) : null}
    </div>
  );
}

import Card from '@components/Card';
import OrderedTitle from '@components/OrderedTitle';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import supremoBkg from '@assets/images/supremoActivation.png';
import supremoBkg2 from '@assets/images/supremoActivation2.png';
import supremoProBkg4 from '@assets/images/supremoActivation4.png';
import supremoProBkg5 from '@assets/images/supremoActivation5.png';

import styles from './styles.module.scss';

type Props = {
  onGetActivationDate: ({
    id,
    isFromModal,
  }: {
    id: string;
    isFromModal: boolean;
  }) => void;
  onGetActivationCode: ({
    id,
    machineCode,
  }: {
    id: string;
    machineCode: string;
  }) => void;
  licenseActivationCode: string;
  id: string;
  expirationDate: string;
  isProfessional: boolean;
};
export default function ModalSupremoActivation({
  onGetActivationDate,
  onGetActivationCode,
  expirationDate,
  licenseActivationCode,
  isProfessional,
  id,
}: Props) {
  const { t } = useTranslation(['license', 'routes']);

  const { copy } = useCopyToClipboard();

  React.useEffect(() => {
    onGetActivationDate({
      id,
      isFromModal: true,
    });
  }, [onGetActivationDate, id]);

  const onGenerateActivationCodeFn = React.useCallback(() => {
    onGetActivationCode({ id, machineCode: '' });
  }, [id, onGetActivationCode]);

  return (
    <div className={styles.container}>
      <Card>
        <OrderedTitle
          number="1"
          title={t('license:supremo.step1.title')}
          /*url={t('routes:supremoControl')}*/
          url={t('links:supremo.download')}
          target='_self'
        />
      </Card>
      <Card>
        <OrderedTitle
          number="2"
          title={t('license:supremo.step2.title')}
          onClick={onGenerateActivationCodeFn}
        />

        <div className={styles.input}>
          <input
            readOnly
            value={licenseActivationCode}
            placeholder={
              licenseActivationCode
                ? licenseActivationCode
                : t('license:activationCodeLong')
            }
          />
          <div
            className={styles.copy}
            onClick={() => copy(licenseActivationCode)}
          >
            <span className="icon-copy"></span>{' '}
          </div>
        </div>
        <p className={styles.description}>
          {t('license:supremo.step2.description', {
            expiredDate: expirationDate,
          })}
        </p>
      </Card>
      <Card>
        <OrderedTitle number="3" title={t('license:supremo.step3.title')} />
        <div className={styles.imageContainer}>
          <img src={supremoBkg} alt="Supremo activation" />
        </div>
      </Card>
      <Card>
        <OrderedTitle number="4" title={t('license:supremo.step4.title')} />
        <div className={styles.imageContainer}>
          <img src={supremoBkg2} alt="Supremo activation" />
        </div>
      </Card>
      {isProfessional && (
        <>
          <Card>
            <OrderedTitle
              number="5"
              title={t('license:supremoProfessional.step5.title')}
              url={t('links:console.home')}
            />
          </Card>
          <Card>
            <OrderedTitle
              number="6"
              title={t('license:supremoProfessional.step6.title')}
              url={t('links:console.home')}
            />
            <div
              className={styles.imageContainer}
              style={{ justifyContent: 'flex-start' }}
            >
              <img
                src={supremoProBkg4}
                alt="Supremo pro activation"
              />
            </div>
          </Card>
          <Card>
            <OrderedTitle
              number="7"
              title={t('license:supremoProfessional.step7.title')}
              url={t('links:console.home')}
            />
            <div className={styles.imageContainer}>
              <img src={supremoProBkg5} alt="Supremo pro activation" />
            </div>
          </Card>
        </>
      )}
    </div>
  );
}

import * as React from 'react';
import ChooseProductBox from '@components/ChooseProductBox';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import uraniumBackupLogo from '@assets/images/uranium-backup.svg';

export default function ProductBoxUranium() {
  const navigate = useNavigate();

  const onBuyFn = React.useCallback(() => {
    navigate(t('routes:uraniumBackup'));
  }, [navigate]);

  return (
    <ChooseProductBox
      description={t('shop:diskBkp')}
      onClick={onBuyFn}
      image={uraniumBackupLogo}
      price={'80€'}
      priceType={t('common:shops.lifetime')}
    />
  );
}

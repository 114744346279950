import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';

import { usePasswordRecovery } from '@hooks/usePasswordRecovery';
import PasswordRecoveryForm from '@components/PasswordRecoveryForm';
import OnSuccessResetPasswordMessage from '@components/OnSuccessResetPasswordMessage';
import BlockFormWrapper from '@components/BlockFormWrapper';

export default function PasswordRecovery() {
  const { t } = useTranslation(['shop', 'routes', 'common', 'forms']);

  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [emailSentTo, setEmailSentTo] = React.useState('');

  const onSuccess = () => {
    setIsEmailSent(true);
    setTimeout(() => {
      setShowSuccessMessage(true);
    }, 2500);
  };

  const onError = () => {
    console.log('error');
  };

  const {
    mutate: recoverPassword,
    isLoading,
    isError,
    error
  } = usePasswordRecovery(onSuccess, onError);

  const onSubmitForm = React.useCallback(
    ({ email, recaptcha }: { email: string; recaptcha: string }) => {
      recoverPassword({ email, recaptcha });
      setEmailSentTo(email);
    },
    [recoverPassword]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection noMarginBottom={true} isCentered>
          <div className={styles.formWrapper}>
            <div className={styles.container}>
              <h1>{t('forms:titlePage.passwordRecovery')}</h1>

              {!showSuccessMessage ? (
                <BlockFormWrapper hasMaxWidth>
                  <PasswordRecoveryForm
                    onSubmit={onSubmitForm}
                    isLoading={isLoading}
                    isError={isError}
                    errorFromApi={error}
                    isEmailSent={isEmailSent}
                  />
                </BlockFormWrapper>
              ) : (
                <BlockFormWrapper hasMaxWidth>
                  <OnSuccessResetPasswordMessage email={emailSentTo} />
                </BlockFormWrapper>
              )}
            </div>
          </div>
        </LayoutSection>
      </LayoutPage>
    </>
  );
}

import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import VerticalTab from '@components/VerticalTab';
import MyAccountForm from '@components/MyAccountForm';

export default function Account() {
  const { t } = useTranslation(['common', 'routes', 'dashboard']);
  const [showForm, setShowForm] = useState('account');

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection>
          <div>
            <div className={styles.header}>
              <Breadcrumbs
                data={[
                  { to: t('routes:dashboard'), label: 'Dashboard' },
                  { label: t('dashboard:myAccount.title') },
                ]}
              />
            </div>
            <h2>{t('dashboard:myAccount.title')}</h2>
          </div>
          <div className={styles.container}>
            <VerticalTab form={showForm} setForm={setShowForm} />

            <div className={styles.formContainer}>
              <MyAccountForm
                showForm={showForm}
                setShowForm={setShowForm}
              />
            </div>
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getPaymentResult } from '@services/api';
import { noop } from 'lodash';
import { PaymentResult, PAYMENT_RESULT } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
export const KEY = 'payment-check';

export default function usePaymentResult(paymentId: string, result: number, onSuccess = noop, onError = noop) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<PaymentResult, Error>(
      [KEY, paymentId],
      () => { return result === -1 ? getPaymentResult(currentLanguage, paymentId ?? '') : { result: result === 1 ? PAYMENT_RESULT.SUCCESS : PAYMENT_RESULT.DECLINED }; },
      {
        enabled: Boolean(paymentId),
        onError,
        onSuccess,
        refetchInterval: (data) =>
          data?.result === PAYMENT_RESULT.PENDING ? 5000 : false,
      }
    ),
  };
}

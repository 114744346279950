import * as React from 'react';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import { Outlet, useLocation, useNavigate  } from 'react-router-dom';
import styles from './styles.module.scss';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import ICON_PAYPAL from '@assets/images/paypal.svg';
import Button from '@components/Button';
import { useCheckPaypalAgreement } from '@hooks/useCheckPaypalAgreement';
import {
  LicenseAgreementResponse,
  LICENSE_AGREEMENT_STATUS,
} from '@services/api';
import Spinner from '@components/Spinner';
import { useDisablePaypalAgreement } from '@hooks/useDisablePaypalAgreement';
import { useAddPaypalAgreement } from '@hooks/useAddPaypalAgreement';
import queryString from 'query-string';
import { enablePayPalAgreement } from '@services/api';

export default function PaymentMethods() {
  const { t } = useTranslation(['common', 'routes', 'dashboard']);

  const location = useLocation();
  const navigate = useNavigate();
  const { query } = queryString?.parseUrl(location.search);
  const action: string = query?.action ? String(query?.action) : '';
  const token: string = query?.token ? String(query?.token) : '';

  // Enable Agreement
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [agreementError, setAgreementError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await enablePayPalAgreement(action, token);
        setIsEnabled(true);
        // Remove params for enable payment to avoid new call on page refresh
        navigate(location.pathname, { replace: true });
      } catch (error: any) {
        setIsEnabled(false);
        setAgreementError(error);
      }
    };
    if (Boolean(action) && Boolean(token)) {
      fetchData();
    } else {
      setIsEnabled(true);
    }
  }, []);

  const { data, isLoading, refetch: useCheckPaypalAgreementFn } = useCheckPaypalAgreement(isEnabled);

  // Add Agreement
  const onSuccessAddAgreement = React.useCallback(
    (data: LicenseAgreementResponse) => {
      data?.url && window.open(data?.url, '_blank');
    },
    []
  );

  const {
    isFetching: isFetchingAddPaypalAgreement,
    refetch: addPaypalAgreementFn,
    error: errorAddPaypalAgreement,
  } = useAddPaypalAgreement((data) => onSuccessAddAgreement(data));

  // Disable Agreement
  const [showDisableMessage, setShowDisableMessage] = React.useState(false);
  const onSuccessDisableAgreement = React.useCallback(
    (data: boolean) => {
      setShowDisableMessage(data);
      if(data) {
        setTimeout(useCheckPaypalAgreementFn, 2500); 
      }
    },
    []
  );

  const {
    isFetching: isFetchingDisablePaypalAgreement,
    refetch: disablePaypalAgreementFn,
    error: errorDisablePaypalAgreement,
  } = useDisablePaypalAgreement((data) => onSuccessDisableAgreement(data));

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection isCentered>
          <div className={styles.header}>
            <Breadcrumbs
              data={[
                { to: t('routes:dashboard'), label: 'Dashboard' },
                { label: t('dashboard:paymentMethod.title') },
              ]}
            />
          </div>
          <h2>{t('dashboard:paymentMethod.title')}</h2>
          {isLoading ? (
            <div
              className={styles.container}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Spinner isGrey isMid />
            </div>
          ) : (
            <>
              {data?.agreement_status === LICENSE_AGREEMENT_STATUS.UNACTIVE ? (
                <div className={styles.container}>
                  <div className={styles.block}>
                    <p className={styles.lead}>
                      {t('dashboard:paymentMethod.noPaymentMethods')}
                    </p>
                    <Button
                      onClick={addPaypalAgreementFn}
                      isLoading={isFetchingAddPaypalAgreement}
                    >
                      {t('dashboard:paymentMethod.addPaypal')}
                    </Button>
                    {errorAddPaypalAgreement && (
                      <div className={styles.errors}>
                        <p>{errorAddPaypalAgreement.message}</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : data?.agreement_status === LICENSE_AGREEMENT_STATUS.CANNOTCHECK ? (
                <div className={styles.container}>
                  <div className={styles.block}>
                    <div className={styles.blockContent}>
                      <p>{t('dashboard:paymentMethod.impossibleToCheck')}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.container}>
                  <div className={styles.block}>
                    {!showDisableMessage ? (
                      <>
                        <div className={styles.blockContent}>
                          <img src={ICON_PAYPAL} alt="paypal-logo" />
                          <p>{data?.email}</p>
                        </div>
                        <div className={styles.blockContentRight}>
                          {!isFetchingDisablePaypalAgreement ? (
                            <span
                              onClick={() => disablePaypalAgreementFn()}
                              className={'icon-trash'}
                            ></span>
                          ) : (
                            <Spinner isGrey isMid />
                          )}
                        </div>
                        {errorDisablePaypalAgreement && (
                          <div className={styles.errors}>
                            <p>{errorDisablePaypalAgreement.message}</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <p>{t('dashboard:paymentMethod.paymentMethodDeleted')}</p>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.error}>
                <p>{agreementError?.message}</p>
              </div>
            </>
          )}
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import { formatPrice } from '@utils/types/helpers';

type Props = {
    value: number | string;
    currency?: string;
};
export default function Price({ value, currency = 'EUR' }: Props) {
    // Format the value with 2 decimals and comma separation
    return (
        <span>
            {formatPrice(value, currency, 2, 2, true)}
        </span>
    );
}
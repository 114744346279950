import classNames from 'classnames';
import styles from './styles.module.scss';
type Props = {
  children: React.ReactNode;
  hideOnMobile?: boolean;
};
export default function SidebarWrapper({ children, hideOnMobile }: Props) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.hideOnMobile]: hideOnMobile,
      })}
    >
      {children}
    </div>
  );
}

import styles from './styles.module.scss';
import cx from 'classnames';
type Props = {
  children: React.ReactNode;
  type?: SECTION_TYPE;
  backgroundColor?: SECTION_BACKGROUND;
  isCentered?: boolean;
  noMarginBottom?: boolean;
  isFullwidthWrap?: boolean;
  hasNarrowWrap?: boolean;
  hasSide?: boolean;
};

export enum SECTION_TYPE {
  HERO = 'hero',
  CUSTOM = 'custom',
}

export enum SECTION_BACKGROUND {
  WHITE = 'white',
  CAT_SKILL = 'catskill',
}

export default function LayoutSection({
  children,
  noMarginBottom = false,
  type = SECTION_TYPE.HERO,
  backgroundColor = SECTION_BACKGROUND.CAT_SKILL,
  isCentered = false,
  isFullwidthWrap = false,
  hasNarrowWrap = false,
  hasSide = false,
}: Props) {
  return type === SECTION_TYPE.CUSTOM ? (
    <section
      className={`custom ${backgroundColor} ${
        noMarginBottom ? 'noMarginBottom' : ''
      }`}
    >
      <div className={'wrap custom__wrap'} style={{ position: 'relative' }}>
        <div
          className={cx('custom__container', {
            centered: isCentered,
            fullWidthWrap: isFullwidthWrap,
            [styles.narrowWrap]: hasNarrowWrap,
          })}
        >
          {children}
        </div>
      </div>
    </section>
  ) : (
    <section
      className={cx('hero hero--standard', {
        [backgroundColor]: backgroundColor,
        noMarginBottom: noMarginBottom,
      })}
    >
      <div
        className={cx('wrap hero__wrap', {
          centered: isCentered,
          fullWidthWrap: isFullwidthWrap,
          [styles.narrowWrap]: hasNarrowWrap,
          showSide: hasSide,
        })}
      >
        {children}
      </div>
    </section>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import SignUpForm from '@components/SignUpForm';
import CompleteSignUpForm from '@components/CompleteSignUpForm';
import SidebarWrapper from '@components/SidebarWrapper';
import MiniCart from '@components/MiniCart';
import { CartContext } from '@contexts/CartContext';
import { UserContext } from '@contexts/UserContext';
import useCartProducts from '@hooks/useCartProducts';
import { CheckoutPrice } from '@utils/types/store';

const SignUp = () => {
  const { t } = useTranslation(['shop', 'routes', 'common', 'forms']);

  const navigate = useNavigate();
  const { isLoggedIn, registrationCompleted } = React.useContext(UserContext);
  React.useEffect(() => {
    if (isLoggedIn && registrationCompleted) {
      navigate(t('routes:home'));
    }
  }, [navigate, isLoggedIn, registrationCompleted, t]);

  const { cartItems } = React.useContext(CartContext);
  const {
    data: { products, total },
    isLoadingCheckoutFromApi,
    isLoadingGettingProduct,
  } = useCartProducts();

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection
          noMarginBottom={true}
          hasSide={cartItems.length > 0}
          isCentered
        >
          <div className={`${styles.container} ${styles.showSide}`}>
            <h1 style={{ marginBottom: '44px', fontSize: '48px' }}>
              {t(isLoggedIn ? 'forms:titlePage.completeSignUp' : 'forms:titlePage.signUp')}
            </h1>

            {isLoggedIn ? <CompleteSignUpForm /> : <SignUpForm />}
          </div>

          {cartItems.length > 0 && (
            <SidebarWrapper>
              <MiniCart
                products={products}
                isLoadingCheckoutFromApi={isLoadingCheckoutFromApi}
                total={total as CheckoutPrice}
                isLoadingGettingProduct={isLoadingGettingProduct}
              />
            </SidebarWrapper>
          )}
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
};

export default SignUp;

import * as React from 'react';
import { isUndefined, noop } from 'lodash';
import styles from './styles.module.scss';

type Props = {
  count: number;
  onAdd: (c: number) => void;
  onRemove: (c: number) => void;
  onTotalRemove?: typeof noop;
  onChange: typeof noop;
  style?: typeof styles;
  hideCounter?: boolean;
};
export default function Counter({
  count = 1,
  onAdd,
  onRemove,
  onChange = noop,
  onTotalRemove = undefined,
  style,
  hideCounter,
}: Props) {
  const [localValue, setLocalValue] = React.useState(String(count));

  const onChangeFn = React.useCallback((e: any) => {
    setLocalValue(e.target.value);
  }, []);

  const onBlurFn = React.useCallback(
    (e: any) => {
      if (Number(localValue) > 0 && Number(localValue) <= 999) {
        onChange(Number(localValue));
      } else if (Number(localValue) <= 0) {
        setLocalValue(String(1));
      } else {
        setLocalValue(String(count));
      }
    },
    [count, localValue, onChange]
  );

  React.useEffect(() => {
    setLocalValue(String(count));
  }, [count]);

  return (
    <>
      <div className={`${styles.container} ${style}`}>
        {!isUndefined(onTotalRemove) && Number(localValue) === 1 ? (
          <button onClick={() => onTotalRemove()}>
            <i className={`${styles.info} icon-trash`}></i>
          </button>
        ) : (
          <button onClick={() => onRemove(Number(localValue) - 1)}>
            <i className={`${styles.info} icon-minus`}></i>
          </button>
        )}
        <input
          className={styles.input}
          type="text"
          onBlur={onBlurFn}
          value={localValue}
          onChange={hideCounter ? noop : onChangeFn}
        />
        {!hideCounter && (
          <button onClick={() => onAdd(Number(localValue) + 1)}>
            <i className={`${styles.info} icon-plus`}></i>
          </button>
        )}
      </div>
    </>
  );
}

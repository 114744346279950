import React from 'react';
import styles from './styles.module.scss';
import { ToastContentProps } from 'react-toastify';
import Card from '@components/Card';
import Button, { BUTTON_STYLE } from '@components/Button';
import { useTranslation } from 'react-i18next';
import { CartContext } from '@contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';

export default function ToastCart({
  closeToast,
  toastProps,
}: Partial<ToastContentProps>) {
  const data = (toastProps?.data as any) ?? {};
  const { t } = useTranslation(['common', 'routes']);
  const navigate = useNavigate();
  const { isLoggedIn, registrationCompleted } = React.useContext(UserContext);

  const onNavigateToCart = React.useCallback(() => {
    closeToast && closeToast();
    navigate(t('routes:cart'));
  }, [closeToast, navigate, t]);

  const onNavigateToCheckout = React.useCallback(() => {
    closeToast && closeToast();
    if (isLoggedIn) {
      if (registrationCompleted) {
        navigate(t('routes:checkout'));
      } else {
        navigate(t('routes:signup'));
      }
    } else {
      navigate(t('routes:signinPayment'));
    }
  }, [closeToast, isLoggedIn, registrationCompleted, navigate, t]);

  const { totalQuantity } = React.useContext(CartContext);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span className="icon-check-circle"></span>
          {t('common:shops.addedToCart')}
        </div>
        <div onClick={closeToast} className={styles.closer}>
          <span className="icon-x"></span>
        </div>
      </div>
      <div className={styles.body}>
        <Card>
          <div className={styles.card}>
            <div>
              <strong className="truncate">{data?.title}</strong>
              <p>
                {t('common:shops.quantity')}: {data?.quantity}
              </p>
            </div>
            <strong>{data?.price}&euro;</strong>
          </div>
        </Card>
      </div>
      <div className={styles.footer}>
        <Button onClick={onNavigateToCart} styleBtn={BUTTON_STYLE.SECONDARY}>
          {t('common:shops.cart')} ({totalQuantity})
        </Button>
        <Button onClick={onNavigateToCheckout}>
          {t('common:shops.payment')}
        </Button>
      </div>
    </div>
  );
}

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  text: string;
  hasBorder?: boolean;
  linkDestination?: string;
  onClick?: () => void;
};

const SimpleBanner = ({ onClick, text, hasBorder, linkDestination }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <div className={`${styles.container} ${hasBorder ? styles.hasBorder : ''}`}>
      <p>
        {text && !linkDestination && text}

        {linkDestination && !onClick && (
          <Trans
            defaults={text}
            components={{
              link1: (
                <a target="_blank" rel="noreferrer" href={linkDestination}></a>
              ),
            }}
          />
        )}
        {onClick && (
          <span className={styles.link} onClick={onClick}>
            {' '}
            {t('common:shops.clickHere')}
          </span>
        )}
      </p>
    </div>
  );
};

export default SimpleBanner;

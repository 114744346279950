import { WithChildren } from '@utils/types/helpers';
import styles from './styles.module.scss';

type Props = WithChildren<{
  onClick?: () => void;
  disabled?: boolean;
  isActive?: boolean;
}>;

export default function ButtonTab({
  isActive,
  onClick,
  disabled,
  children,
}: Props) {
  return (
    <button
      className={`${styles.container} ${isActive ? styles.active : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

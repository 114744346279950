import ContentLoader from 'react-content-loader';

type Props = {
  width: number;
  height: number;
  radius?: number;
};
export default function SkeletonBasic({ width, height, radius = 4 }: Props) {
  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
    </ContentLoader>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getUraniumProduct } from '@services/api';
import { ProductUranium, PRODUCT_TYPE } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';

export const KEY = 'store-products';

export default function useUraniumProduct(
  onError?: typeof noop,
  onSuccess?: typeof noop
) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<ProductUranium, Error>(
      [KEY, PRODUCT_TYPE.URANIUM],
      () => getUraniumProduct(currentLanguage),
      {
        onError,
        onSuccess,
      }
    ),
  };
}

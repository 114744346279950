import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

import React from 'react';

const Footer = React.forwardRef((props, ref) => {
  const { t } = useTranslation(['common', 'links']);

  return (
    <footer className={styles.container} ref={ref as any}>
      <p className="micro">
        &copy; {new Date().getFullYear()} {t('common:footer.caption')} ·{' '}
        {
          <Trans
            defaults={t('common:footer.nav.privacy')}
            components={{
              link1: (
                <a
                  target="_self"
                  rel="noreferrer"
                  href={t('links:nanosystems.privacy')}
                ></a>
              ),
            }}
          />
        }{' '}
        ·{' '}
        {
          <Trans
            defaults={t('common:footer.nav.cookie')}
            components={{
              link1: (
                <a
                  target="_self"
                  rel="noreferrer"
                  href={t('links:nanosystems.cookie')}
                ></a>
              ),
            }}
          />
        }
      </p>
    </footer>
  );
});

export default Footer;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import SupremoControlLogo from '@assets/images/control.svg';
import UraniumBackupLogo from '@assets/images/uranium-backup.svg';
import SupremoConsoleLogo from '@assets/images/supremo-console.svg';
import styles from './styles.module.scss';
import Avatar from '@components/Avatar';
import useGetUserInfo from '@hooks/useUserInfo';
import { CartContext } from '@contexts/CartContext';
import { UserContext } from '@contexts/UserContext';
import { useLogout } from '@hooks/useLogout';
//import NotificationBell from '@components/NotificationBell';

type Props = {
  isAvatarHighlighted: boolean;
};
const NavBar = ({ isAvatarHighlighted }: Props) => {
  const { t } = useTranslation(['routes', 'common']);
  const [isProductOpen, setIsProductOpen] = React.useState(false);
  const [isPurchaseOpen, setIsPurchaseOpen] = React.useState(false);
  const [isSupportOpen, setIsSupportOpen] = React.useState(false);
  const { totalQuantity } = React.useContext(CartContext);
  const navigate = useNavigate();

  function setProductDropdown() {
    setIsProductOpen(!isProductOpen);
  }
  function setPurchaseDropdown() {
    setIsPurchaseOpen(!isPurchaseOpen);
  }
  function setSupportDropdown() {
    setIsSupportOpen(!isSupportOpen);
  }

  const onNavigateToAccount = React.useCallback(() => {
    navigate(t('routes:dashboard'));
  }, [navigate, t]);

  const { isLoggedIn } = React.useContext(UserContext);
  const { mutate } = useLogout();
  const logOut = () => {
    mutate();
  };
  const { data } = useGetUserInfo(isLoggedIn);

  return (
    <>
      <nav className="header__menu">
        <ul>
          {isLoggedIn && (
            <li className="custom-mobile-nav ">
              <div
                className={`${styles.avatar} ${styles.mobile} custom-mobile-nav `}
              >
                <Avatar
                  onClick={onNavigateToAccount}
                  image={''}
                  name={data?.username ?? ''}
                  isAvatarHighlighted={isAvatarHighlighted}
                />

                <p
                  onClick={onNavigateToAccount}
                  className={styles.logoutMobile}
                >
                  {t('common:navigation.hello', {
                    name: data?.username,
                  })}
                </p>
              </div>
            </li>
          )}
          <li id="menu-item-1" className="menu-item">
            <a href={t('links:nanosystems.aboutUs')}>
              {t('common:navigation.aboutUs')}
            </a>
          </li>
          <li id="menu-item-2"
            className={`${isProductOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setProductDropdown}>
              {t('common:navigation.products')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-2-1" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-supremo-control'}>
                  <small> {t('common:navigation.remoteControl')}</small>
                  <img src={SupremoControlLogo} alt={t('common:navigation.supremoControl')} />
                </a>
              </li>
              <li id="menu-item-2-2" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-uranium'}>
                  <small> {t('common:navigation.diskBkp')}</small>
                  <img src={UraniumBackupLogo} alt={t('common:navigation.uraniumBackup')} />
                </a>
              </li>
              <li id="menu-item-2-3" className="menu-item">
                <a className="logo-menu" href={t('links:nanosystems.home') + '#info-supremo-console'}>
                  <small> {t('common:navigation.supremoConsoleDesc')}</small>
                  <img src={SupremoConsoleLogo} alt={t('common:navigation.supremoConsole')} />
                </a>
              </li>
            </ul>
          </li>
          <li id="menu-item-3"
            className={`${isPurchaseOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setPurchaseDropdown}>
              {t('common:navigation.purchase')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-3-1" className="menu-item">
                <Link to={t('routes:home')}>{t('common:navigation.shop')}</Link>
              </li>
              <li id="menu-item-3-2" className="menu-item">
                <a href={t('links:nanosystems.becomeReseller')}>{t('common:navigation.becomeReseller')}</a>
              </li>
            </ul>
          </li>
          <li id="menu-item-4"
            className={`${isSupportOpen
              ? 'menu-item menu-item-has-children open'
              : 'menu-item menu-item-has-children'
              }`}
          >
            <Link to={'#'} onClick={setSupportDropdown}>
              {t('common:navigation.support')}
            </Link>
            <ul className="sub-menu">
              <li id="menu-item-4-1" className="menu-item">
                <a className="logo-menu" href={t('links:supremo.support')}>
                  <img src={SupremoControlLogo} alt={t('common:navigation.supremoControl')} />
                </a>
              </li>
              <li id="menu-item-4-2" className="menu-item">
                <a className="logo-menu" href={t('links:uranium.support')}>
                  <img src={UraniumBackupLogo} alt={t('common:navigation.uraniumBackup')} />
                </a>
              </li>
            </ul>
          </li>
          <li id="menu-item-5" className="menu-item">
            <a href={t('links:nanosystems.contacts')}>
              {t('common:navigation.contact')}
            </a>
          </li>

          {!isLoggedIn ? (
            <li className="custom-mobile-nav">
              <Link className="cta secondary block" to={t('routes:signin')}>
                {t('common:navigation.access')}
              </Link>
            </li>
          ) : (
            <li className="custom-mobile-nav">
              <Link onClick={logOut} to={'#'}>
                {t('common:navigation.logout')}
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <nav className="header__cart">
        <Link to={Boolean(totalQuantity) ? t('routes:cart') : ''}>
          <i className="icon-shopping-cart"></i>
          <span>{totalQuantity > 99 ? '99+' : totalQuantity}</span>
        </Link>
      </nav>
      {/*isLoggedIn && (
        <nav className="header__notification">
          <Link to={t('routes:dashboard')}>
            <NotificationBell isActive={data?.hasUnreadArticles} />
            <span></span>
          </Link>
        </nav>
      )*/}
      <nav className="header__user">
        {!isLoggedIn ? (
          <Link className="cta secondary" to={t('routes:signin')}>
            {t('common:navigation.access')}
          </Link>
        ) : (
          <div className={styles.avatar}>
            <Avatar
              onClick={onNavigateToAccount}
              image={''}
              name={data?.username ?? ''}
              isAvatarHighlighted={isAvatarHighlighted}
            />
            <div className={styles.logout}>
              <p onClick={onNavigateToAccount}>
                {t('common:navigation.hello', {
                  name: data?.username,
                })}
              </p>
              <span onClick={logOut}>{t('common:navigation.logout')}</span>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default NavBar;

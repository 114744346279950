/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getLoggedUserInfoFromApi } from '@services/api';
import { UserInfo } from '@utils/types/user';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
export const KEY = 'user-info';

export default function useGetUserInfo(
  enabled = false,
  onSuccess?: typeof noop,
  onError?: typeof noop
) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<UserInfo, Error>(
      [KEY],
      async () => {
        return await getLoggedUserInfoFromApi(currentLanguage);
      },
      {
        enabled,
        onSuccess,
        onError,
        retry: false,
        refetchOnWindowFocus: false,
      }
    ),
  };
}

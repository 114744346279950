import React from 'react';
import { CartContext } from '@contexts/CartContext';
import { UserContext } from '@contexts/UserContext';
import {
  CartItemType,
  ProductVersion,
  PRODUCT_VERSION_ID,
  ProfessionalProfile,
} from '@utils/types/store';
import { filter, isUndefined, multiply, sortBy } from 'lodash';
import { useCheckout } from './useCheckout';
import useDebounce from './useDebounce';
import { PRODUCT_TYPE } from '@utils/types/store';
import { t } from 'i18next';
import { calculateProductPriceFn } from '@utils/types/helpers';
import useNormalizeCartProductsForCheckout from './useNormalizeCartProductsForCheckout';

export default function useCartProducts() {
  const { cartItems, discountCode } = React.useContext(CartContext);
  const { registrationCompleted } = React.useContext(UserContext);

  const {
    data: checkoutDataFromAPI,
    isLoading: isLoadingCheckoutFromApi,
    isError: checkoutIsError,
    mutate: checkoutMutate,
  } = useCheckout();

  const [checkoutData, setCheckoutData] = React.useState(checkoutDataFromAPI);
  React.useEffect(() => {
    if (!isUndefined(checkoutDataFromAPI)) {
      setCheckoutData(checkoutDataFromAPI);
    }
  }, [checkoutDataFromAPI]);

  const {
    data: normalizedCartItemsForCheckout,
    allProducts,
    isLoading: isLoadingGettingProduct,
  } = useNormalizeCartProductsForCheckout();

  const debouncedNormalizedCartItemsForCheckout = useDebounce(
    normalizedCartItemsForCheckout,
    300
  );

  // call checkout api when quantity of product changes.
  React.useEffect(() => {
    if (Boolean(debouncedNormalizedCartItemsForCheckout.length)) {
      checkoutMutate({
        products: debouncedNormalizedCartItemsForCheckout,
        code: discountCode,
      });
    }
  }, [debouncedNormalizedCartItemsForCheckout, checkoutMutate, discountCode, registrationCompleted]);

  // create a good data for rendering for each element of cart
  const supremoData = React.useMemo(() => {
    return filter(cartItems, { productType: PRODUCT_TYPE.SUPREMO }).reduce<
      CartItemType[]
    >((acc: CartItemType[], item) => {
      // pick current item from products to get all information.
      const targetIds = [
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR,
        PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL,
      ];
      const onlyNotUpgradedProducts: ProductVersion[] | [] =
        allProducts?.filter((item) => targetIds.indexOf(item.id) === -1);

      const currentProduct: ProductVersion | undefined =
        onlyNotUpgradedProducts?.find((p) => String(p.id) === String(item.id));

      const minAmount = currentProduct?.minAmount ?? 1;
      const { teamAssistanceProfileId, connections = 0, id, quantity } = item;

      const currentTeamAssistanceProfile = currentProduct?.profiles?.find(
        (profile: ProfessionalProfile) => profile.id === teamAssistanceProfileId
      );

      const profilePrice = Number(currentTeamAssistanceProfile?.price ?? 0);
      const itemPrice = currentProduct?.price ?? 0;

      const subProductsPrices =
        currentProduct?.subProducts.map((pr) => pr?.price ?? 0) ?? [];

      const localProductPrice = calculateProductPriceFn({
        productPrice: itemPrice,
        subProductsPrices,
        profilePrice,
        connections,
        minAmount,
      });

      // const apiProductPrice =
      //   first(filter(checkoutData?.productPrices, { id })) ?? null;

      return currentProduct
        ? [
            ...acc,
            {
              id: id,
              localId: item?.localId,
              hideCounter: item?.hideCounter,
              renewOrUpgrade: item?.renewOrUpgrade,
              title: currentProduct?.name ?? '',
              price: multiply(localProductPrice, quantity),
              upgradeId: item?.upgradeId,

              fromLicense: item.fromLicense,
              quantity: quantity,
              description:
                Number(item?.connections) >= 3
                  ? `${item?.connections}x ${t(
                      'common:cart.concurrentSessions'
                    )} ${
                      currentTeamAssistanceProfile
                        ? ` - ${t('common:shops.assistanceTeam')}: ${
                            currentTeamAssistanceProfile?.name
                          }`
                        : ''
                    }`
                  : currentProduct?.description,
            },
          ]
        : acc;
    }, []);
  }, [allProducts, cartItems]);

  const upgradedData = React.useMemo(() => {
    return filter(cartItems, { productType: PRODUCT_TYPE.SUPREMO }).reduce<
      CartItemType[]
    >((acc: CartItemType[], item) => {
      const targetIds = [
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS,
        PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR,
        PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO,
        PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL,
      ];

      const onlyUpgradedProducts: ProductVersion[] | [] = allProducts?.filter(
        (item) => targetIds.indexOf(item.id) !== -1
      );

      const currentProduct: ProductVersion | undefined =
        onlyUpgradedProducts?.find(
          (p) => String(p.localId) === String(item.localId)
        );

      const { teamAssistanceProfileId, id } = item;

      const currentTeamAssistanceProfile: any = currentProduct?.profiles?.find(
        (profile: ProfessionalProfile) => profile.id === teamAssistanceProfileId
      );

      const itemPrice = currentProduct?.price ?? 0;

      return currentProduct
        ? [
            ...acc,
            {
              id: id,
              localId: item?.localId,
              hideCounter: item?.hideCounter,
              renewOrUpgrade: item?.renewOrUpgrade,
              title: currentProduct?.name ?? '',
              // price: multiply(itemPrice, quantity),
              price: itemPrice,
              quantity: 1,
              fromLicense: item?.fromLicense,
              subProducts: currentProduct?.subProducts,
              description:
                Number(item?.connections) >= 3
                  ? `${item?.connections}x ${t(
                      'common:cart.concurrentSessions'
                    )} ${
                      currentTeamAssistanceProfile
                        ? ` - ${t('common:shops.assistanceTeam')}: ${
                            currentTeamAssistanceProfile?.name
                          }`
                        : ''
                    }`
                  : currentProduct?.description,
            },
          ]
        : acc;
    }, []);
  }, [allProducts, cartItems]);

  const uraniumData = React.useMemo(() => {
    return filter(cartItems, { productType: PRODUCT_TYPE.URANIUM }).reduce<
      CartItemType[]
    >((acc: CartItemType[], item) => {
      // pick current item from products to get all information.
      const currentProduct: ProductVersion | undefined = allProducts?.find(
        (p) => String(p.id) === String(item.id)
      );

      const { quantity, id } = item;
      const itemPrice: any = currentProduct?.price ?? 0;
      // const apiProductPrice =
      //   first(filter(checkoutData?.productPrices, { id })) ?? null;

      return currentProduct
        ? [
            ...acc,
            {
              id: id,
              localId: item?.localId,
              hideCounter: item?.hideCounter,
              renewOrUpgrade: item?.renewOrUpgrade,
              fromLicense: item?.fromLicense,
              title: currentProduct?.name ?? '',
              price: multiply(itemPrice, quantity),
              quantity: quantity,
              description: currentProduct?.description ?? '',
            },
          ]
        : acc;
    }, []);
  }, [allProducts, cartItems]);

  const sortedData = React.useMemo(
    () =>
      sortBy([...supremoData, ...uraniumData, ...upgradedData], (item) =>
        cartItems.map((i) => i.id).indexOf(item?.id)
      ),
    [cartItems, supremoData, uraniumData, upgradedData]
  );

  const offlineTotal = React.useMemo(
    () => sortedData?.reduce((acc, item) => acc + Number(item?.price ?? 0), 0),
    [sortedData]
  );

  return {
    data: {
      B2BPecNeeded: checkoutDataFromAPI?.B2BPecNeeded,
      billingInformation: checkoutDataFromAPI?.billingInformation || null,
      products:
        !isUndefined(allProducts) || !isUndefined(cartItems)
          ? sortedData
          : undefined,
      total: {
        ...checkoutData,
        total: checkoutData?.total ?? offlineTotal?.toLocaleString('it-IT'),
        currency: checkoutData?.currency ?? '€',
      },
    },
    isLoadingGettingProduct,
    isLoadingCheckoutFromApi:
      checkoutIsError || isLoadingCheckoutFromApi || isUndefined(checkoutData),
  };
}

import { SUBSCRIPTION_DURATION } from '@services/normalize';

export enum PRODUCT_VERSION {
  DEFAULT = '',
  SOLO = 'solo',
  BUSINESS = 'business',
  PRO = 'pro',
  PRO_TAPE = 'pro-tape',
  PRO_VIRTUAL = 'pro-virtual',
  PRO_DB = 'pro-db',
  PRO_SHADOW = 'pro-shadow',
  BASE = 'base',
  GOLD = 'gold',
  AUSILIO = 'ausilio',
  EXTRA_CONTROL = 'extra-control',
}

export enum PRODUCT_VERSION_ID {
  SOLO_THREE_MONTHS = 27,
  SOLO_ONE_YEAR = 28,
  BUSINESS_THREE_MONTHS = 29,
  BUSINESS_ONE_YEAR = 30,
  PRO_THREE_MONTHS = 63,
  PRO_ONE_YEAR = 64,
  SOLO_TWO_YEAR = 65,
  SOLO_THREE_YEAR = 66,
  BUSINESS_TWO_YEAR = 67,
  BUSINESS_THREE_YEAR = 68,
  PRO_TWO_YEAR = 69,
  PRO_THREE_YEAR = 70,
  PRO_TAPE = 3,
  PRO_VIRTUAL = 23,
  PRO_DB = 6,
  PRO_SHADOW = 21,
  BASE = 2,
  GOLD = 5,
  AUSILIO = 51,
  EXTRA_CONTROL_THREE_MONTHS = 31,
  EXTRA_CONTROL_ONE_YEAR = 32,
  UPGRADE_PRO_TO_GOLD = 10,
  UPGRADE_BASE_TO_PRO_TAPE = 11,
  UPGRADE_BASE_TO_GOLD = 12,
  UPGRADE_BASE_TO_PRO_DB = 14,
  UPGRADE_BASE_TO_PRO_SHADOW = 22,
  UPGRADE_BASE_TO_PRO_VIRTUAL = 24,
  UPGRADE_VIRTUAL_TO_GOLD = 25,
  UPGRADE_PRO_SHADOW_TO_PRO_VIRTUAL = 26,
  SUPREMO_EXTRA_CONTROL_THREE_MONTHS = 31,
  SUPREMO_EXTRA_CONTROL_ONE_YEAR = 32,
  SUPREMO_EXTRA_CONTROL_THREE_YEARS = 72,
  SUPREMO_EXTRA_CONTROL_TWO_YEAR = 71,
  UPGRADE_SOLO_TO_BUSINESS = 53,
  UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR = 54,
  UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS = 73,
  UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS = 74,
  UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO = 55,
  UPGRADE_SUPREMO_PROFESSIONAL = 62,
}

export enum TEAM_ASSISTANCE {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
}

export enum PRODUCT_TYPE {
  SUPREMO = 'Supremo',
  URANIUM = 'Uranium Backup',
  CONSOLE = 'Console',
}

export type CalculateProductPriceParams = {
  productPrice: number;
  profilePrice: number;
  subProductsPrices: number[];
  connections: number;
  minAmount: number;
};

export interface ProductSupremo {
  id: number;
  name: string;
  nameId: PRODUCT_TYPE.SUPREMO;
  description: string;
  products: {
    quarterly: ProductVersion[];
    yearly: ProductVersion[];
    biennial: ProductVersion[];
    triennial: ProductVersion[];
  };
}
export interface RenewUpgradeProductSupremo {
  titlePage: string;
  type: string;
  software: string;
  startingControls: number;
  startingProfile: number;
  products: {
    quarterly: ProductVersion[];
    yearly: ProductVersion[];
    biennial: ProductVersion[];
    triennial: ProductVersion[];
  };
}

export interface RenewUpgradeProductUranium {
  titlePage: string;
  type: string;
  software: string;
  startingControls: number;
  startingProfile: number;
  products: ProductVersion[];
}

export interface ProductUranium {
  id: number;
  name: string;
  nameId: PRODUCT_TYPE.URANIUM;
  description: string;
  products: ProductVersion[];
}

export interface ProductVersion {
  id: number;
  price: number;
  quantity: number;
  upgradeId: number;
  autoRenewable: boolean;
  name: string;
  minAmount: number;
  maxAmount: number;
  profiles?: ProfessionalProfile[];
  defaultProfile?: ProfessionalProfile;
  description: string;
  shortDescription: string;
  image: string;
  isCumulable: boolean;
  subscriptionDuration: SUBSCRIPTION_DURATION;
  durationMonths: number;
  isRenewable: boolean;
  promotionalCode: string;
  profile?: number;
  promotionalDiscountAmount: number;
  usilioIntegration: boolean;
  shouldShowMultiConnection: boolean;
  subProducts: Partial<ProductVersion[]>;
  newExpire?: string;
  sortIndex?: number;
  site?: string;
  startingControl?: number;
  localId?: string;
  promoDesc?: string;
  isDowngrade?: boolean;
}

export type SupremoFeatures = {
  feature: React.ReactNode;
  solo: React.ReactNode;
  business: React.ReactNode;
  professional: React.ReactNode;
};

export type UraniumFeatures = {
  feature: React.ReactNode;
  free: React.ReactNode;
  base: React.ReactNode;
  proTape: React.ReactNode;
  proDB: React.ReactNode;
  proShadow: React.ReactNode;
  proVirtual: React.ReactNode;
  gold: React.ReactNode;
};

export type SupremoFeaturesList = {
  name: string;
  tooltip: React.ReactNode;
  solo: boolean;
  business: boolean;
  pro: boolean;
};

export type UraniumFeaturesList = {
  name: string;
  free: boolean;
  base: boolean;
  proTape: boolean;
  proDB: boolean;
  proShadow: boolean;
  proVirtual: boolean;
  gold: boolean;
};

export type Cart = {
  id: number;
  quantity: number;
  radioValue: any;
  selectValue: any;
};

export type CartProductItem = {
  productType: PRODUCT_TYPE;
  localId?: any;
  id: number;
  quantity: number;
  connections?: number;
  teamAssistanceProfileId?: number;
  isCumulable: boolean;
  upgradeId?: number;
  matchedId?: number;
  connectionsForUpgradeCart?: number;
  hideCounter?: boolean;
  renewOrUpgrade?: string;
  fromLicense?: number;
};

export type ProfessionalProfile = {
  id: number;
  name: string;
  description: string;
  price: string;
  months: string;
};

export type CheckoutRawProduct = {
  renewEnabled: boolean;
  toUpgradeId: number;
  id: number;
  profile: number;
  quantity: number;
  childs?: CheckoutRawProduct[];
};

export type CartProductItemForCheckout = Partial<CartProductItem> & {
  renewEnabled: boolean;
  toUpgradeId: number;
  profile: number;
  children?: CartProductItemForCheckout[];
};

export type CheckoutDiscountAndTax = {
  percent: string;
  total: string;
  description?: string;
};

export type CheckoutBIllingInformation = {
  fullname: string;
  address: string;
  country: string;
  city: string;
  fiscalCode?: string;
  taxNumber?: string;
};

export type CheckoutPrice = {
  currency: string;
  billingInformation: CheckoutBIllingInformation;
  totalCount: string;
  productPrices: { id: number; price: number }[];
  totalWithoutTax: string;
  discounts: CheckoutDiscountAndTax[];
  tax: CheckoutDiscountAndTax[];
  total: string;
  B2BPecNeeded: boolean;
};

export type CartItemType = {
  id: number;
  title: string;
  description: string;
  localId: string;
  price: number;
  quantity: number;
  hideCounter?: boolean;
  fromLicense?: number;
  renewOrUpgrade?: string;
  subProducts?: Partial<ProductVersion[]>;
};

export enum PAYMENT_METHODS {
  TRANSFER = 'B',
  PAYPAL = 'P',
  CREDIT_CARD = 'C',
  RENEW = 'A',
}

export type AddPurchaseResponse = {
  redirectUrl: string | null;
};

export type PaymentRecap = {
  id: string;
  total: string;
  products: Partial<ProductVersion>[];
  supportMail: string;
  currency: string;
  bankTransferInfo: {
    accountHolder: string;
    bank: string;
    iban: string;
    bicSwift: string;
    abi: string;
    cab: string;
    cc: string;
    cin: string;
  };
  userInfo: CheckoutBIllingInformation;
};

export enum PAYMENT_RESULT {
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export type PaymentResult = {
  result: PAYMENT_RESULT;
};

export type CheckPayWithPaypal = {
  amount: string;
  errorMessage: string;
};

import Button, { BUTTON_STYLE } from '@components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
export default function InitSignUp() {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'routes']);
  const goToSignUp = React.useCallback(() => {
    navigate(t('routes:signup'));
  }, [navigate, t]);

  return (
    <div className={styles.container}>
      <p>{t('common:shops.registerToCompletePurchase')}</p>
      <Button fullWidth onClick={goToSignUp} styleBtn={BUTTON_STYLE.SECONDARY}>
        {t('common:shops.startSignUp')}
      </Button>
    </div>
  );
}

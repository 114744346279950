/* eslint-disable @typescript-eslint/no-unused-vars */
import { onUpdateUserAccountData } from '@services/api';
import { useMutation } from '@tanstack/react-query';

import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

const KEY = 'user-update-account';

export const useUpdateUserAccountData = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const data = useMutation<any, string[], any>(
    [KEY],
    async (data: any): Promise<any> => {
      await onUpdateUserAccountData(currentLanguage, data);
    },
    {
      onError,
      onSuccess,
    }
  );

  return {
    isLoading: data?.isLoading,
    mutate: data.mutate,
    isError: data?.isError,
    error: data?.error,
  };
};

import { useMutation } from '@tanstack/react-query';
import { getActivationCode } from '@services/api';
import { noop } from 'lodash';
import { IActivationCodeRequest } from '@utils/types/licenses';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-activation-code';

export const useGetActivationCode = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<string, Error, IActivationCodeRequest>(
      [KEY],
      async ({ id, machineCode }: IActivationCodeRequest): Promise<string> => {
        return await getActivationCode(currentLanguage, id, machineCode);
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection, { SECTION_BACKGROUND } from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import Button, { BUTTON_STYLE } from '@components/Button';
import { CartContext } from '@contexts/CartContext';
import FaqListAccordion from '@components/FaqListAccordion';
import TwoBlockLayout from '@components/TwoBlockLayout';
import uraniumBackupLogo from '@assets/images/uranium-backup.svg';
import ImageRightColumn from '@assets/nanosystems-base/images/hero-uranium-en.webp';
import {
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION_ID,
} from '@utils/types/store';
import { SUBSCRIPTION_DURATION } from '@services/normalize';

import Collapsible from 'react-collapsible';
import useSupremoProduct from '@hooks/useSupremoProduct';
import SupremoAccessAndControlTable from '@components/SupremoAccessAndControlTable';
import SupremoTableHeader from '@components/SupremoTableHeader';
import SupremoAssistanceAndCustomizationTable from '@components/SupremoAssistanceAndCustomizationTable';
import SupremoSecurityAndPrivacyTable from '@components/SupremoSecurityAndPrivacyTable';
import SupremoSoloProductCard from '@components/SupremoSoloProductCard';
import SupremoBusinessProductCard from '@components/SupremoBusinessProductCard';
import SupremoProfessionalProductCard from '@components/SupremoProfessionalProductCard';
import { SelectProductButton } from '@components/SelectProductButton';

const SupremoControl = () => {
  const { t } = useTranslation(['common', 'routes', 'supremoControl']);
  const [subscription, setSubscription] = React.useState<number>(12);
  const { data } = useSupremoProduct();
  const [showPromoTriennial, setShowPromoTriennial] =
    React.useState<boolean>(false);
  const [showPromoBiennial, setShowPromoBiennial] =
    React.useState<boolean>(false);
  const [showPromoYearly, setShowPromoYearly] = React.useState<boolean>(false);
  const [showPromoQuarterly, setShowPromoQuarterly] =
    React.useState<boolean>(false);

  const { setCartItems } = React.useContext(CartContext);

  const checkForPromo = React.useCallback(() => {
    if (data) {
      if (subscription === 36) {
        setShowPromoTriennial(
          data?.products?.triennial?.some((el) => el?.promoDesc)
        );
      } else if (subscription === 24) {
        setShowPromoBiennial(
          data?.products?.biennial?.some((el) => el?.promoDesc)
        );
      } else if (subscription === 12) {
        setShowPromoYearly(data?.products?.yearly?.some((el) => el?.promoDesc));
      } else {
        setShowPromoQuarterly(
          data?.products?.quarterly?.some((el) => el?.promoDesc)
        );
      }
    }
  }, [subscription, data]);

  React.useEffect(() => {
    if (data) {
      checkForPromo();
    }
  }, [checkForPromo, data]);

  const showYearlyDataFn = React.useCallback(() => {
    setSubscription(12);
    checkForPromo();
  }, [checkForPromo]);

  const showQuarterlyDataFn = React.useCallback(() => {
    setSubscription(3);
    checkForPromo();
  }, [checkForPromo]);

  const showBiennialDataFn = React.useCallback(() => {
    setSubscription(24);
    checkForPromo();
  }, [checkForPromo]);

  const showTriennialDataFn = React.useCallback(() => {
    setSubscription(36);
    checkForPromo();
  }, [checkForPromo]);

  const renderProductItem = React.useCallback(
    (product: ProductVersion) => {
      if (
        product?.id === PRODUCT_VERSION_ID.SOLO_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.SOLO_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.SOLO_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.SOLO_TWO_YEAR
      ) {
        return (
          <SupremoSoloProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                ? showPromoBiennial
                : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                ? showPromoYearly
                : showPromoQuarterly
            }
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.BUSINESS_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_TWO_YEAR
      ) {
        return (
          <SupremoBusinessProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                ? showPromoBiennial
                : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                ? showPromoYearly
                : showPromoQuarterly
            }
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.PRO_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.PRO_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.PRO_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.PRO_TWO_YEAR
      ) {
        return (
          <SupremoProfessionalProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                ? showPromoBiennial
                : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                ? showPromoYearly
                : showPromoQuarterly
            }
          />
        );
      }
    },
    [
      setCartItems,
      subscription,
      showPromoBiennial,
      showPromoQuarterly,
      showPromoTriennial,
      showPromoYearly,
    ]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection isCentered>
          <SelectProductButton isSupremoPage={true} />
          <div className={styles.header}>
            <div>
              <h1>{t('supremoControl:title')}</h1>
              {/* <p>{t('supremoControl:subTitle')}</p> */}
            </div>
            <div className={styles.selectDuration}>
              <Button
                styleBtn={
                  subscription === SUBSCRIPTION_DURATION.THREE_MONTH
                    ? BUTTON_STYLE.PRIMARY
                    : BUTTON_STYLE.SECONDARY
                }
                onClick={showQuarterlyDataFn}
              >
                {t('common:shops.quarterly')}
              </Button>
              <Button
                styleBtn={
                  subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                    ? BUTTON_STYLE.PRIMARY
                    : BUTTON_STYLE.SECONDARY
                }
                onClick={showYearlyDataFn}
              >
                {t('common:shops.yearly')}
              </Button>
              <Button
                styleBtn={
                  subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                    ? BUTTON_STYLE.PRIMARY
                    : BUTTON_STYLE.SECONDARY
                }
                onClick={showBiennialDataFn}
              >
                {t('common:shops.biennial')}
              </Button>
              <Button
                styleBtn={
                  subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                    ? BUTTON_STYLE.PRIMARY
                    : BUTTON_STYLE.SECONDARY
                }
                onClick={showTriennialDataFn}
              >
                {t('common:shops.triennial')}
              </Button>
            </div>
          </div>
          <div className={styles.cardWrap}>
            {subscription === 36
              ? data?.products?.triennial?.map(renderProductItem)
              : subscription === 24
              ? data?.products?.biennial?.map(renderProductItem)
              : subscription === 12
              ? data?.products?.yearly?.map(renderProductItem)
              : data?.products?.quarterly?.map(renderProductItem)}
          </div>
        </LayoutSection>
        <LayoutSection isCentered>
          <div className={`${styles.header} ${styles.compare}`}>
            <h3 className={styles.titleSection}>
              {t('supremoControl:compareSubscription.title')}
            </h3>
          </div>
          <div className={`${styles.tableWrap} tableAccordion`}>
            <SupremoTableHeader />
            <Collapsible
              open
              trigger={t(
                'supremoControl:compareSubscription.table.accessAndControl.accessAndControl'
              )}
              className={styles.question}
            >
              <SupremoAccessAndControlTable />
            </Collapsible>

            <Collapsible
              open
              trigger={t(
                'supremoControl:compareSubscription.table.securityAndPrivacy.securityAndPrivacy'
              )}
              className={styles.question}
            >
              <SupremoSecurityAndPrivacyTable />
            </Collapsible>

            <Collapsible
              open
              trigger={t(
                'supremoControl:compareSubscription.table.assistanceAndPersonalization.assistanceAndPersonalization'
              )}
              className={styles.question}
            >
              <SupremoAssistanceAndCustomizationTable />
            </Collapsible>
          </div>
        </LayoutSection>
        <LayoutSection isCentered>
          <div className={styles.header}>
            <h4 className={styles.titleSection}>{t('common:faq.title')}</h4>
          </div>
          <div className={styles.faqWrap}>
            <FaqListAccordion productType={PRODUCT_TYPE.SUPREMO} />
          </div>
        </LayoutSection>
        <LayoutSection backgroundColor={SECTION_BACKGROUND.WHITE} isCentered>
          <div className={styles.header}>
            <h3 className={`${styles.titleSection} ${styles.blue}`}>
              {t('supremoControl:needABkp.title')}
            </h3>
            <p>{t('supremoControl:needABkp.subTitle')}</p>
          </div>
          <TwoBlockLayout
            logo={uraniumBackupLogo}
            title={t('supremoControl:needABkp.leftContent.title')}
            description={t('supremoControl:needABkp.leftContent.description')}
            image={ImageRightColumn}
            isButtonSecondary
            labelBadge={''}
            labelBtn={t('supremoControl:needABkp.leftContent.btnLabel')}
            destinationBtn={PRODUCT_TYPE.URANIUM}
          />
        </LayoutSection>
      </LayoutPage>
    </>
  );
};

export default SupremoControl;

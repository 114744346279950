import { useQuery } from '@tanstack/react-query';
import { disablePayPalAgreement } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'disable-paypal-agreement';

export const useDisablePaypalAgreement = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<boolean, Error>(
      [KEY],
      async (): Promise<boolean> => {
        return await disablePayPalAgreement(currentLanguage);
      },
      {
        enabled: false,
        onError,
        onSuccess,
      }
    )
  };
};

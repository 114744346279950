import Modal from '@components/Modal';
import { PRODUCT_TYPE } from '@utils/types/store';
import ModalSupremoActivation from '@components/ModalSupremoActivation';
import { useTranslation } from 'react-i18next';
import ModalUraniumBkpProActivation from '@components/ModalUraniumProActivation';
import ModalUraniumActivation from '@components/ModalUraniumActivation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setCurrentMachineCodeFn: (machineCode: string) => void;
  productType: PRODUCT_TYPE;
  licenseActivationCode: string | '';
  onGetActivationDate: ({
    id,
    isFromModal,
  }: {
    id: string;
    isFromModal: boolean;
  }) => void;
  onGetActivationCode: ({
    id,
    machineCode,
  }: {
    id: string;
    machineCode: string;
  }) => void;
  supremoExpirationDate: string;
  id: string;
  isLoadingActivationCode: boolean;
  isErrorActivationCode: any;
  isProfessional: boolean;
};
export default function ModalActivationProduct({
  productType,
  isOpen,
  onClose,
  onGetActivationDate,
  onGetActivationCode,
  licenseActivationCode,
  supremoExpirationDate,
  setCurrentMachineCodeFn,
  isLoadingActivationCode,
  isErrorActivationCode,
  isProfessional,

  id,
}: Props) {
  const { t } = useTranslation(['license']);
  const licenseOrSubscription =
    productType === PRODUCT_TYPE.URANIUM
      ? 'activateLicenseOf'
      : 'activateSubscriptionOf';

  const isSupremoProfessional =
    productType === PRODUCT_TYPE.SUPREMO && isProfessional
      ? ' Professional'
      : '';
  return (
    <Modal
      isOpen={isOpen}
      title={t(`license:${licenseOrSubscription}`, {
        product:
          productType === PRODUCT_TYPE.CONSOLE
            ? 'Uranium Backup Professional'
            : productType + isSupremoProfessional,
      })}
      onClose={onClose}
    >
      {productType === PRODUCT_TYPE.SUPREMO ? (
        <ModalSupremoActivation
          id={id}
          onGetActivationDate={onGetActivationDate}
          onGetActivationCode={onGetActivationCode}
          expirationDate={supremoExpirationDate}
          licenseActivationCode={licenseActivationCode}
          isProfessional={isProfessional}
        />
      ) : productType === PRODUCT_TYPE.URANIUM ? (
        <ModalUraniumActivation
          id={id}
          licenseActivationCode={licenseActivationCode}
          onGetActivationCode={onGetActivationCode}
          setCurrentMachineCodeFn={setCurrentMachineCodeFn}
          isLoadingActivationCode={isLoadingActivationCode}
          isErrorActivationCode={isErrorActivationCode}
        />
      ) : (
        <ModalUraniumBkpProActivation
          id={id}
          onGetActivationDate={onGetActivationDate}
          onGetActivationCode={onGetActivationCode}
          expirationDate={supremoExpirationDate}
          licenseActivationCode={licenseActivationCode}
        />
      )}
    </Modal>
  );
}

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const OnSuccessResetPasswordMessage = ({ email }: { email: string }) => {
  const { t } = useTranslation('forms');

  return (
    <div className={styles.container}>
      <p className={styles.message}>{t('resetPasswordMessage')}</p>
      <div className={styles.email}>
        <p>{email}</p>
      </div>
      <p className={styles.notReceivedMessage}>{t('notReceivedEmail')}</p>
      <p className={styles.checkSpam}>{t('checkSpam')}</p>
    </div>
  );
};

export default OnSuccessResetPasswordMessage;

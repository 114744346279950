import { useMutation } from '@tanstack/react-query';
import { getLicensePDF } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-license-pdf';

export const useGetLicensePDF = (
  id: string,
  onSuccess = noop,
  onError = noop
) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<string, Error, string>(
      [KEY, id],
      async (): Promise<string> => {
        return await getLicensePDF(currentLanguage, id);
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

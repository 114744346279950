import Tooltip from 'rc-tooltip';
import styles from './styles.module.scss';
type Props = {
  text: string;
  tooltipOverlay: any;
};

function LabelWithTooltip({ text, tooltipOverlay }: Props) {
  return (
    <div className={styles.container}>
      <p>
        {text && text}
        {tooltipOverlay && (
          <Tooltip
            placement="top"
            overlay={tooltipOverlay}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <i className={`${styles.info} icon-info`}></i>
          </Tooltip>
        )}
      </p>
    </div>
  );
}

export default LabelWithTooltip;

import Card from '@components/Card';
import Modal from '@components/Modal';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function ModalDiscountReseller({ isOpen, onClose }: Props) {
  const { t } = useTranslation(['discount']);
  return (
    <Modal
      isOpen={isOpen}
      title={t('discount:resellerDiscount.title')}
      onClose={onClose}
    >
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.cardContent}>
            <h4>{t('discount:resellerDiscount.card1.title')}</h4>
            <p className={styles.blueText}>
              {t('discount:resellerDiscount.card1.descriptionBlue')}&nbsp;
            </p>
            <p>{t('discount:resellerDiscount.card1.description')}</p>
          </div>
        </Card>
        <Card>
          <div className={styles.cardContent}>
            <h4>{t('discount:resellerDiscount.card2.title')}</h4>
            <p className={styles.blueText}>
              {t('discount:resellerDiscount.card2.descriptionBlue')}
            </p>
            <p>{t('discount:resellerDiscount.card2.description')}</p>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card2.arrows.arrow1')}</p>
              <strong>
                {t('discount:resellerDiscount.card2.arrows.discount1')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card2.arrows.arrow2')}</p>
              <strong>
                {t('discount:resellerDiscount.card2.arrows.discount2')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card2.arrows.arrow3')}</p>
              <strong>
                {t('discount:resellerDiscount.card2.arrows.discount3')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card2.arrows.arrow4')}</p>
              <strong>
                {t('discount:resellerDiscount.card2.arrows.discount4')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card2.arrows.arrow5')}</p>
              <strong>
                {t('discount:resellerDiscount.card2.arrows.discount5')}
              </strong>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.cardContent}>
            <h4>{t('discount:resellerDiscount.card3.title')}</h4>
            <p className={styles.blueText}>
              {t('discount:resellerDiscount.card3.description')}
            </p>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow1')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount1')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow2')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount2')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow3')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount3')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow4')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount4')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow5')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount5')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow6')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount6')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow7')}</p>
              <strong>
                {t('discount:resellerDiscount.card3.arrows.discount7')}
              </strong>
            </div>
            <div className={styles.row}>
              <p>{t('discount:resellerDiscount.card3.arrows.arrow8')}</p>
              <strong className={classNames(styles.blueText, styles.cta)}>
                <Trans
                  defaults={t(
                    'discount:resellerDiscount.card3.arrows.discount8'
                  )}
                  components={{
                    link1: <a target="_blank" rel="noreferrer" href="#"></a>,
                  }}
                />
              </strong>
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import routes from '@locales/en/routes.json';
import routesIT from '@locales/it/routes.json';
import routesFR from '@locales/fr/routes.json';
import routesDE from '@locales/de/routes.json';
import routesES from '@locales/es/routes.json';
import routesPT_BR from '@locales/pt-br/routes.json';
import uraniumBackup from '@locales/en/uraniumBackup.json';
import uraniumBackupIT from '@locales/it/uraniumBackup.json';
import uraniumBackupFR from '@locales/fr/uraniumBackup.json';
import uraniumBackupDE from '@locales/de/uraniumBackup.json';
import uraniumBackupES from '@locales/es/uraniumBackup.json';
import uraniumBackupPT_BR from '@locales/pt-br/uraniumBackup.json';
import supremoControl from '@locales/en/supremoControl.json';
import supremoControlIT from '@locales/it/supremoControl.json';
import supremoControlFR from '@locales/fr/supremoControl.json';
import supremoControlDE from '@locales/de/supremoControl.json';
import supremoControlES from '@locales/es/supremoControl.json';
import supremoControlPT_BR from '@locales/pt-br/supremoControl.json';
import contacts from '@locales/en/contacts.json';
import contactsIT from '@locales/it/contacts.json';
import contactsFR from '@locales/fr/contacts.json';
import contactsDE from '@locales/de/contacts.json';
import contactsES from '@locales/es/contacts.json';
import contactsPT_BR from '@locales/pt-br/contacts.json';
import shop from '@locales/en/shop.json';
import shopIT from '@locales/it/shop.json';
import shopFR from '@locales/fr/shop.json';
import shopDE from '@locales/de/shop.json';
import shopES from '@locales/es/shop.json';
import shopPT_BR from '@locales/pt-br/shop.json';
import common from '@locales/en/common.json';
import commonIT from '@locales/it/common.json';
import commonFR from '@locales/fr/common.json';
import commonDE from '@locales/de/common.json';
import commonES from '@locales/es/common.json';
import commonPT_BR from '@locales/pt-br/common.json';
import forms from '@locales/en/forms.json';
import formsIT from '@locales/it/forms.json';
import formsFR from '@locales/fr/forms.json';
import formsDE from '@locales/de/forms.json';
import formsES from '@locales/es/forms.json';
import formsPT_BR from '@locales/pt-br/forms.json';
import cart from '@locales/en/cart.json';
import cartIT from '@locales/it/cart.json';
import cartFR from '@locales/fr/cart.json';
import cartDE from '@locales/de/cart.json';
import cartES from '@locales/es/cart.json';
import cartPT_BR from '@locales/pt-br/cart.json';
import checkout from '@locales/en/checkout.json';
import checkoutIT from '@locales/it/checkout.json';
import checkoutFR from '@locales/fr/checkout.json';
import checkoutDE from '@locales/de/checkout.json';
import checkoutES from '@locales/es/checkout.json';
import checkoutPT_BR from '@locales/pt-br/checkout.json';
import payments from '@locales/en/payments.json';
import paymentsIT from '@locales/it/payments.json';
import paymentsFR from '@locales/fr/payments.json';
import paymentsDE from '@locales/de/payments.json';
import paymentsES from '@locales/es/payments.json';
import paymentsPT_BR from '@locales/pt-br/payments.json';
import purchases from '@locales/en/purchases.json';
import purchasesIT from '@locales/it/purchases.json';
import purchasesFR from '@locales/fr/purchases.json';
import purchasesDE from '@locales/de/purchases.json';
import purchasesES from '@locales/es/purchases.json';
import purchasesPT_BR from '@locales/pt-br/purchases.json';
import license from '@locales/en/license.json';
import licenseIT from '@locales/it/license.json';
import licenseFR from '@locales/fr/license.json';
import licenseDE from '@locales/de/license.json';
import licenseES from '@locales/es/license.json';
import licensePT_BR from '@locales/pt-br/license.json';
import discount from '@locales/en/discount.json';
import discountIT from '@locales/it/discount.json';
import discountFR from '@locales/fr/discount.json';
import discountDE from '@locales/de/discount.json';
import discountES from '@locales/es/discount.json';
import discountPT_BR from '@locales/pt-br/discount.json';
import multiPages from '@locales/en/multiPages.json';
import multiPagesIT from '@locales/it/multiPages.json';
import multiPagesFR from '@locales/fr/multiPages.json';
import multiPagesDE from '@locales/de/multiPages.json';
import multiPagesES from '@locales/es/multiPages.json';
import multiPagesPT_BR from '@locales/pt-br/multiPages.json';
import dashboard from '@locales/en/dashboard.json';
import dashboardIT from '@locales/it/dashboard.json';
import dashboardFR from '@locales/fr/dashboard.json';
import dashboardDE from '@locales/de/dashboard.json';
import dashboardES from '@locales/es/dashboard.json';
import dashboardPT_BR from '@locales/pt-br/dashboard.json';
import links from '@locales/en/links.json';
import linksIT from '@locales/it/links.json';
import linksFR from '@locales/fr/links.json';
import linksDE from '@locales/de/links.json';
import linksES from '@locales/es/links.json';
import linksPT_BR from '@locales/pt-br/links.json';

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en';
    resources: {
      routes: typeof routes;
      routesIT: typeof routesIT;
      routesFR: typeof routesFR;
      routesDE: typeof routesDE;
      routesES: typeof routesES;
      routesPT_BR: typeof routesPT_BR;
      uraniumBackup: typeof uraniumBackup;
      uraniumBackupIT: typeof uraniumBackupIT;
      uraniumBackupFR: typeof uraniumBackupFR;
      uraniumBackupDE: typeof uraniumBackupDE;
      uraniumBackupES: typeof uraniumBackupES;
      uraniumBackupPT_BR: typeof uraniumBackupPT_BR;
      supremoControl: typeof supremoControl;
      supremoControlIT: typeof supremoControlIT;
      supremoControlFR: typeof supremoControlFR;
      supremoControlDE: typeof supremoControlDE;
      supremoControlES: typeof supremoControlES;
      supremoControlPT_BR: typeof supremoControlPT_BR;
      contacts: typeof contacts;
      contactsIT: typeof contactsIT;
      contactsFR: typeof contactsFR;
      contactsDE: typeof contactsDE;
      contactsES: typeof contactsES;
      contactsPT_BR: typeof contactsPT_BR;
      shop: typeof shop;
      shopIT: typeof shopIT;
      shopFR: typeof shopFR;
      shopDE: typeof shopDE;
      shopES: typeof shopES;
      shopPT_BR: typeof shopPT_BR;
      common: typeof common;
      commonIT: typeof commonIT;
      commonFR: typeof commonFR;
      commonDE: typeof commonDE;
      commonES: typeof commonES;
      commonPT_BR: typeof commonPT_BR;
      forms: typeof forms;
      formsIT: typeof formsIT;
      formsFR: typeof formsFR;
      formsDE: typeof formsDE;
      formsES: typeof formsES;
      formsPT_BR: typeof formsPT_BR;
      cart: typeof cart;
      cartIT: typeof cartIT;
      cartFR: typeof cartFR;
      cartDE: typeof cartDE;
      cartES: typeof cartES;
      cartPT_BR: typeof cartPT_BR;
      checkout: typeof checkout;
      checkoutIT: typeof checkoutIT;
      checkoutFR: typeof checkoutFR;
      checkoutDE: typeof checkoutDE;
      checkoutES: typeof checkoutES;
      checkoutPT_BR: typeof checkoutPT_BR;
      payments: typeof payments;
      paymentsIT: typeof paymentsIT;
      paymentsFR: typeof paymentsFR;
      paymentsDE: typeof paymentsDE;
      paymentsES: typeof paymentsES;
      paymentsPT_BR: typeof paymentsPT_BR;
      purchases: typeof purchases;
      purchasesIT: typeof purchasesIT;
      purchasesFR: typeof purchasesFR;
      purchasesDE: typeof purchasesDE;
      purchasesES: typeof purchasesES;
      purchasesPT_BR: typeof purchasesPT_BR;
      license: typeof license;
      licenseIT: typeof licenseIT;
      licenseFR: typeof licenseFR;
      licenseDE: typeof licenseDE;
      licenseES: typeof licenseES;
      licensePT_BR: typeof licensePT_BR;
      discount: typeof discount;
      discountIT: typeof discountIT;
      discountFR: typeof discountFR;
      discountDE: typeof discountDE;
      discountES: typeof discountES;
      discountPT_BR: typeof discountPT_BR;
      multiPages: typeof multiPages;
      multiPagesIT: typeof multiPagesIT;
      multiPagesFR: typeof multiPagesFR;
      multiPagesDE: typeof multiPagesDE;
      multiPagesES: typeof multiPagesES;
      multiPagesPT_BR: typeof multiPagesPT_BR;
      dashboard: typeof dashboard;
      dashboardIT: typeof dashboardIT;
      dashboardFR: typeof dashboardFR;
      dashboardDE: typeof dashboardDE;
      dashboardES: typeof dashboardES;
      dashboardPT_BR: typeof dashboardPT_BR;
      links: typeof links;
      linksIT: typeof linksIT;
      linksFR: typeof linksFR;
      linksDE: typeof linksDE;
      linksES: typeof linksES;
      linksPT_BR: typeof linksPT_BR;
    };
  }
}

export const resources = {
  'en': {
    routes,
    supremoControl,
    uraniumBackup,
    contacts,
    shop,
    common,
    forms,
    cart,
    checkout,
    payments,
    purchases,
    license,
    discount,
    multiPages,
    dashboard,
    links
  },
  'it': {
    routes: routesIT,
    supremoControl: supremoControlIT,
    uraniumBackup: uraniumBackupIT,
    contacts: contactsIT,
    shop: shopIT,
    common: commonIT,
    forms: formsIT,
    cart: cartIT,
    checkout: checkoutIT,
    payments: paymentsIT,
    purchases: purchasesIT,
    license: licenseIT,
    discount: discountIT,
    multiPages: multiPagesIT,
    dashboard: dashboardIT,
    links: linksIT
  },
  'fr': {
    routes: routesFR,
    supremoControl: supremoControlFR,
    uraniumBackup: uraniumBackupFR,
    contacts: contactsFR,
    shop: shopFR,
    common: commonFR,
    forms: formsFR,
    cart: cartFR,
    checkout: checkoutFR,
    payments: paymentsFR,
    purchases: purchasesFR,
    license: licenseFR,
    discount: discountFR,
    multiPages: multiPagesFR,
    dashboard: dashboardFR,
    links: linksFR
  },
  'de': {
    routes: routesDE,
    supremoControl: supremoControlDE,
    uraniumBackup: uraniumBackupDE,
    contacts: contactsDE,
    shop: shopDE,
    common: commonDE,
    forms: formsDE,
    cart: cartDE,
    checkout: checkoutDE,
    payments: paymentsDE,
    purchases: purchasesDE,
    license: licenseDE,
    discount: discountDE,
    multiPages: multiPagesDE,
    dashboard: dashboardDE,
    links: linksDE
  },
  'es': {
    routes: routesES,
    supremoControl: supremoControlES,
    uraniumBackup: uraniumBackupES,
    contacts: contactsES,
    shop: shopES,
    common: commonES,
    forms: formsES,
    cart: cartES,
    checkout: checkoutES,
    payments: paymentsES,
    purchases: purchasesES,
    license: licenseES,
    discount: discountES,
    multiPages: multiPagesES,
    dashboard: dashboardES,
    links: linksES
  },
  'pt-BR': {
    routes: routesPT_BR,
    supremoControl: supremoControlPT_BR,
    uraniumBackup: uraniumBackupPT_BR,
    contacts: contactsPT_BR,
    shop: shopPT_BR,
    common: commonPT_BR,
    forms: formsPT_BR,
    cart: cartPT_BR,
    checkout: checkoutPT_BR,
    payments: paymentsPT_BR,
    purchases: purchasesPT_BR,
    license: licensePT_BR,
    discount: discountPT_BR,
    multiPages: multiPagesPT_BR,
    dashboard: dashboardPT_BR,
    links: linksPT_BR
  }
} as const;

export const defaultNS = 'en';

i18n.on('languageChanged', function (lng) {
  //console.log({ lng });
  if (lng === 'it') {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/it/shop');
      window.location.replace(newUrl);
    }
  } else if (lng === 'fr') {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/fr/shop');
      window.location.replace(newUrl);
    }
  }else if (lng === 'de') {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/de/shop');
      window.location.replace(newUrl);
    }
  } else if (lng === 'es') {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/es/shop');
      window.location.replace(newUrl);
    }
  } else if (lng === 'pt-BR') {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/pt-br/shop');
      window.location.replace(newUrl);
    }
  } else {
    if (window.location.pathname === '/') {
      const newUrl = window.location.pathname.replace('/', '/shop');
      window.location.replace(newUrl);
    }
  }
  document.documentElement.lang = lng;
});

export const pathnameToLanguage = (() => {
  const result = {};
  for (const [language, { routes }] of Object.entries(resources))
    for (const [, pathname] of Object.entries(routes)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      result[pathname] = language;
    }
  return result;
})();

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'pathname',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  lookup: () => pathnameToLanguage[window?.location?.pathname] as any,
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS,
    detection: {
      order: ['pathname'],
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'it', 'fr', 'de', 'es', 'pt-BR'],
    resources,
    ns: [
      'routes',
      'uraniumBackup',
      'supremoControl',
      'contacts',
      'shop',
      'forms',
      'common',
      'cart',
      'checkout',
      'payments',
      'purchases',
      'license',
      'discount',
      'multiPages',
      'dashboard',
      'links'
    ],
  });

export default i18n;

import React from 'react';
import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';

type Props = WithChildren<{
  title: React.ReactNode | string;
  isDone?: boolean;
  isOpen?: boolean;
  errors?: string;
  handleClick?: () => void;
  noBorderBottom?: boolean;
  formStep: number;
  formId: number;
}>;

export default function AccordionWrapper({
  title,
  children,
  noBorderBottom,
  isDone = false,
  isOpen = false,
  formStep,
  formId,
  errors = '',
  handleClick,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className="Collapsible__trigger"
          style={{
            borderBottom:
              formId === 3
                ? '0 !important'
                : '1px solid rgba(11, 40, 78, 0.25)',
          }}
          onClick={handleClick}
        >
          {title}
        </div>
        <div
          onClick={handleClick}
          className={`Collapsible ${
            noBorderBottom === true ? 'noBorderBottom' : ''
          } ${isOpen ? 'active' : 'is-closed '} ${
            formId === 3 ? 'noBorder' : ''
          }`}
        >
          {children}
        </div>
      </div>

      {errors && (
        <div className={styles.errors}>
          <p>{errors}</p>
        </div>
      )}
    </div>
  );
}

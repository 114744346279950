import { useQuery } from '@tanstack/react-query';
import { activateAutoRenew } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'activate-auto-renew';

export const useActivateAutoRenew = (
  id: string,
  onSuccess = noop,
  onError = noop
) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<string, Error>(
      [KEY, `license-${id}`],
      async (): Promise<string> => {
        return await activateAutoRenew(currentLanguage, id);
      },

      {
        enabled: false,
        onError,
        onSuccess,
      }
    ),
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import MyAccountFormUserInfo from '@components/MyAccountFormUserInfo';
import MyAccountFormPersonalData from '@components/MyAccountFormPersonalData';
import MyAccountFormContacts from '@components/MyAccountFormContacts';
import { useUpdateUserAccountData } from '@hooks/useUpdateUserAccountData';
import { UserDataResponse } from '@utils/types/user';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetUserAccountData } from '@hooks/useGetUserAccountData';

type IProps = {
  showForm: string;
  setShowForm: (field: string) => void;
};

export default function MyAccountForm({ showForm, setShowForm }: IProps) {
  const { data = {}, refetch } = useGetUserAccountData();

  const [searchParams] = useSearchParams();
  const param = searchParams.get('field');

  React.useEffect(() => {
    if (param) {
      setShowForm(param);
    }
  }, [param, setShowForm]);

  const navigate = useNavigate();
  const { t } = useTranslation('routes');

  const backToCheckout = React.useCallback(() => {
    navigate(t('checkout'));
  }, [navigate, t]);

  const onSuccess = React.useCallback(() => {
    if (param) {
      backToCheckout();
    } else {
      refetch();
    }
  }, [param, backToCheckout, refetch]);

  const { mutate, isLoading, isError, error } = useUpdateUserAccountData(onSuccess);

  const onSubmitAccountInfoForm = React.useCallback(
    (data: any) => {
      mutate(data);
    },
    [mutate]
  );

  /**
   *   user type: non è modificabile
   *   partita iva:  non è modificabile
   *   codicePA:  non è modificabile
   *   codice Fiscale:  non è modificabile
   *   username: non è modificabile
   */

  return (
    <div>
      {showForm === 'account' ? (
        <MyAccountFormUserInfo
          onSubmit={onSubmitAccountInfoForm}
          errorsFromApi={error}
          isLoading={isLoading}
          isError={isError}
          userInfoPhase1={data?.phase1}
        />
      ) : showForm === 'data' ? (
        <MyAccountFormPersonalData
          errorsFromApi={error}
          onSubmit={onSubmitAccountInfoForm}
          isLoading={isLoading}
          isError={isError}
          userInfoPhase2={data?.phase2}
        />
      ) : (
        <MyAccountFormContacts
          errorsFromApi={error}
          isCountryItaly={data?.phase2?.country === '1'}
          onSubmit={onSubmitAccountInfoForm}
          isLoading={isLoading}
          isError={isError}
          userInfoPhase3={data?.phase3}
        />
      )}
    </div>
  );
}

import { useMutation } from '@tanstack/react-query';
import { getB2BPecCheck } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'b2b-pec-check';

export const useB2BPecCheck = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<boolean, Error, string>(
      [KEY],
      async (b2bPec: string): Promise<boolean> => {
        return await getB2BPecCheck(currentLanguage, b2bPec);
      },
      {
        onError,
        onSuccess,
      }
    ),
  };
};

import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ProductCard from '@components/ProductCard';
import {
  CartProductItem,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION,
} from '@utils/types/store';
import ControlsSelect from '@components/ControlsSelect';
import LabelWithTooltip from '@components/LabelWithTooltip';
//import RadioButton from '@components/RadioButton';
import TeamInfoTooltip from '@components/TeamInfoTooltip';
import Counter from '@components/Counter';
import Select from '@components/Select';
import { filter, first, multiply, noop } from 'lodash';
import { calculateProductPriceFn } from '@utils/types/helpers';
import { toast } from 'react-toastify';
import ToastCart from '@components/ToastCart';
import { SUBSCRIPTION_DURATION } from '@services/normalize';
import { dataLayerPushAddToCart } from '@utils/tagManagerHelper';

type Props = {
  productDetails: ProductVersion;
  handleAddCart: (CartProductItem: CartProductItem) => void;
  renewOrUpgrade?: string;
  showPromoDesc: boolean;
};

const SupremoSoloProductCard = ({
  productDetails,
  handleAddCart,
  renewOrUpgrade,
  showPromoDesc,
}: Props) => {
  const { t } = useTranslation(['common', 'supremoControl']);

  // STATE

  const [isUpgrade, setIsUpgrade] = React.useState<string | boolean>();
  const [connectionsValue, setConnectionsValue] = React.useState({
    productID: productDetails?.id,
    connection: productDetails.minAmount === 0 ? 1 : productDetails.minAmount,
  });

  const [teamValue, setTeamValue] = React.useState({
    productID: productDetails?.id,
    teamID: productDetails?.profiles && productDetails?.profiles[0]?.id,
    label: productDetails?.profiles && productDetails?.profiles[0]?.name,
  });

  const [quantity, setQuantity] = React.useState(1);

  const onChooseTeam = React.useCallback(
    (productID: number) =>
      ({ id, label }: { id: number; label: string }) => {
        setTeamValue({ productID, teamID: id, label });
      },
    []
  );

  const onChooseConnections = React.useCallback(
    (productID: number) => (connection: number) => {
      setConnectionsValue({ productID, connection });
    },
    []
  );

  const currentProfile = React.useMemo(
    () => filter(productDetails?.profiles, { id: teamValue?.teamID }),
    [productDetails?.profiles, teamValue?.teamID]
  );

  const subProductsPrices = React.useMemo(
    () => productDetails?.subProducts.map((pr) => pr?.price ?? 0) ?? [],
    [productDetails?.subProducts]
  );

  const priceCalculated = React.useMemo(
    () =>
      calculateProductPriceFn({
        productPrice: productDetails?.price,
        profilePrice: Number(first(currentProfile)?.price ?? 0),
        connections: connectionsValue?.connection,
        subProductsPrices,
        minAmount: productDetails?.minAmount,
      }),
    [
      currentProfile,
      productDetails?.minAmount,
      productDetails?.price,
      connectionsValue?.connection,
      subProductsPrices,
    ]
  );

  const finalPrice = multiply(priceCalculated, quantity)?.toLocaleString(
    'it-IT'
  );

  const supremoSoloFeatures = React.useMemo(
    () => [
      t('supremoControl:features.solo.list.1'),
      t('supremoControl:features.solo.list.2'),
      t('supremoControl:features.solo.list.3'),
      t('supremoControl:features.solo.list.4'),
      t('supremoControl:features.solo.list.5'),
      t('supremoControl:features.solo.list.6')
    ],
    [t]
  );

  const handleAddCartFn = React.useCallback(() => {
    handleAddCart({
      productType: PRODUCT_TYPE.SUPREMO,
      id: productDetails?.id,
      quantity,
      isCumulable: productDetails?.isCumulable,
      connections: connectionsValue?.connection,
      teamAssistanceProfileId: teamValue?.teamID,
      hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
      renewOrUpgrade: renewOrUpgrade,
      fromLicense: productDetails.upgradeId,
    });
    const name = `${PRODUCT_TYPE.SUPREMO} ${productDetails?.name}`;
    toast(<ToastCart />, {
      data: {
        quantity,
        price: finalPrice,
        title: name,
      },
    });
    dataLayerPushAddToCart(productDetails?.id, name, priceCalculated, quantity);
  }, [
    productDetails.upgradeId,
    renewOrUpgrade,
    handleAddCart,
    productDetails?.id,
    productDetails?.isCumulable,
    productDetails?.name,
    quantity,
    connectionsValue?.connection,
    teamValue?.teamID,
    priceCalculated,
    finalPrice,
  ]);

  // USE EFFECT

  React.useEffect(() => {
    const isUpgradeRenew =
      renewOrUpgrade === 'UPGRADE'
        ? true
        : renewOrUpgrade === 'RENEW'
          ? false
          : 'shop';

    setIsUpgrade(isUpgradeRenew);
  }, [renewOrUpgrade]);

  const monthlyPrice =
    priceCalculated && priceCalculated / productDetails?.subscriptionDuration;

  return (
    <ProductCard
      subscription={
        productDetails?.subscriptionDuration as SUBSCRIPTION_DURATION
      }
      showPromoDesc={showPromoDesc}
      onAddCart={handleAddCartFn}
      id={productDetails?.id}
      promoDesc={productDetails?.promoDesc}
      description={t('supremoControl:features.solo.description')}
      upgradeId={productDetails?.upgradeId}
      key={productDetails?.id}
      price={multiply(priceCalculated, quantity)?.toLocaleString('it-IT')}
      priceType={t('common:shops.pricePerSubscription')}
      monthlyPrice={monthlyPrice}
      currency={<span>&euro;</span>}
      showPricePerYear
      isUpgrade={isUpgrade}
      subscriptionDurationType={t('common:shops.perMonth')}
      version={PRODUCT_VERSION.SOLO}
      features={supremoSoloFeatures}
      priceDesc='&nbsp;'
      connectionsLabel={
        <LabelWithTooltip
          text={t('common:shops.simultaneousConnections')}
          tooltipOverlay={productDetails?.maxAmount !== 0 && ''}
        />
      }
      expirationDate={productDetails?.newExpire}
      connections={
        productDetails?.maxAmount === 0 ? (
          <p className={styles.single}> {t('common:shops.single')}</p>
        ) : (
          <ControlsSelect
            min={productDetails?.minAmount}
            max={productDetails?.maxAmount}
            onChange={onChooseConnections(productDetails?.id)}
            value={connectionsValue?.connection}
          />
        )
      }
      teamLabel={
        <LabelWithTooltip
          text={t('common:shops.assistanceTeam')}
          tooltipOverlay={
            productDetails?.profiles && (
              <TeamInfoTooltip profiles={productDetails?.profiles} />
            )
          }
        />
      }
      teamAssistance={
        /* 12-10-2023: removed to use always the select
        productDetails?.profiles && productDetails?.profiles.length <= 4 ? (
          <RadioButton<{ id: number; label: string }>
            onChange={onChooseTeam(productDetails?.id)}
            options={productDetails?.profiles?.map((profile) => ({
              ...profile,
              label: profile?.name,
            }))}
            value={{
              id: teamValue?.teamID ?? 0,
              label: teamValue?.label ?? '',
            }}
          />
        ) : */
        productDetails?.profiles && productDetails?.profiles.length > 0 ? (
          <Select<{ id: number; label: string }>
            onChange={onChooseTeam(productDetails?.id)}
            options={productDetails?.profiles?.map((profile) => ({
              ...profile,
              label: profile?.description,
            }))}
            value={{
              id: teamValue?.teamID ?? 0,
              label: teamValue?.label ?? '',
            }}
          />
        ) : (
          <p> {t('common:shops.notActive')}</p>
        )
      }
      counter={
        <Counter
          count={quantity}
          onAdd={setQuantity}
          onChange={setQuantity}
          onRemove={quantity > 1 ? setQuantity : noop}
        />
      }
    />
  );
};

export default SupremoSoloProductCard;

import * as React from 'react';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection, { SECTION_BACKGROUND } from '@components/LayoutSection';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import Button from '@components/Button';
import classNames from 'classnames';
import OrderRecap from '@components/OrderRecap';
import usePaymentResult from '@hooks/usePaymentResult';
import { PAYMENT_RESULT } from '@utils/types/store';
import { CartContext } from '@contexts/CartContext';
import { first } from 'lodash';
import usePaymentRecap from '@hooks/usePaymentRecap';
import useGetUserInfo from '@hooks/useUserInfo';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function PaymentCheck() {
  const { t } = useTranslation(['cart', 'routes', 'common', 'payments']);

  const navigate = useNavigate();

  const { onEmptyCart } = React.useContext(CartContext);

  const location = useLocation();
  const { query } = queryString?.parseUrl(location.search);
  const paymentId: any = query?.paymentId ?? null;
  const result: number = [0, 1].indexOf(Number(query?.result));

  const { data: userInfoPayment } = usePaymentRecap();
  const { data: userInfo } = useGetUserInfo();

  const name = first(userInfoPayment?.userInfo.fullname?.split(' ') ?? []);

  const { data, isLoading } = usePaymentResult(paymentId, result);

  const isPending = isLoading || data?.result === PAYMENT_RESULT.PENDING;

  const paymentSuccess = data?.result === PAYMENT_RESULT.SUCCESS;

  React.useEffect(() => {
    if (paymentSuccess) {
      onEmptyCart();
    }
  }, [onEmptyCart, paymentSuccess]);

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage totalWhite={true}>
        <LayoutSection
          backgroundColor={SECTION_BACKGROUND.WHITE}
          isFullwidthWrap
        >
          {!Boolean(paymentId) ?
            <div className={styles.banner}>
              <h1>{t('payments:paymentResult.title.noPaymentId')}</h1>
              <p>{t('payments:paymentResult.description.noPaymentId')}</p>
            </div>
            :
            <div
              className={classNames(styles.banner, {
                [styles.bannerError]: !paymentSuccess,
              })}
            >
              {isPending ? (
                <h1>{t('payments:paymentResult.title.pending')}</h1>
              ) : (
                <h1>
                  {paymentSuccess ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('payments:paymentResult.title.success', {
                          value: name,
                          interpolation: {
                            escapeValue: false,
                          },
                        }),
                      }}
                    ></div>
                  ) : (
                    t('payments:paymentResult.title.declined')
                  )}
                </h1>
              )}
              {isPending ? (
                <p>{t('payments:paymentResult.description.pending')}</p>
              ) : (
                <p>
                  {paymentSuccess ? (
                    <>
                      {t('payments:paymentResult.description.success')}{' '}
                      <strong className={styles.success}>
                        {userInfo?.email}
                        <span className="icon-check-circle"></span>
                      </strong>
                    </>
                  ) : (
                    <>
                      {t('payments:paymentResult.description.declined')}{' '}
                      <strong className={styles.error}>
                        {' '}
                        <span className="icon-x-circle"></span>
                      </strong>
                    </>
                  )}
                </p>
              )}
              {!isPending && (
                <>
                  {paymentSuccess ? (
                    <Button onClick={() => navigate(t('routes:license'))}>
                      {t('payments:paymentResult.labelCta.success')}
                    </Button>
                  ) : (
                    <Button onClick={() => navigate(t('routes:checkout'))}>
                      {t('payments:paymentResult.labelCta.declined')}
                    </Button> // vai al checkout
                  )}
                </>
              )}
            </div>
          }
        </LayoutSection>
        <LayoutSection backgroundColor={SECTION_BACKGROUND.WHITE} hasNarrowWrap>
          {Boolean(paymentId) && <OrderRecap />}
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getLicenseToRenew } from '@services/api';

import { noop } from 'lodash';

export const KEY = 'get-license-to-renew';

export default function useGetLicenseToRenew(
  enabled: boolean,
  onSuccess?: typeof noop,
  onError?: typeof noop
) {
  return {
    ...useQuery<number, Error>([KEY], () => getLicenseToRenew(), {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
      enabled,
    }),
  };
}

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { UIProvider } from '@contexts/UIContext';
import { CartProvider } from '@contexts/CartContext';
import { UserProvider } from '@contexts/UserContext';
import { HelmetProvider } from 'react-helmet-async';

import { createRoot } from 'react-dom/client';

const client = new QueryClient();

const container: any = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Router>
    <QueryClientProvider client={client}>
      <HelmetProvider>
        <UserProvider>
          <CartProvider>
            <UIProvider>
              <App />
            </UIProvider>
          </CartProvider>
        </UserProvider>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Counter from '@components/Counter';
import { t } from 'i18next';
import styles from './styles.module.scss';
import Clamp from 'react-multiline-clamp';
import Price from '@components/Price';
import { ProductVersion } from '@utils/types/store';

type Props = {
  title: string;
  description: string;
  price: number;
  quantity: number;
  hasStaticQuantity?: boolean;
  onAddItem: (count: number) => void;
  onChangeItem: (count: number) => void;
  onRemoveItem: (count: number) => void;
  onDeleteAllItems: () => void;
  hideCounter?: boolean;
  renewOrUpgrade?: string;
  fromLicense?: number;
  subProducts?: Partial<ProductVersion[]>;
};
export default function CartItem({
  title,
  description,
  price,
  quantity = 1,
  hasStaticQuantity = false,
  onAddItem,
  onChangeItem,
  onRemoveItem,
  onDeleteAllItems,
  hideCounter,
  renewOrUpgrade,
  fromLicense,
  subProducts
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <div className={styles.topLeftContent}>
          <p style={{ marginBottom: 8 }}>
            <strong>
              {renewOrUpgrade === 'UPGRADE' ?
                <>
                  {t('common:shops.upgradeFromLicense', { license: fromLicense })}
                </>
                :
                <>
                  {renewOrUpgrade === 'RENEW' ? t('common:shops.renew') : ''}{' '}
                  {title}
                </>}
            </strong>
          </p>
          <p>
            {fromLicense !== 0 && renewOrUpgrade === 'RENEW' && t('common:shops.renewFromLicense', { license: fromLicense })}
          </p>
          {renewOrUpgrade === 'UPGRADE' ?
            <Clamp lines={(subProducts ? subProducts?.length : 0) + 2}>
              <p className={styles.description}>{title}</p>
              {subProducts?.map((p, i) => <p className={styles.description} key={i}>{p?.name}</p>)}
              <p className={styles.description}>{description}</p>
            </Clamp>
            :
            <Clamp lines={1}>
              <p className={styles.description}>{description}</p>
            </Clamp>}
        </div>

        {hasStaticQuantity ? (
          <p className={styles.staticQuantity}>
            {`${t('common:shops.quantityLong')} ${quantity}x`}
          </p>
        ) : (
          <Counter
            // count={hideCounter ? 1 : quantity}
            count={quantity}
            onAdd={onAddItem}
            onChange={onChangeItem}
            onRemove={onRemoveItem}
            onTotalRemove={onDeleteAllItems}
            hideCounter={hideCounter}
          />
        )}
      </div>
      <div className={styles.rightContent}>
        <p>
          <strong><Price value={price} /></strong>
        </p>
      </div>
    </div>
  );
}

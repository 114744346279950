import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ProductCard from '@components/ProductCard';
import {
  CartProductItem,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION,
} from '@utils/types/store';
import { camelCase, multiply, noop, startCase } from 'lodash';
import Counter from '@components/Counter';
import { toast } from 'react-toastify';
import ToastCart from '@components/ToastCart';
import { dataLayerPushAddToCart } from '@utils/tagManagerHelper';

type Props = {
  productDetails: ProductVersion;
  windowWidth: any;
  handleAddCart: (CartProductItem: CartProductItem) => void;
  renewOrUpgrade?: string;
  showPromoDesc?: boolean;
};

const UraniumGoldProductCard = ({
  productDetails,
  windowWidth,
  handleAddCart,
  renewOrUpgrade,
  showPromoDesc,
}: Props) => {
  const { t } = useTranslation(['common', 'uraniumBackup']);
  const [isUpgrade, setIsUpgrade] = React.useState<string | boolean>();
  const [quantity, setQuantity] = React.useState(1);

  const finalPrice = multiply(productDetails?.price, quantity)?.toLocaleString(
    'it-IT'
  );

  const handleAddCartFn = React.useCallback(() => {
    const name = `${productDetails?.name}`;
    if (isUpgrade === true) {
      handleAddCart({
        productType: PRODUCT_TYPE.URANIUM,
        id: productDetails?.id,
        quantity: quantity,
        isCumulable: productDetails?.isCumulable,
        upgradeId: productDetails?.upgradeId,
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: productDetails.upgradeId,
      });
      toast(<ToastCart />, {
        data: {
          quantity,
          price: finalPrice,
          title: name,
        },
      });
      dataLayerPushAddToCart(productDetails?.id, name, productDetails?.price, quantity);
    } else {
      handleAddCart({
        productType: PRODUCT_TYPE.URANIUM,
        id: productDetails?.id,
        quantity: quantity,
        isCumulable: productDetails?.isCumulable,
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: productDetails.upgradeId,
      });
      toast(<ToastCart />, {
        data: {
          quantity,
          price: finalPrice,
          title: name,
        },
      });
      dataLayerPushAddToCart(productDetails?.id, name, productDetails?.price, quantity);
    }
  }, [
    renewOrUpgrade,
    handleAddCart,
    productDetails?.id,
    productDetails?.isCumulable,
    productDetails?.name,
    quantity,
    finalPrice,
    isUpgrade,
    productDetails?.upgradeId,
    productDetails?.price
  ]);

  // USE EFFECT

  React.useEffect(() => {
    const isUpgradeRenew =
      renewOrUpgrade === 'UPGRADE'
        ? true
        : renewOrUpgrade === 'RENEW'
          ? false
          : 'shop';

    setIsUpgrade(isUpgradeRenew);
  }, [renewOrUpgrade]);

  return (
    <ProductCard
      isActive
      showPromoDesc={showPromoDesc}
      onAddCart={handleAddCartFn}
      description={productDetails?.description}
      key={productDetails?.id}
      price={finalPrice}
      id={productDetails?.id}
      promoDesc={productDetails?.promoDesc}
      upgradeId={productDetails?.upgradeId}
      monthlyPrice={productDetails?.price}
      currency={<span>&euro;</span>}
      isUpgrade={isUpgrade}
      showPricePerYear={false}
      version={PRODUCT_VERSION.GOLD}
      isHorizontal={windowWidth > 1290 ? true : false}
      subscriptionDurationType={t('common:shops.forever')}
      goldVersion={
        <Trans
          defaults={t('uraniumBackup:uraniumGold.title', {
            version: startCase(camelCase(PRODUCT_VERSION.GOLD)),
          })}
        />
      }
      counter={
        <Counter
          count={quantity}
          onAdd={setQuantity}
          onChange={setQuantity}
          onRemove={quantity > 1 ? setQuantity : noop}
        />
      }
    />
  );
};

export default UraniumGoldProductCard;

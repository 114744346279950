import { useQuery } from '@tanstack/react-query';
import { getUpgradeRenewProduct } from '@services/api';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const KEY = 'upgrade-renew-products';

export default function useUpgradeRenewProduct(
  onSuccess = noop,
  onError = noop
) {
  const location = useLocation();
  const { query } = queryString?.parseUrl(location.search);
  const licenseId: any = query?.licenseId ?? null;
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<any, Error>(
      [KEY, licenseId],
      () => getUpgradeRenewProduct(currentLanguage, licenseId ?? ''),
      {
        enabled: Boolean(licenseId),
        onSuccess,
        onError,
        retry: 0,
      }
    ),
  };
}

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { noop } from 'lodash';

type Props = {
  price: string;
  onClick: () => void;
  description: string;
  image: string;
  priceType: string;
};

const ChooseProductBox = ({
  description,
  image,
  price,
  priceType,
  onClick = noop,
}: Props) => {
  const { t } = useTranslation(['routes', 'common', 'shop']);

  return (
    <div className={styles.container}>
      <img src={image} className="attachment-full size-full" alt="logo" />

      <p className={styles.description}>{description}</p>
      <span>{t('common:shops.startingFrom')}</span>
      <p className={styles.price}>{price}</p>
      <span className={styles.priceType}>{priceType}</span>
      <Button onClick={onClick} fullWidth>
        {t('common:shops.buy')}
      </Button>
    </div>
  );
};

export default ChooseProductBox;

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description: string;
  goTo: string;
};
export default function UpsellingBanner({ title, description, goTo }: Props) {
  const { t } = useTranslation(['cart', 'routes', 'common']);

  return (
    <div className={styles.container}>
      <p>
        <strong>{title}</strong>
      </p>
      <p>{description}</p>
      <Link to={goTo} className={'linkBlue'}>
        {t('cart:upsellingBanner.ctaLabel')}
      </Link>
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useWindowScroll from 'beautiful-react-hooks/useWindowScroll';
import NavBar from '@components/NavBar';
import NanosystemsLogo from '@assets/nanosystems-base/images/logo.svg';

export enum HEADER_TYPE {
  DEFAULT = 'default',
  CHECKOUT = 'checkout',
}

type Props = {
  type?: HEADER_TYPE;
  isAvatarHighlighted?: boolean;
};

const Header = ({
  type = HEADER_TYPE.DEFAULT,
  isAvatarHighlighted = false,
}: Props) => {
  const { t } = useTranslation(['common', 'links']);
  const [isHeaderScrolled, setIsHeaderScrolled] = React.useState(false);

  const onWindowScroll = useWindowScroll();
  onWindowScroll(() => {
    window.scrollY >= 100
      ? setIsHeaderScrolled(true)
      : setIsHeaderScrolled(false);
  });

  function renderNav(type: HEADER_TYPE) {
    if (type === HEADER_TYPE.DEFAULT) {
      return <NavBar isAvatarHighlighted={isAvatarHighlighted} />;
    }

    if (type === HEADER_TYPE.CHECKOUT) {
      return <NavBar isAvatarHighlighted={isAvatarHighlighted} />;
    }
  }

  return (
    <header className={isHeaderScrolled ? 'header header--scrolled' : 'header'}>
      <div className="wrap wrap--extended">
        <a rel="noopener noreferrer" className="header__logo" href={t('links:nanosystems.home')}>
          <img
            src={NanosystemsLogo}
            className="attachment-full size-full"
            alt={t('common:nanosystems')}
          />
        </a>

        <>
          <input id="hamburger" type="checkbox" />
          <label htmlFor="hamburger">
            <i></i>
          </label>
        </>

        {renderNav(type)}
      </div>
    </header>
  );
};

export default Header;

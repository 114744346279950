import * as React from 'react';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

function UraniumTableHeader() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const hideColumn = windowWidth < 1200;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const columnHelper = createColumnHelper<any>();
  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.free, {
      id: 'free',
      cell: (info) => info.getValue(),
      header: () => <span>Free</span>,
    }),
    columnHelper.accessor((row) => row.base, {
      id: 'base',
      cell: (info) => info.getValue(),
      header: () => <span>Base</span>,
    }),
    columnHelper.accessor((row) => row.proTape, {
      id: 'proTape',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Tape</span>,
    }),
    columnHelper.accessor((row) => row.proDB, {
      id: 'proDB',
      cell: (info) => info.getValue(),
      header: () => <span>Pro DB</span>,
    }),
    columnHelper.accessor((row) => row.proShadow, {
      id: 'proShadow',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Shadow</span>,
    }),
    columnHelper.accessor((row) => row.proVirtual, {
      id: 'proVirtual',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Virtual</span>,
    }),
    columnHelper.accessor((row) => row.gold, {
      id: 'gold',
      cell: (info) => info.getValue(),
      header: () => <span style={{ color: '#F2C94C' }}>Gold</span>,
    }),
  ];
  return (
    <BasicTable
      isBodyHidden
      product="uranium"
      isHeaderHidden={hideColumn}
      isTitleRow
      data={[]}
      columns={versionColumns}
    />
  );
}

export default UraniumTableHeader;

import Card from '@components/Card';
import OrderedTitle from '@components/OrderedTitle';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import supremoProBkg4 from '@assets/images/supremoActivation4.png';
import uraniumProBkg4 from '@assets/images/uraniumBkg5.png';

import styles from './styles.module.scss';

type Props = {
  onGetActivationDate: ({
    id,
    isFromModal,
  }: {
    id: string;
    isFromModal: boolean;
  }) => void;
  onGetActivationCode: ({
    id,
    machineCode,
  }: {
    id: string;
    machineCode: string;
  }) => void;
  licenseActivationCode: string;
  id: string;
  expirationDate: string;
};
export default function ModalUraniumBkpProActivation({
  onGetActivationDate,
  onGetActivationCode,
  expirationDate,
  licenseActivationCode,
  id,
}: Props) {
  const { t } = useTranslation(['license', 'routes', 'links']);

  const { copy } = useCopyToClipboard();

  React.useEffect(() => {
    onGetActivationDate({
      id,
      isFromModal: true,
    });
  }, [onGetActivationDate, id]);

  const onGenerateActivationCodeFn = React.useCallback(() => {
    onGetActivationCode({ id, machineCode: '' });
  }, [id, onGetActivationCode]);

  const navigateToSupremoConsole = () => {
    const url = t('links:console.home');
    if (typeof url === 'string') {
      window.open(url, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <OrderedTitle
          number="1"
          title={t('license:uraniumBkpProfessional.step1.title')}
          onClick={onGenerateActivationCodeFn}
        />

        <div className={styles.input}>
          <input
            readOnly
            value={licenseActivationCode}
            placeholder={
              licenseActivationCode
                ? licenseActivationCode
                : t('license:activationCodeLong')
            }
          />
          <div
            className={styles.copy}
            onClick={() => copy(licenseActivationCode)}
          >
            <span className="icon-copy"></span>{' '}
          </div>
        </div>
        <p className={styles.description}>
          {t('license:uraniumBkpProfessional.step1.description', { expiredDate: expirationDate })}
        </p>
      </Card>

      <Card>
        <OrderedTitle
          number="2"
          title={t('license:uraniumBkpProfessional.step2.title')}
          onClick={navigateToSupremoConsole}
        />
      </Card>

      <Card>
        <OrderedTitle
          number="3"
          title={t('license:supremoProfessional.step6.title')}
          url={t('links:console.home')}
        />
        <div
          className={styles.imageContainer}
          style={{ justifyContent: 'flex-start' }}
        >
          <img
            src={supremoProBkg4}
            alt="Uranium pro activation"
          />
        </div>
      </Card>
      <Card>
        <OrderedTitle
          number="4"
          title={t('license:uraniumBkpProfessional.step4.title')}
          url={t('links:console.home')}
        />
        <div className={styles.imageContainer}>
          <img src={uraniumProBkg4} alt="Uranium pro activation" />
        </div>
      </Card>
    </div>
  );
}

import * as React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
  hasMaxWidth?: boolean;
};
export default function BlockFormWrapper({
  hasMaxWidth,
  title,
  children,
}: Props) {
  return (
    <div
      className={`${styles.container} container ${
        hasMaxWidth ? styles.maxWidth : ''
      }`}
    >
      {title && (
        <div className={styles.header}>
          <p>{title}</p>
        </div>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCard from '@components/ProductCard';
import logoConsole from '@assets/nanosystems-base/images/Uranium-Backup-Professional.svg';
import Counter from '@components/Counter';
import { multiply, noop } from 'lodash';
import {
  CartProductItem,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION,
} from '@utils/types/store';
import { toast } from 'react-toastify';
import ToastCart from '@components/ToastCart';
import { dataLayerPushAddToCart } from '@utils/tagManagerHelper';

type Props = {
  productDetails: ProductVersion;
  windowWidth: any;
  handleAddCart: (CartProductItem: CartProductItem) => void;
  renewOrUpgrade?: string;
  showPromoDesc?: boolean;
};

const SupremoConsoleProductCard = ({
  productDetails,
  windowWidth,
  handleAddCart,
  renewOrUpgrade,
  showPromoDesc = false,
}: Props) => {
  const { t } = useTranslation(['common', 'uraniumBackup']);
  const [isUpgrade, setIsUpgrade] = React.useState<string | boolean>();
  const [quantity, setQuantity] = React.useState(1);
  const finalPrice = multiply(productDetails?.price, quantity)?.toLocaleString(
    'it-IT'
  );

  const handleAddCartFn = React.useCallback(() => {
    handleAddCart({
      productType: PRODUCT_TYPE.URANIUM,
      id: productDetails?.id,
      quantity,
      isCumulable: productDetails?.isCumulable,
      hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
      renewOrUpgrade: renewOrUpgrade,
      fromLicense: productDetails.upgradeId,
    });
    const name = `${productDetails?.name}`;
    toast(<ToastCart />, {
      data: {
        quantity,
        price: finalPrice,
        title: name,
      },
    });
    dataLayerPushAddToCart(productDetails?.id, name, productDetails?.price, quantity);
  }, [
    productDetails.upgradeId,
    renewOrUpgrade,
    quantity,
    handleAddCart,
    productDetails?.id,
    productDetails?.isCumulable,
    finalPrice,
    productDetails?.name,
    productDetails?.price
  ]);

  React.useEffect(() => {
    const isUpgradeRenew =
      renewOrUpgrade === 'UPGRADE'
        ? true
        : renewOrUpgrade === 'RENEW'
        ? false
        : 'shop';

    setIsUpgrade(isUpgradeRenew);
  }, [renewOrUpgrade]);

  const monthlyPrice =
    productDetails &&
    productDetails?.price / productDetails?.subscriptionDuration;
  return (
    <ProductCard
      showPromoDesc={showPromoDesc}
      onAddCart={handleAddCartFn}
      description={productDetails?.description}
      key={productDetails?.id}
      id={productDetails?.id}
      upgradeId={productDetails?.upgradeId}
      price={multiply(productDetails?.price, quantity)?.toLocaleString('it-IT')}
      monthlyPrice={monthlyPrice}
      isUpgrade={isUpgrade}
      currency={<span>&euro;</span>}
      showPricePerYear={false}
      subscriptionDurationType={t('common:shops.perMonth')}
      logo={logoConsole}
      isHorizontal={windowWidth > 1290 ? true : false}
      isAusilio
      version={PRODUCT_VERSION.AUSILIO}
      promoDesc={productDetails?.promoDesc}
      expirationDate={productDetails?.newExpire}
      counter={
        <Counter
          count={quantity}
          onAdd={setQuantity}
          onChange={setQuantity}
          onRemove={quantity > 1 ? setQuantity : noop}
        />
      }
    />
  );
};

export default SupremoConsoleProductCard;

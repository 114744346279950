import Card from '@components/Card';
import OrderedTitle from '@components/OrderedTitle';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import uraniumBkgImage from '@assets/images/uraniumBkpActivation.png';
import uraniumBkgImage2 from '@assets/images/uraniumBkpActivation2.png';
import uraniumBkgImage3 from '@assets/images/uraniumBkpActivation3.png';
import styles from './styles.module.scss';
import Button, { BUTTON_SIZE, BUTTON_STYLE } from '@components/Button';
import { ERRORS_TYPE } from '@services/normalize';

type Props = {
  setCurrentMachineCodeFn: (machineCode: string) => void;
  onGetActivationCode: ({
    id,
    machineCode,
  }: {
    id: string;
    machineCode: string;
  }) => void;
  id: string;
  licenseActivationCode: string;
  isLoadingActivationCode: boolean;
  isErrorActivationCode: any;
};

export default function ModalUraniumActivation({
  onGetActivationCode,
  id,
  licenseActivationCode,
  setCurrentMachineCodeFn,
  isLoadingActivationCode,
  isErrorActivationCode,
}: Props) {
  const { t } = useTranslation(['license', 'routes']);

  const [machineCode, setMachineCode] = React.useState('');

  const onGetActivationCodeFn = React.useCallback(() => {
    onGetActivationCode({ id, machineCode });
    setCurrentMachineCodeFn(machineCode);
  }, [id, machineCode, onGetActivationCode, setCurrentMachineCodeFn]);

  const onChangeMachineCode = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMachineCode(e?.target?.value);
    },
    []
  );
  const { copy } = useCopyToClipboard();

  return (
    <div className={styles.container}>
      <Card>
        <OrderedTitle
          number="1"
          title={t('license:uraniumBkp.step1.title')}
          url={t('links:uranium.download')}
        />
      </Card>
      <Card>
        <OrderedTitle number="2" title={t('license:uraniumBkp.step2.title')} />
        <div className={styles.imageContainer}>
          <img src={uraniumBkgImage} alt="Uranium backup activation" />
        </div>
      </Card>
      <Card>
        <OrderedTitle number="3" title={t('license:uraniumBkp.step3.title')} />
        <div className={styles.imageContainer}>
          <img src={uraniumBkgImage2} alt="Uranium backup activation" />
        </div>
      </Card>

      <Card>
        <OrderedTitle number="4" title={t('license:uraniumBkp.step4.title')} />

        <div className={styles.input}>
          <span>{t('license:machineCodeShort')}</span>
          <input
            onChange={onChangeMachineCode}
            value={machineCode}
            className={
              isErrorActivationCode !== null &&
              (isErrorActivationCode as any).type === ERRORS_TYPE.INVALID_CODE
                ? styles.error
                : ''
            }
          />
        </div>

        {licenseActivationCode ? (
          <div className={styles.input}>
            <span>{t('license:activationCodeShort')}</span>
            <input
              style={{ paddingRight: '40px' }}
              value={licenseActivationCode}
              readOnly
            />
            {licenseActivationCode && (
              <div
                className={styles.copy}
                onClick={() => copy(licenseActivationCode)}
              >
                <span className="icon-copy"></span>{' '}
              </div>
            )}
          </div>
        ) : (
          <>
            <Button
              size={BUTTON_SIZE.SMALL}
              disabled={!machineCode}
              styleBtn={BUTTON_STYLE.SECONDARY}
              onClick={onGetActivationCodeFn}
              isLoading={isLoadingActivationCode}
            >
              {t('license:generateActivationCode')}
            </Button>
            {isErrorActivationCode && (
              <div className={styles.errors}>
                <p>{isErrorActivationCode.message}</p>
              </div>
            )}
          </>
        )}
      </Card>
      <Card>
        <OrderedTitle number="5" title={t('license:uraniumBkp.step5.title')} />
        <div className={styles.imageContainer}>
          <img src={uraniumBkgImage3} alt="Uranium backup activation" />
        </div>
      </Card>
    </div>
  );
}

import styles from './styles.module.scss';
import SupremoControlLogo from '@assets/nanosystems-base/images/logo-supremo-control.svg';
import uraniumBackupLogo from '@assets/images/uranium-backup.svg';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
type Props = {
  isSupremoPage: boolean;
};
export const SelectProductButton = ({ isSupremoPage }: Props) => {
  return (
    <div className={styles.container}>
      <Link
        className={`${styles.item} ${isSupremoPage ? styles.isActive : ''}`}
        to={t('routes:supremoControl')}
        aria-current="page"
      >
        <>
          <img src={SupremoControlLogo} alt="" />
        </>
      </Link>

      <Link
        className={`${styles.item} ${!isSupremoPage ? styles.isActive : ''}`}
        to={t('routes:uraniumBackup')}
        aria-current="page"
      >
        <>
          <img src={uraniumBackupLogo} alt="" />
        </>
      </Link>
    </div>
  );
};

import * as React from 'react';
import Button, { BUTTON_SIZE, BUTTON_STYLE } from '@components/Button';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { useTranslation } from 'react-i18next';
import { LICENSE_STATUS, RENEW_STATUS } from '@utils/types/licenses';
import { PRODUCT_TYPE } from '@utils/types/store';
import {
  checkActivationDateResponse,
  useCheckActivationDate,
} from '@hooks/useCheckActivationDate';
import Spinner from '@components/Spinner';
import Badge, { BADGE_TYPE } from '@components/Badge';
import { useGetActivationCode } from '@hooks/useGetActivationCode';
import { differenceInDays, parse } from 'date-fns';
import Tooltip from 'rc-tooltip';
import { useActivateAutoRenew } from '@hooks/useActivateAutoRenew';
import { useDeactivateAutoRenew } from '@hooks/useDeactivateAutoRenew';
import { ERRORS_TYPE } from '@services/normalize';
import { useGetLicensePDF } from '@hooks/useGetLicensePDF';
import ModalActivationProduct from '@components/ModalActivationProduct';
import ModalSuspendAutoRenew from '@components/ModalSuspendAutoRenew';
import { useNavigate } from 'react-router-dom';

type Props = {
  type: string;
  id: string;
  activationDate?: string;
  expireDate?: string;
  renewOrSuspendType: RENEW_STATUS;
  isUpgradable?: boolean;
  title: string;
  description?: string;
  onUpdateDescriptionFn: ({ id, note }: { id: string; note: string }) => void;
  activationCode?: string;
  machineCode?: string;
  isRenewable: boolean;
  isActive: boolean;
  isProfessional: boolean;
  licenseStatus: LICENSE_STATUS;
};
export default function License({
  type,
  licenseStatus,
  id,
  activationDate,
  expireDate,
  renewOrSuspendType,
  isRenewable,
  isUpgradable = false,
  title,
  isActive,
  description,
  onUpdateDescriptionFn,
  activationCode = '',
  machineCode = '',
  isProfessional,
}: Props) {
  const { t } = useTranslation(['common', 'license', 'routes']);
  const { copy } = useCopyToClipboard();
  const ref = React.useRef<HTMLInputElement>(null);

  // STATE

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isExpired, setIsExpired] = React.useState<boolean>(false);
  const [daysLeft, setDaysLeft] = React.useState<number>();
  const [descriptionIsEditing, setDescriptionIsEditing] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveLicense, setIsActiveLicense] = React.useState(isActive);
  const [futureExpirationDate, setFutureExpirationDate] = React.useState('');
  const [expirationDate, setExpirationDate] = React.useState(expireDate);
  const [showSuspendedMessage, setShowSuspendedMessage] = React.useState(false);
  const [supremoActivationModalOpen, setActivationModalOpen] =
    React.useState(false);
  const [suspendAutoRenewModalOpen, setSuspendAutoRenewModalOpen] =
    React.useState(false);
  const [activationRenewStatus, setActivationRenewStatus] =
    React.useState<RENEW_STATUS>(renewOrSuspendType);
  const [currentDescription, setCurrentDescription] =
    React.useState(description);
  const [licenseActivationCode, setLicenseActivationCode] =
    React.useState(activationCode);
  const [currentMachineCode, setCurrentMachineCode] =
    React.useState(machineCode);
  const [showConfirmActivation, setShowConfirmActivation] =
    React.useState(false);

  //

  const navigate = useNavigate();

  const onCancelActivation = React.useCallback(() => {
    setShowConfirmActivation(false);
  }, []);

  // ON SUCCESS CALLBACK

  const onSuccessActivationDate = React.useCallback(
    (date: checkActivationDateResponse) => {
      if (!date?.isFromModal) {
        setShowConfirmActivation(true);
      }
      setFutureExpirationDate(date?.expirationDate);
    },
    []
  );

  const onSuccessActivateAutoRenew = React.useCallback((url: string) => {
    if (url === '') {
      setActivationRenewStatus(RENEW_STATUS.ENABLED);
    } else {
      url && window.open(url, '_self');
    }
    setShowSuspendedMessage(false);
    onCloseSuspendAutoRenewModalFn();
  }, []);

  const onSuccessDeactivateAutoRenew = React.useCallback((status: boolean) => {
    if (Boolean(status)) {
      setActivationRenewStatus(RENEW_STATUS.DISABLED);
      setShowSuspendedMessage(true);
    }
  }, []);

  const onSuccessActivationCode = React.useCallback(
    (data: string) => {
      setLicenseActivationCode(data);
      setIsActiveLicense(true);
      setExpirationDate(futureExpirationDate);
      setShowConfirmActivation(false);
    },
    [futureExpirationDate]
  );

  const onSuccessGetLicensePDF = React.useCallback((urlPDF: string) => {
    urlPDF && window.open(urlPDF, '_blank');
  }, []);

  // HOOKS

  const {
    data: calculateExpireDate,
    mutate: onGetActivationDate,
    isLoading: isLoadingCalculateExpireDate,
    error: errorCalculateExpireDate,
  } = useCheckActivationDate((data) => onSuccessActivationDate(data));

  const {
    mutate: onGetActivationCode,
    isLoading: isLoadingActivationCode,
    error: errorGetActivationCode,
  } = useGetActivationCode((data) => onSuccessActivationCode(data));

  const {
    mutate: onGetLicensePDF,
    isLoading: isLoadingOnGetLicensePDF,
    error: errorOnGetLicensePDF,
  } = useGetLicensePDF(id, (data) => onSuccessGetLicensePDF(data));

  const {
    isFetching: isFetchingActivateAutoRenew,
    refetch: activateAutoRenewFn,
  } = useActivateAutoRenew(id, onSuccessActivateAutoRenew);

  const {
    isFetching: isFetchingDeactivateAutoRenew,
    refetch: deactivateAutoRenewFn,
  } = useDeactivateAutoRenew(id, onSuccessDeactivateAutoRenew);

  // USE EFFECT

  React.useEffect(() => {
    if (descriptionIsEditing) {
      ref?.current?.focus();
    }
  }, [descriptionIsEditing]);

  React.useEffect(() => {
    if (expirationDate) {
      const differenceDays = differenceInDays(
        parse(expirationDate, 'dd/MM/yyyy', new Date()),
        new Date()
      );

      setDaysLeft(differenceDays);

      if (differenceDays <= 0) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    }
  }, [expirationDate]);

  // MODALS CALLBACKS

  const onCloseSuspendAutoRenewModalFn = React.useCallback(() => {
    setSuspendAutoRenewModalOpen(false);
    setShowSuspendedMessage(false);
  }, [setSuspendAutoRenewModalOpen]);

  const onOpenSuspendAutoRenewModalFn = React.useCallback(() => {
    setSuspendAutoRenewModalOpen(true);
  }, [setSuspendAutoRenewModalOpen]);

  const onCloseActivationModalFn = React.useCallback(() => {
    setActivationModalOpen(false);
    onCancelActivation();
  }, [setActivationModalOpen, onCancelActivation]);

  const onOpenActivationModalFn = React.useCallback(() => {
    setActivationModalOpen(true);
  }, [setActivationModalOpen]);

  // USER NOTE CALLBACK

  const onEditingFn = React.useCallback(() => {
    setDescriptionIsEditing(true);
  }, []);

  const onChangeDescriptionFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentDescription(e?.target?.value);
    },
    []
  );

  const onChangeDescriptionSubmitFn = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (typeof currentDescription === 'string') {
        onUpdateDescriptionFn({ id, note: currentDescription });
      }
      setDescriptionIsEditing(false);
    },
    [currentDescription, id, onUpdateDescriptionFn]
  );

  const onBlur = React.useCallback(() => {
    if (typeof currentDescription === 'string') {
      onUpdateDescriptionFn({ id, note: currentDescription });
    }
    setDescriptionIsEditing(false);
  }, [currentDescription, id, onUpdateDescriptionFn]);

  // MACHINE CODE CALLBACK

  const onChangeCurrentMachineCodeFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (machineCode) {
        return;
      }
      setCurrentMachineCode(e?.target?.value);
    },
    [machineCode]
  );

  const callAutoRenew = () => {
    if (
      activationRenewStatus === RENEW_STATUS.DISABLED ||
      activationRenewStatus === RENEW_STATUS.AGREEMENT_ISSUE
    ) {
      activateAutoRenewFn();
    }
    if (activationRenewStatus === RENEW_STATUS.ENABLED) {
      onOpenSuspendAutoRenewModalFn();
    }
  };

  const goToUpgradePage = () => {
    navigate({
      pathname: t('routes:upgrade'),
      search: `?licenseId=${id}`,
    });
  };

  return (
    <>
      <ModalActivationProduct
        onClose={onCloseActivationModalFn}
        isOpen={supremoActivationModalOpen}
        productType={type as PRODUCT_TYPE}
        onGetActivationDate={onGetActivationDate}
        onGetActivationCode={onGetActivationCode}
        supremoExpirationDate={calculateExpireDate?.expirationDate ?? ''}
        id={id}
        licenseActivationCode={licenseActivationCode}
        setCurrentMachineCodeFn={setCurrentMachineCode}
        isLoadingActivationCode={isLoadingActivationCode}
        isErrorActivationCode={errorGetActivationCode}
        isProfessional={isProfessional}
      />
      <ModalSuspendAutoRenew
        note={currentDescription ?? ''}
        title={title}
        expirationData={expirationDate ?? ''}
        activationDate={activationDate ?? ''}
        id={id}
        onClose={onCloseSuspendAutoRenewModalFn}
        isOpen={suspendAutoRenewModalOpen}
        showSuspendedMessage={showSuspendedMessage}
        daysLeft={daysLeft}
        suspendFn={deactivateAutoRenewFn}
        reactivateFn={activateAutoRenewFn}
        isLoadingDeactivateAutoRenew={isFetchingDeactivateAutoRenew}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContentLeft}>
            {id && (
              <div className={styles.headerInfoBlock}>
                <span>{t('license:code')}</span>
                <p>{id}</p>
              </div>
            )}
            {activationDate && (
              <div className={styles.headerInfoBlock}>
                <span>{t('license:activation')}</span>
                <p>{activationDate}</p>
              </div>
            )}
            {licenseStatus === LICENSE_STATUS.NON_ACTIVE ? (
              <div className={styles.headerInfoBlock}>
                <span>{t('license:expire')}</span>
                <div className={styles.expireDate}>
                  <p>--</p>
                </div>
              </div>
            ) : (licenseStatus === LICENSE_STATUS.EXPIRED && type !== PRODUCT_TYPE.URANIUM) ? (
              <div className={styles.headerInfoBlock}>
                <span>{t('license:expire')}</span>
                <div className={styles.expireDate}>
                  <p>
                    {t('license:expired')}
                    {expirationDate && (
                      <>{' - ' + expirationDate}</>
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <>
                {expirationDate && (
                  <div className={styles.headerInfoBlock}>
                    <span>{t('license:expire')}</span>
                    <div className={styles.expireDate}>
                      <p>
                        {(licenseStatus === LICENSE_STATUS.BLOCKED && daysLeft && daysLeft < 0) ? `${t('license:expired')} - ` : ''}
                        {(daysLeft && daysLeft >= 0) ? `${daysLeft} ${t('license:days')} · ` : ''}
                        {' '}
                        {expirationDate}
                      </p>
                      {licenseStatus === LICENSE_STATUS.BLOCKED && (<p>{t('license:blocked')}</p>)}
                      {licenseStatus !== LICENSE_STATUS.BLOCKED && activationRenewStatus !== RENEW_STATUS.NOT_RENEWABLE && (
                        <>
                          {isFetchingActivateAutoRenew ||
                            isFetchingDeactivateAutoRenew ? (
                            <div
                              className={styles.spinnerWrap}
                              style={{ marginTop: 0 }}
                            >
                              <Spinner isGrey isSmall />
                            </div>
                          ) : (
                            <p
                              onClick={() => callAutoRenew()}
                              className={classNames({
                                [styles.renew]:
                                  activationRenewStatus ===
                                  RENEW_STATUS.DISABLED ||
                                  activationRenewStatus ===
                                  RENEW_STATUS.AGREEMENT_ISSUE,
                                [styles.suspend]:
                                  activationRenewStatus ===
                                  RENEW_STATUS.ENABLED,
                              })}
                            >
                              {activationRenewStatus === RENEW_STATUS.ENABLED
                                ? t('license:suspendAutoRenew')
                                : t('license:activateAutoRenew')}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.headerContentRight}>
            {licenseActivationCode && (
              <div
                className={styles.licenseDownload}
                onClick={() => onGetLicensePDF(id)}
              >
                {isLoadingOnGetLicensePDF ? (
                  <div className={styles.spinnerWrap}>
                    <Spinner isGrey isSmall />
                  </div>
                ) : (
                  <>
                    <span className="icon-file-pdf"></span>
                    <p>{t('license:license')}</p>
                  </>
                )}

                {errorOnGetLicensePDF && (
                  <div className={styles.errors}>
                    <p>{errorOnGetLicensePDF.message}</p>
                  </div>
                )}
              </div>
            )}

            {isRenewable && (
              <Button
                onClick={goToUpgradePage}
                size={BUTTON_SIZE.SMALL}
                styleBtn={BUTTON_STYLE.SECONDARY}
              >
                {t('license:headerCtaLabel.renew')}
              </Button>
            )}
            {isUpgradable && (
              <Button
                onClick={goToUpgradePage}
                size={BUTTON_SIZE.SMALL}
                styleBtn={BUTTON_STYLE.SECONDARY}
              >
                {t('license:headerCtaLabel.upgrade')}
              </Button>
            )}

            {!licenseActivationCode && (
              <Button
                onClick={onOpenActivationModalFn}
                size={BUTTON_SIZE.SMALL}
                styleBtn={BUTTON_STYLE.TERTIARY}
              >
                {t('license:headerCtaLabel.subscription')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.bodyContentLeft}>
            <p
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            ></p>
            <div className={styles.description}>
              <span
                style={{
                  opacity:
                    currentDescription && !descriptionIsEditing ? 1 : 0.5,
                }}
                className="icon-pencil-simple"
                onClick={onEditingFn}
              ></span>
              {descriptionIsEditing ? (
                <form onSubmit={onChangeDescriptionSubmitFn}>
                  <input
                    onBlur={onBlur}
                    ref={ref}
                    onChange={onChangeDescriptionFn}
                    value={currentDescription}
                  />
                </form>
              ) : (
                <p
                  style={{
                    opacity:
                      currentDescription && !descriptionIsEditing ? 1 : 0.5,
                  }}
                  className={classNames({
                    [styles.hasDescription]: currentDescription,
                  })}
                >
                  {currentDescription
                    ? currentDescription
                    : t('license:addNote')}
                </p>
              )}
            </div>
          </div>
          <div
            className={`${styles.bodyContentRight} ${showConfirmActivation ? styles.flexItem : ''
              }`}
          >
            <div
              className={`${styles.activationWrap} ${showConfirmActivation ? styles.confirmBoxVisible : ''
                } ${isLoadingActivationCode ? styles.fullWidth : ''}`}
            >
              <>
                {(type === PRODUCT_TYPE.SUPREMO || type === PRODUCT_TYPE.CONSOLE) && (
                  <>
                    <div className={styles.title}>
                      <p>{t('license:activationCode')}</p>
                      <Tooltip
                        placement="top"
                        // TOOLTIP HAS TO BE SPECIFIC
                        overlay={
                          type === PRODUCT_TYPE.SUPREMO
                            ? t(isProfessional ? 'license:supremoProfessionalTooltip' : 'license:supremoTooltip')
                            : type === PRODUCT_TYPE.CONSOLE
                              ? t('license:consoleTooltip')
                              : ''
                        }
                        arrowContent={
                          <div className="rc-tooltip-arrow-inner"></div>
                        }
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          className="icon-question"
                        ></span>
                      </Tooltip>
                    </div>
                    {!isLoadingActivationCode ? (
                      <>
                        {!showConfirmActivation ? (
                          <>
                            {Boolean(licenseActivationCode) ? (
                              <div className={styles.input}>
                                <input value={licenseActivationCode} readOnly />
                                <div
                                  className={styles.copy}
                                  onClick={() => copy(licenseActivationCode)}
                                >
                                  <span className="icon-copy"></span>{' '}
                                </div>
                              </div>
                            ) : (
                              <>
                                <Button
                                  isLoading={isLoadingCalculateExpireDate}
                                  size={BUTTON_SIZE.SMALL}
                                  styleBtn={BUTTON_STYLE.SECONDARY}
                                  onClick={() =>
                                    onGetActivationDate({
                                      id,
                                      isFromModal: false,
                                    })
                                  }
                                >
                                  {t('license:generateActivationCode')}
                                </Button>
                                {errorCalculateExpireDate && (
                                  <div className={styles.errors}>
                                    <p>{errorCalculateExpireDate.message}</p>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <p>
                            {t('license:activateNowExpirationWillBe', {
                              date: calculateExpireDate?.expirationDate,
                            })}
                          </p>
                        )}
                      </>
                    ) : (
                      <div className={styles.spinnerWrap}>
                        <Spinner isGrey isSmall />
                      </div>
                    )}
                  </>
                )}
                {type === PRODUCT_TYPE.URANIUM && (
                  <>
                    <div className={styles.title}>
                      <p>{t('license:machineCodeShort')}</p>
                    </div>
                    <div className={styles.input} style={{marginBottom: '8px'}}>
                      <input
                        value={currentMachineCode}
                        onChange={onChangeCurrentMachineCodeFn}
                        className={
                          errorGetActivationCode !== null &&
                            (errorGetActivationCode as any).type ===
                            ERRORS_TYPE.INVALID_CODE
                            ? styles.error
                            : ''
                        }
                      />
                      {machineCode && (
                        <div
                          className={styles.copy}
                          onClick={() => copy(machineCode)}
                        >
                          <span className="icon-copy"></span>{' '}
                        </div>
                      )}
                    </div>
                    <>
                      <div className={styles.title}>
                        <p>{t('license:activationCode')}</p>
                        <Tooltip
                          placement="top"
                          overlay={t('license:uraniumTooltip')}
                          arrowContent={
                            <div className="rc-tooltip-arrow-inner"></div>
                          }
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            className="icon-question"
                          ></span>
                        </Tooltip>
                      </div>
                      {licenseActivationCode ? (
                        <div className={styles.input}>
                          <input
                            style={{ paddingRight: '40px' }}
                            value={licenseActivationCode}
                            readOnly
                          />
                          {licenseActivationCode && (
                            <div
                              className={styles.copy}
                              onClick={() => copy(licenseActivationCode)}
                            >
                              <span className="icon-copy"></span>{' '}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <Button
                            size={BUTTON_SIZE.SMALL}
                            disabled={!currentMachineCode}
                            styleBtn={BUTTON_STYLE.SECONDARY}
                            isLoading={isLoadingActivationCode}
                            onClick={() =>
                              onGetActivationCode({
                                id,
                                machineCode: currentMachineCode,
                              })
                            }
                          >
                            {t('license:generateActivationCode')}
                          </Button>
                          {errorGetActivationCode && (
                            <div className={styles.errors}>
                              <p>{errorGetActivationCode.message}</p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </>
                )}
              </>
            </div>

            {showConfirmActivation && !isLoadingActivationCode && (
              <div className={styles.rightSide}>
                <Badge onClick={onCancelActivation} type={BADGE_TYPE.SECONDARY}>
                  {t('license:cancel')}
                </Badge>
                <Badge
                  onClick={() =>
                    onGetActivationCode({
                      id,
                      machineCode,
                    })
                  }
                  type={BADGE_TYPE.PRIMARY}
                >
                  {t('license:confirm')}
                </Badge>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { onSignOut } from '@services/api';
import { noop } from 'lodash';
import { UserContext } from '@contexts/UserContext';

export const KEY = 'logout';

export const useLogout = (onSuccess = noop, onError = noop) => {
  const { setIsLoggedIn } = React.useContext(UserContext);
  return {
    ...useMutation<void, Error>(
      [KEY],
      async (): Promise<void> => {
        await onSignOut();
      },
      {
        onError,
        onSuccess: () => {
          setIsLoggedIn(false);
          onSuccess();
        },
        retryDelay: 1000,
      }
    ),
  };
};

import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import ProductBoxUranium from '@components/ProductBoxUranium';
import ProductBoxSupremo from '@components/ProductBoxSupremo';

const Shop = () => {
  const { t } = useTranslation(['shop', 'routes', 'common']);
  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection isCentered>
          <h2 className={styles.title}>{t('shop:title')}</h2>
          <div className={styles.wrapper}>
            <ProductBoxSupremo />
            <ProductBoxUranium />
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
};

export default Shop;

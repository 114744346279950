import { useMutation } from '@tanstack/react-query';
import { getDiscountCheck } from '@services/api';
import { noop } from 'lodash';

import useNormalizeCartProductsForCheckout from './useNormalizeCartProductsForCheckout';
import { useTranslation } from 'react-i18next';

export const KEY = 'discount-check';

export const useDiscountCheck = (onSuccess = noop, onError = noop) => {
  const { data: normalizedCartItemsForCheckout } =
    useNormalizeCartProductsForCheckout();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<boolean, Error, string>(
      [KEY],
      async (code: string): Promise<boolean> => {
        return await getDiscountCheck(
          currentLanguage,
          normalizedCartItemsForCheckout,
          code
        );
      },
      {
        onError,
        onSuccess,
      }
    ),
  };
};

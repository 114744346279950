import { logUser } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { Token } from '@utils/types/user';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import useGetUserInfo from './useUserInfo';
const KEY = 'user-login';

export const useLogInUser = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const data = useMutation<
    Token,
    Error,
    { email: string; password: string; recaptcha: string }
  >(
    [KEY],
    async ({
      email,
      password,
      recaptcha,
    }: {
      email: string;
      password: string;
      recaptcha: string;
    }): Promise<Token> => {
      const token = await logUser(currentLanguage, email, password, recaptcha);
      return token;
    },
    { onError }
  );

  const query = useGetUserInfo(!!data?.data, onSuccess, onError);

  return {
    isLoading: data?.isLoading || query?.isLoading,
    data: query.data,
    mutate: data?.mutate,
    isError: data?.isError || query?.isError,
    error: data?.error || query.error,
  };
};

import { useMutation } from '@tanstack/react-query';
import { confirmPayWithPaypal } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AddPurchaseResponse } from '@utils/types/store';

export const KEY = 'confirm-paypal-payment';

export const usePayWithPaypal = (paymentId: any, onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useMutation<AddPurchaseResponse, Error>(
      [KEY],
      async (): Promise<AddPurchaseResponse> => {
        return await confirmPayWithPaypal(currentLanguage, paymentId);
      },
      {
        onError,
        onSuccess,
      }
    ),
  };
};

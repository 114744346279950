import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import { useNavigate } from 'react-router-dom';
import { useLogInUser } from '@hooks/useLogInUser';
import SignInForm from '@components/SignInForm';
import { UserContext } from '@contexts/UserContext';
import useCaptchaRequired from '@hooks/useCaptchaNeeded';
import BlockFormWrapper from '@components/BlockFormWrapper';
import useGetLicenseToRenew from '@hooks/useGetLicenseToRenew';
import { UserInfo } from '@utils/types/user';

const SignIn = () => {
  const { t } = useTranslation(['shop', 'routes', 'common', 'forms']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const goToUrl = searchParams.get('goToUrl') ?? '';

  const location = useLocation();

  const [isCaptchaRequired, setIsCaptchaRequired] =
    React.useState<boolean>(false);

  const hasCart = location?.state?.hasCart;
  // const redirectRoute = location?.state?.redirectRoute;

  const { setIsLoggedIn, isLoggedIn } = React.useContext(UserContext);

  const onSuccessGetLicenseRenew = (data: any) => {
    if (isLoggedIn && data !== 0) {
      navigate(t('routes:licenseToRenew'));
    }
    if (isLoggedIn && data === 0) {
      navigate(t('routes:dashboard'));
    }
  };

  const { refetch: checkForLicenseToRenew } = useGetLicenseToRenew(
    false,
    onSuccessGetLicenseRenew
  );

  const onSuccess = React.useCallback(() => {
    setIsLoggedIn(true);
    if (goToUrl.length > 0) {
      navigate(goToUrl);
    } else {
      checkForLicenseToRenew();
    }
  }, [setIsLoggedIn, goToUrl, navigate, checkForLicenseToRenew]);

  const onError = () => {
    console.log('error');
  };

  const onSuccessCaptcha = (captchaInfo: UserInfo) => {
    if (captchaInfo && captchaInfo?.recaptcha) {
      setIsCaptchaRequired(captchaInfo?.recaptcha);
    }
  };

  const {
    mutate: signIn,
    isLoading,
    isError,
    error
  } = useLogInUser(onSuccess, onError);

  const {isLoading: isLoadingRecaptcha} = useCaptchaRequired(true, (data) => onSuccessCaptcha(data));

  const onSubmitForm = React.useCallback(
    ({
      email,
      password,
      recaptcha,
    }: {
      email: string;
      password: string;
      recaptcha: string;
    }) => {
      signIn({ email, password, recaptcha });
    },
    [signIn]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection
          noMarginBottom={true}
          hasSide={Boolean(hasCart)}
          isCentered
        >
          <div className={hasCart ? styles.formWrapper : ''}>
            <div className={styles.container}>
              <h1 style={{ marginBottom: '44px', fontSize: '2.4rem' }}>
                {t('forms:titlePage.signIn')}
              </h1>

              <BlockFormWrapper>
                <SignInForm
                  isLoadingRecaptcha={isLoadingRecaptcha}
                  isCaptachRequired={isCaptchaRequired}
                  onSubmit={onSubmitForm}
                  isLoading={isLoading}
                  isError={isError}
                  error={error}
                />
              </BlockFormWrapper>
            </div>
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
};

export default SignIn;

import { useQuery } from '@tanstack/react-query';
import { getSupremoProduct } from '@services/api';
import { ProductSupremo, PRODUCT_TYPE } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';

export const KEY = 'store-products';

export default function useSupremoProduct(
  onError?: typeof noop,
  onSuccess?: typeof noop
) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<ProductSupremo, Error>(
      [KEY, PRODUCT_TYPE.SUPREMO],
      () => getSupremoProduct(currentLanguage),
      {
        onError,
        onSuccess,
      }
    ),
  };
}

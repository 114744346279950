import Button, { BUTTON_STYLE } from '@components/Button';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import { useState } from 'react';

type IProps = {
  setForm: (v: string) => void;
  form: string;
};
export default function VerticalTab({ form, setForm }: IProps) {
  const { t } = useTranslation(['common', 'forms']);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const showSelect = windowWidth < 770;
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const selectFormStyle = {
    container: (base: any) => ({
      ...base,
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '0',
      borderRadius: '15px',
      borderColor: 'rgba(11, 40, 78, 0.25)',
      color: '#0b284e',
      boxShadow: state.isFocused
        ? 'rgba(11, 40, 78, 0.5) !important'
        : '0 0 0 40px #fff inset',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '15px',
      },
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '45px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '21px',
    }),

    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      backgroundColor: '#f4f8f8',
      fontSize: '16px',
      zIndex: '9999 !important',
      position: 'relative',
    }),
    listBox: (base: any, state: any) => ({
      ...base,
      minHeight: '300px',
    }),

    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? '#2d9cdb' : '#f4f8f8',
      color: state.isSelected ? '#fff' : 'rgba(11, 40, 78)',
      '&:hover': {
        backgroundColor: '#2d9cdb',
        cursor: 'pointer',
        color: '#fff',
      },
    }),
  };

  const options = [
    {
      value: 'account',
      label: t('forms:formHeader.title.account'),
    },
    {
      value: 'data',
      label: t('forms:formHeader.title.personalData'),
    },
    {
      value: 'contact',
      label: t('forms:formHeader.title.contacts'),
    },
  ];

  return (
    <>
      {!showSelect && (
        <div className={styles.container}>
          <Button
            styleBtn={
              form !== 'account' ? BUTTON_STYLE.WHITE : BUTTON_STYLE.LIGHT_BG
            }
            onClick={() => setForm('account')}
            fullWidth
          >
            {t('forms:formHeader.title.account')}
          </Button>
          <Button
            styleBtn={
              form !== 'data' ? BUTTON_STYLE.WHITE : BUTTON_STYLE.LIGHT_BG
            }
            onClick={() => setForm('data')}
            fullWidth
          >
            {t('forms:formHeader.title.personalData')}
          </Button>
          <Button
            styleBtn={
              form !== 'contact' ? BUTTON_STYLE.WHITE : BUTTON_STYLE.LIGHT_BG
            }
            onClick={() => setForm('contact')}
            fullWidth
          >
            {t('forms:formHeader.title.contacts')}
          </Button>
        </div>
      )}
      {showSelect && (
        <Select
          options={options}
          styles={selectFormStyle}
          isClearable={false}
          defaultValue={options[0]}
          isSearchable={false}
          value={options.find((c: any) => c.value === form)}
          placeholder=""
          onChange={(val: any) => {
            setForm(val?.value);
          }}
        />
      )}
    </>
  );
}

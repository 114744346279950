import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Badge from '@components/Badge';
import {
  ILicenseThumbnail,
  IPurchase,
  IPurchaseDocument,
  PURCHASE_STATUS,
} from '@utils/types/purchase';
import { useTranslation } from 'react-i18next';
import SkeletonBasic from '@components/SkeletonBasic';
import styles from './styles.module.scss';
import { formatPurchasesStatus } from '@utils/purchasesHelperFunctions';
import Tooltip from 'rc-tooltip';
import PurchaseStyleTable from '@components/PurchaseStyleTable';
import { useDownloadPurchaseDocument } from '@hooks/useDownloadPurchaseDocument';
import { Link } from 'react-router-dom';
import { SORT_ORDER_LICENSE } from '@utils/types/licenses';
import Spinner from '@components/Spinner';

type Props = {
  data: IPurchase[];
  isLoading: boolean;
  onChangeSorting: (orderType: string, orderTime: SORT_ORDER_LICENSE) => void;
  currentSortDir: any;
  currentSortField: any;
};
function PurchasesTable({
  data,
  isLoading,
  onChangeSorting,
  currentSortDir,
  currentSortField,
}: Props) {
  const { t } = useTranslation(['purchases', 'routes']);

  const renderPurchaseLicensesInTooltip = (
    purchaseLicense: ILicenseThumbnail,
    index: number
  ) => {
    return (
      <p key={index} className={styles.tooltipLicense}>
        <img
          width={14}
          height={14}
          src={purchaseLicense?.iconUrl}
          alt={purchaseLicense?.description}
        />
        {purchaseLicense?.description}
      </p>
    );
  };

  const { mutate: getPurchaseDocument } = useDownloadPurchaseDocument((data) => onSuccessPurchaseDocument(data));

  const onSuccessPurchaseDocument = React.useCallback((docUrl: string) => {
    docUrl && window.open(docUrl, '_blank');
  }, []);

  const renderDocuments = React.useCallback(
    (documents: IPurchaseDocument[], paymentId: string) => {
      return (
        <>
          {documents.map((doc, index: number) => (
            <>
              {(index > 0 && <br />)}
              <span
                style={{ cursor: 'pointer' }}
                key={index}
                onClick={() => getPurchaseDocument({ paymentId, type: doc?.type })}
              >
                {t(`purchases:documents.types.${doc.type.toLowerCase()}`)}
              </span>
            </>
          ))}
        </>
      );
    },
    [getPurchaseDocument, t]
  );

  const columns: any = React.useMemo<ColumnDef<IPurchase>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: () => <span>{t('purchases:purchasesTable.code')}</span>,
        cell: ({ getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span className={styles.cellWrapper}>
              {getValue<string>() ?? '-'}
            </span>
          );
        },
        size: 140,
        minSize: 130,
      },
      {
        id: 'activationDate',
        accessorKey: 'activationDate',
        header: () => <span>{t('purchases:purchasesTable.date')}</span>,
        cell: ({ getValue }) => {
          const value = getValue<string>();
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span className={styles.cellWrapper}>
              <span className={styles.date}>
                {value}
              </span>
              &nbsp;
            </span>
          );
        },
        size: 140,
        minSize: 130,
      },
      {
        id: 'price',
        accessorKey: 'price',
        header: () => <span>{t('purchases:purchasesTable.amount')}</span>,
        cell: ({ row, getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span className={styles.cellWrapper}>{getValue<number>()}</span>
          );
        },
        size: 140,
        minSize: 130,
      },
      {
        id: 'priceWithVAT',
        accessorKey: 'priceWithVAT',
        header: () => <span>{t('purchases:purchasesTable.totalAmount')}</span>,
        cell: ({ row, getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span className={styles.cellWrapper}>{getValue<number>()}</span>
          );
        },
        size: 140,
        minSize: 130,
      },
      {
        id: 'paymentType',
        accessorKey: 'paymentType',
        header: () => <span>{t('purchases:purchasesTable.payment')}</span>,
        cell: ({ getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span className={styles.cellWrapper}>
              {getValue<string>() ?? '-'}
            </span>
          );
        },
        size: 140,
        minSize: 130,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: () => <span>{t('purchases:purchasesTable.status')}</span>,
        cell: ({ getValue }) => {
          const purchaseStatus = formatPurchasesStatus(
            getValue<PURCHASE_STATUS>()
          );
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <Badge type={purchaseStatus?.color}>{t(purchaseStatus?.labelKey)}</Badge>
          );
        },
        size: 140,
        minSize: 130,
        enableSorting: false,
      },
      {
        id: 'licenses',
        accessorKey: 'licenses',
        header: () => <span>{t('purchases:purchasesTable.licenses')}</span>,
        cell: ({ row, getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span
              className={`${styles.cellWrapper} ${styles.licenses}`}
              style={{
                textDecoration:
                  row.original.licenses.length > 0
                    ? 'underline'
                    : 'none !important',
                cursor:
                  row.original.licenses.length > 0 ? 'pointer' : 'default',
              }}
            >
              {row.original.licenses.length > 0 ? (
                <Tooltip
                  placement="top"
                  overlay={row.original.licenses.map(
                    renderPurchaseLicensesInTooltip
                  )}
                  arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                  <span>
                    <Link
                      to={`${t('routes:license')}?paymentId=${row.original.id}`}
                    >
                      {t('purchases:purchasesTable.licenses')}{' '}
                      {row.original.licenses.length > 1
                        ? `(${row.original.licenses.length})`
                        : ''}
                    </Link>
                  </span>
                </Tooltip>
              ) : (
                t('purchases:purchasesTable.licenses')
              )}
            </span>
          );
        },
        size: 140,
        minSize: 130,
        enableSorting: false,
      },
      {
        id: 'documents',
        accessorKey: 'documents',
        header: () => <span>{t('purchases:purchasesTable.documents')}</span>,
        cell: ({ row, getValue }) => {
          return isLoading ? (
            <SkeletonBasic width={80} height={12} />
          ) : (
            <span
              className={`${styles.cellWrapper} ${styles.documents}`}
              style={{
                textDecoration:
                  row.original.documents.length > 0
                    ? 'underline'
                    : 'none !important',
                cursor:
                  row.original.documents.length > 0 ? 'pointer' : 'default',
              }}
            >
              {row.original.documents.length > 0 ? (
                renderDocuments(row.original.documents, row?.original.id)
              ) : (
                <span></span>
              )}
            </span>
          );
        },
        size: 140,
        minSize: 130,
        enableSorting: false,
      },
    ],
    [isLoading, renderDocuments, t]
  );

  return (
    <>
      {data && !isLoading ? (
        <PurchaseStyleTable
          onChangeSorting={onChangeSorting}
          data={data}
          columns={columns}
          currentSortDir={currentSortDir}
          currentSortField={currentSortField}
        />
      ) : (
        <div className={styles.spinnerContainer}>
          <Spinner isGrey isMid />
        </div>
      )}
    </>
  );
}

export default PurchasesTable;

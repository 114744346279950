import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import BlockFormWrapper from '@components/BlockFormWrapper';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import ShortSignUpForm from '@components/ShortSignUpForm';
import SyncLoader from '@components/SyncLoader';

import { UserContext } from '@contexts/UserContext';

import { useLoadInvite, useAcceptInvite } from '@hooks/useInvite';
import { useRegisterQuick } from '@hooks/useRegisterQuick';

import styles from './styles.module.scss';

const TrialLicenseInvite = () => {
  const { t } = useTranslation(['routes', 'common', 'forms']);
  const navigate = useNavigate();

  const location = useLocation();
  const { query } = queryString?.parseUrl(location.search);
  const token = typeof query.token === 'string' ? query.token : '';

  const [inviteEmail, setInviteEmail] = React.useState<string>('');

  const { setIsLoggedIn, isLoggedIn } = React.useContext(UserContext);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate(t('routes:dashboard'));
    }
  }, []);

  // Invite
  const {
    mutate: loadInvite,
    isLoading: loadInviteIsLoading,
    isError: loadInviteIsError,
  } = useLoadInvite((data) => setInviteEmail(data.Email));

  React.useEffect(() => {
    loadInvite(token);
  }, [loadInvite, token]);

  const onAcceptInviteSuccess = React.useCallback(() => {
    navigate(t('routes:license'));
  }, [navigate, t]);

  const onAcceptInviteError = () => {
    console.log('error');
  };

  const {
    mutate: acceptInvite,
    isLoading: acceptInviteIsLoading,
    isError: acceptInviteIsError,
  } = useAcceptInvite(onAcceptInviteSuccess, onAcceptInviteError);

  // SignUp
  const onSignUpSuccess = React.useCallback(() => {
    setIsLoggedIn(true);
    acceptInvite(token);
  }, [setIsLoggedIn, acceptInvite, token]);

  const onSignUpError = () => {
    console.log('error');
  };

  const {
    mutate: signUp,
    isLoading: signUpIsLoading,
    isError: signUpIsError,
    error: signUpError
  } = useRegisterQuick(onSignUpSuccess, onSignUpError);

  const onSubmitSignUpForm = React.useCallback(
    ({
      username,
      email,
      password,
      recaptcha,
    }: {
      username: string;
      email: string;
      password: string;
      recaptcha: string;
    }) => {
      signUp({ username, email, password, recaptcha });
    },
    [signUp]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection
          noMarginBottom={true}
          isCentered
        >
          <div className={styles.container}>
            <h1 style={{ marginBottom: '44px', fontSize: '2.4rem' }}>
              {t('forms:titlePage.trialLicenseInvite')}
            </h1>
            <BlockFormWrapper hasMaxWidth>
              {(loadInviteIsLoading || acceptInviteIsLoading) && (
                <div
                  style={{
                    marginTop: -24,
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SyncLoader size={10} color={'rgba(0,0,0,0.2)'} />
                </div>
              )}
              {!(loadInviteIsLoading || acceptInviteIsLoading) && !loadInviteIsError && (<ShortSignUpForm
                onSubmit={onSubmitSignUpForm}
                isLoading={signUpIsLoading}
                isError={signUpIsError}
                errorsFromApi={signUpError}
                forcedEmail={inviteEmail}
              />)}
              {(loadInviteIsError || acceptInviteIsError) && (
                <p className={styles.error}>
                  {t('forms:errorsState.general.submit')}
                </p>
              )}
            </BlockFormWrapper>
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
};

export default TrialLicenseInvite;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import { getPurchases } from '@services/api';
import { IPurchaseRequest, IPurchaseResponse } from '@utils/types/purchase';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-purchases';

export const usePurchases = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<IPurchaseResponse, Error, IPurchaseRequest>(
      [KEY],
      async ({
        query,
        currentPage,
        itemsPerPage,
        sortDir,
        sortField,
      }): Promise<IPurchaseResponse> => {
        return await getPurchases(
          currentLanguage,
          query,
          currentPage,
          itemsPerPage,
          sortDir,
          sortField
        );
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import ICON_PAYPAL from '@assets/images/paypal.svg';
import Button from '@components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import SkeletonBasic from '@components/SkeletonBasic';
import { usePayWithPaypal } from '@hooks/usePayWithPaypal';
import { CartContext } from '@contexts/CartContext';
import { AddPurchaseResponse } from '@utils/types/store';
import useCheckPayWithPaypal from '@hooks/useCheckPayWithPaypal';
import { enablePayPalAgreement } from '@services/api';

export default function ConfirmPaypalPayment() {
  const { t } = useTranslation(['shop', 'routes', 'common', 'checkout']);

  const navigate = useNavigate();
  const location = useLocation();
  const { query } = queryString?.parseUrl(location.search);
  const paymentId: any = query?.paymentId ?? null;
  const action: string = String(query?.action) ?? '';
  const token: string = String(query?.token) ?? '';

  const [isEnabled, setIsEnabled] = React.useState(false);
  const [agreementError, setAgreementError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await enablePayPalAgreement(action, token);
        setIsEnabled(true);
      } catch (error: any) {
        setIsEnabled(false);
        setAgreementError(error);
      }
    };
    fetchData();
  }, []);

  const { onEmptyCart } = React.useContext(CartContext);

  const {
    data,
    isLoading: isLoadingCheckPayWithPaypal,
    error: errorPaymentCheck,
  } = useCheckPayWithPaypal(paymentId, isEnabled);

  React.useEffect(() => {
    if (!Boolean(paymentId)) {
      navigate(t('routes:home'));
    }
  }, [paymentId, navigate, t]);

  const onSuccessFn = React.useCallback(
    (res: AddPurchaseResponse) => {
      onEmptyCart();
      res?.redirectUrl && window.open(res?.redirectUrl, '_self');
    },
    [onEmptyCart]
  );

  const {
    mutate: onConfirm,
    isLoading: isLoadingPayWithPaypal,
    error: erroPayWithPaypal,
  } = usePayWithPaypal(paymentId, onSuccessFn);

  return (
    <>
      <Header type={HEADER_TYPE.CHECKOUT} />
      <LayoutPage>
        <LayoutSection noMarginBottom={true} isCentered>
          <div className={styles.container}>
            <h1>{t('checkout:confirmPayments.title')}</h1>

            <div className={styles.block}>
              <img src={ICON_PAYPAL} alt="paypal-logo" />
              <p>{t('checkout:confirmPayments.disclaimer')}</p>
              {isEnabled && (
                <div className={styles.totalWrapper}>
                  <strong>{t('common:shops.total')}</strong>
                  {isLoadingCheckPayWithPaypal ? (
                    <strong className={styles.total}>
                      <SkeletonBasic width={120} height={19} />
                    </strong>
                  ) : (
                    <strong className={styles.total}>{data?.amount}&euro;</strong>
                  )}
                </div>
              )}
              { isEnabled && (errorPaymentCheck === null || errorPaymentCheck?.message.length === 0) &&
                <Button isLoading={isLoadingPayWithPaypal} onClick={onConfirm} >
                  {t('checkout:confirmPayments.labelCta')}
                </Button>
              }
              <div className={styles.error}>
                <p>{agreementError?.message || errorPaymentCheck?.message || erroPayWithPaypal?.message}</p>
              </div>
            </div>
          </div>
        </LayoutSection>
      </LayoutPage>
    </>
  );
}

import { useMutation } from '@tanstack/react-query';
import { addPurchase } from '@services/api';
import { noop } from 'lodash';

import useNormalizeCartProductsForCheckout from './useNormalizeCartProductsForCheckout';
import { AddPurchaseResponse, PAYMENT_METHODS } from '@utils/types/store';
import { useTranslation } from 'react-i18next';

export const KEY = 'add-purchase';

type Props = {
  autoRenew: boolean;
  paymentMethod: PAYMENT_METHODS;
  code?: string;
  b2bPec?: string;
};
export const useAddPurchase = (onSuccess = noop, onError = noop) => {
  const { data: normalizedCartItemsForCheckout } =
    useNormalizeCartProductsForCheckout();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<AddPurchaseResponse, Error, Props>(
      [KEY],
      async ({
        autoRenew,
        paymentMethod,
        code,
        b2bPec,
      }: Props): Promise<AddPurchaseResponse> => {
        return await addPurchase(
          currentLanguage,
          autoRenew,
          normalizedCartItemsForCheckout,
          paymentMethod,
          code,
          b2bPec
        );
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input, { INPUT_SIZE } from '@components/Input';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import Button from '@components/Button';
import { isEmailValid } from '@services/validations';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '@components/Spinner';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

type Props = {
  isLoading: boolean;
  isError?: boolean;
  error?: Error | null;
  isLoadingRecaptcha: boolean;
  isCaptachRequired: boolean;
  isInSigninAndPay?: boolean;

  onSubmit: ({
    email,
    password,
    recaptcha = '',
  }: {
    email: string;
    password: string;
    recaptcha: string;
  }) => void;
};

export default function SignInForm({
  isLoadingRecaptcha,
  isCaptachRequired,
  isLoading,
  onSubmit,
  isError,
  error,
  isInSigninAndPay,
}: Props) {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [usernameOrEmailValue, setUsernameOrEmailValue] = React.useState('');
  const [captchaToken, setCaptchaToken] = React.useState<string>('');
  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
    password: boolean;
  }>({
    email: false,
    password: false,
  });

  const { t } = useTranslation(['forms', 'routes']);
  const mailOrUser = watch('email', '');

  function handleOnChange(value: any) {
    setCaptchaToken(value);
  }
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const isCompactCaptcha = windowWidth < 370;

  React.useEffect(() => {
    setUsernameOrEmailValue(mailOrUser);
  }, [watch, mailOrUser]);

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('forms:errorsState.required.emailUsername'),
    minLength: {
      value: 3,
      message: usernameOrEmailValue.includes('@')
        ? t('forms:errorsState.invalid.email')
        : t('forms:errorsState.invalid.emailMinLength'),
    },
    maxLength: {
      value: !usernameOrEmailValue.includes('@') ? 50 : 320,
      message: t('forms:errorsState.invalid.email'),
    },
    validate: {
      value: (value) => {
        if (usernameOrEmailValue.includes('@')) {
          return isEmailValid(value) || t('forms:errorsState.invalid.email');
        }
      },
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('forms:errorsState.required.password'),

    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.password'),
    },
  });

  function handleOnSubmit({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsDirty({ email: email.length > 0, password: password.length > 0 });

    if (captchaToken) {
      onSubmit({ email, password, ...{ recaptcha: captchaToken } });
    } else {
      onSubmit({ email, password, recaptcha: '' });
    }
  }

  React.useEffect(() => {
    // Reset recaptha on error
    if (isError) {
      recaptchaRef.current?.reset();
    }
  }, [isError]);

  return (
    <>
      <div className={`${styles.container} container`}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <Input
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            label={t('forms:label.emailOrUsername')}
            hasValue={isDirty?.email ?? false}
            type="text"
            placeholder={t('forms:placeholder.emailOrUsername')}
            inputRef={emailRef}
            {...emailInputProps}
            error={errors?.email?.message as any}
          />

          <Input
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.password ?? false}
            type="password"
            label={t('forms:label.password')}
            placeholder={t('forms:placeholder.password')}
            inputRef={passwordRef}
            {...passwordInputProps}
            error={errors?.password?.message as any}
          />
          {isCaptachRequired ? (
            <>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                onChange={handleOnChange}
                className={styles.recaptcha}
                size={isCompactCaptcha ? 'compact' : 'normal'}
              />

              <Button
                style={{ marginTop: '10px' }}
                fullWidth
                disabled={!captchaToken}
              >
                {isLoadingRecaptcha ? (
                  <div className={styles.spinnerContainer}>
                    <Spinner isWhite isSmall />
                  </div>
                ) : (
                  t('forms:signIn')
                )}
              </Button>
            </>
          ) : (
            <button style={{ marginTop: '10px' }} className="cta full">
              {t('forms:signIn')}
            </button>
          )}

          <Link
            to={t('routes:passwordRecovery')}
            className={`${styles.forgetPass} linkBold linkBlue`}
          >
            {t('forms:forgotPassword')}
          </Link>
          <br />

          {!isInSigninAndPay && (
            <Link
              to={t('routes:signup')}
              className={`${styles.forgetPass} linkBold linkBlue`}
            >
              {t('forms:dontHaveAccount')}
            </Link>
          )}

          {isError && (
            <p className={styles.error}>
              {error && error.message ? error.message : t('forms:errorsState.general.submit')}
            </p>
          )}
        </form>
      </div>
    </>
  );
}

import * as React from 'react';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection, { SECTION_BACKGROUND } from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import styles from './styles.module.scss';
import TwoBlockLayout from '@components/TwoBlockLayout';
import SupremoControlLogo from '@assets/nanosystems-base/images/logo-supremo-control.svg';
import ImageRightColumn from '@assets/nanosystems-base/images/hero-supremo-en.webp';
import FaqListAccordion from '@components/FaqListAccordion';
import SimpleBanner from '@components/SimpleBanner';
import useUraniumProduct from '@hooks/useUraniumProduct';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import {
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION_ID,
} from '@utils/types/store';

import Collapsible from 'react-collapsible';
import UraniumBasicFeaturesTable from '@components/UraniumBasicFeaturesTable';
import UraniumAdvancedFeaturesTable from '@components/UraniumAdvancedFeaturesTable';
import UraniumTableHeader from '@components/UraniumTableHeader';
import UraniumBaseProductCard from '@components/UraniumBaseProductCard';
import UraniumProProductCard from '@components/UraniumProProductCard';
import UraniumProVirtualProductCard from '@components/UraniumProVirtualProductCard';
import UraniumGoldProductCard from '@components/UraniumGoldProductCard';
import SupremoConsoleProductCard from '@components/SupremoConsoleProductCard';
import { CartContext } from '@contexts/CartContext';
import { SelectProductButton } from '@components/SelectProductButton';
import ModalDiscount from '@components/ModalDiscount';

const UraniumBackup = () => {
  const { t } = useTranslation(['shop', 'common', 'routes', 'uraniumBackup']);
  const [, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const { setCartItems } = React.useContext(CartContext);
  const [showPromoDesc, setShowPromoDesc] = React.useState(false);
  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const onOpenModalFn = React.useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const onCloseModalFn = React.useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const { data, isSuccess } = useUraniumProduct();

  const checkForPromo = React.useCallback(() => {
    if (data) {
      const onlyClassicUranium = data.products.filter(
        (el) =>
          el.id !== PRODUCT_VERSION_ID.AUSILIO &&
          el.id !== PRODUCT_VERSION_ID.GOLD
      );
      setShowPromoDesc(onlyClassicUranium.some((el) => el?.promoDesc));
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      checkForPromo();
    }
  }, [data, checkForPromo]);

  const renderProductItem = React.useCallback(
    (product: ProductVersion, key: number) => {
      if (product?.id === PRODUCT_VERSION_ID.BASE) {
        return (
          <UraniumBaseProductCard
            handleAddCart={setCartItems}
            key={key}
            productDetails={product}
            showPromoDesc={showPromoDesc}
          />
        );
      }
      if (product?.id === PRODUCT_VERSION_ID.PRO_TAPE) {
        const onlyProVersion =
          data &&
          isSuccess &&
          data.products.filter(
            (el: any) =>
              el.id === PRODUCT_VERSION_ID.PRO_TAPE ||
              el.id === PRODUCT_VERSION_ID.PRO_DB ||
              el.id === PRODUCT_VERSION_ID.PRO_SHADOW
          );

        return (
          <UraniumProProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            onlyProVersionProducts={onlyProVersion}
            renewOrUpgrade={'shop'}
            showPromoDesc={showPromoDesc}
          />
        );
      }
      if (product?.id === PRODUCT_VERSION_ID.PRO_VIRTUAL) {
        return (
          <UraniumProVirtualProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            showPromoDesc={showPromoDesc}
          />
        );
      }
    },
    [setCartItems, data, isSuccess, showPromoDesc]
  );

  return (
    <>
      <ModalDiscount isOpen={modalIsOpen} onClose={onCloseModalFn} />
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection isCentered isFullwidthWrap>
          <SelectProductButton isSupremoPage={false} />
          <div className={styles.header}>
            <h1>{t('uraniumBackup:title')}</h1>
          </div>
          <div className={styles.alternatives}>
            {isSuccess &&
              data?.products
                .filter((prod: any) => prod.id === PRODUCT_VERSION_ID.GOLD)
                .map((el: ProductVersion, key: number) => {
                  return (
                    <UraniumGoldProductCard
                      windowWidth={1291}
                      productDetails={el}
                      handleAddCart={setCartItems}
                      key={key}
                    />
                  );
                })}
          </div>
          <div className={styles.cardWrap}>
            {data?.products.map(renderProductItem)}
          </div>
          <SimpleBanner
            hasBorder
            text={t('uraniumBackup:quantityDiscountBannerOnClick')}
            onClick={onOpenModalFn}
          />
        </LayoutSection>
        <LayoutSection isCentered isFullwidthWrap>
          <div className={`${styles.header} ${styles.ausilio}`}>
            <h3>{t('uraniumBackup:nanosystemsConsole.title')}</h3>
            <p>{t('uraniumBackup:purchaseYearly')}</p>
          </div>

          <div className={`${styles.alternatives} ${styles.ausilio}`}>
            {isSuccess &&
              data?.products
                .filter((prod: any) => prod.id === PRODUCT_VERSION_ID.AUSILIO)
                .map((el: ProductVersion, key) => {
                  return (
                    <SupremoConsoleProductCard
                      windowWidth={1291}
                      handleAddCart={setCartItems}
                      key={key}
                      productDetails={el}
                    />
                  );
                })}
          </div>
        </LayoutSection>
        <LayoutSection isCentered isFullwidthWrap>
          <div className={styles.header}>
            <h3 className={styles.titleSection}>
              {t('uraniumBackup:compareLicenses.title')}
            </h3>
          </div>
          <div className={`${styles.tableWrap} tableAccordion`}>
            <UraniumTableHeader />
            <Collapsible
              open
              trigger={t(
                'uraniumBackup:compareLicenses.table.basicFunctionality.basicFunctionality'
              )}
              className={styles.question}
            >
              <UraniumBasicFeaturesTable />
            </Collapsible>

            <Collapsible
              open
              trigger={t(
                'uraniumBackup:compareLicenses.table.specificFeatures.specificFeatures'
              )}
              className={styles.question}
            >
              <UraniumAdvancedFeaturesTable />
            </Collapsible>
          </div>
        </LayoutSection>
        <LayoutSection isCentered>
          <SimpleBanner
            hasBorder
            text={t('uraniumBackup:freeVersionBanner')}
            linkDestination={t('links:uranium.download')}
          />
        </LayoutSection>
        <LayoutSection isCentered>
          <div className={styles.header}>
            <h4 className={styles.titleSection}>{t('common:faq.title')}</h4>
          </div>

          <div className={styles.faqWrap}>
            <FaqListAccordion productType={PRODUCT_TYPE.URANIUM} />
          </div>
        </LayoutSection>
        <LayoutSection isCentered backgroundColor={SECTION_BACKGROUND.WHITE}>
          <div className={styles.header}>
            <h3 className={`${styles.titleSection} ${styles.blue}`}>
              {t('uraniumBackup:needABkp.title')}
            </h3>
            <p>{t('uraniumBackup:needABkp.subTitle')}</p>
          </div>
          <TwoBlockLayout
            isInverted
            logo={SupremoControlLogo}
            title={t('uraniumBackup:needABkp.leftContent.title')}
            description={t('uraniumBackup:needABkp.leftContent.description')}
            image={ImageRightColumn}
            isButtonSecondary
            labelBadge={t('uraniumBackup:needABkp.rightContent.badgeLabel')}
            labelBtn={t('uraniumBackup:needABkp.leftContent.btnLabel')}
            destinationBtn={PRODUCT_TYPE.SUPREMO}
          />
        </LayoutSection>
      </LayoutPage>
    </>
  );
};

export default UraniumBackup;

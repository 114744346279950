import * as React from 'react';
import { UserContext } from '@contexts/UserContext';
import { checkout } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { CartProductItemForCheckout, CheckoutPrice } from '@utils/types/store';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
const KEY = 'checkout';

type Props = {
  products: CartProductItemForCheckout[];
  code: string;
};
export const useCheckout = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { isLoggedIn, registrationCompleted } = React.useContext(UserContext);

  return {
    ...useMutation<CheckoutPrice | void, Error, Props>(
      [KEY],
      async ({ products, code }: Props): Promise<CheckoutPrice | void> => {
        const data = (isLoggedIn && registrationCompleted)
          ? await checkout(currentLanguage, products, code)
          : noop();

        return data;
      },
      { onError, onSuccess, retryDelay: 1000 }
    ),
  };
};

import Button, { BUTTON_STYLE } from '@components/Button';
import Card from '@components/Card';
import Modal from '@components/Modal';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function ModalDiscount({ isOpen, onClose }: Props) {
  const { t } = useTranslation(['discount', 'links']);
  return (
    <Modal
      isOpen={isOpen}
      title={t('discount:userDiscount.title')}
      onClose={onClose}
    >
      <Card>
        <div className={styles.cardContent}>
          <h4>{t('discount:userDiscount.card1.title')}</h4>
          <p className={styles.blueText}>
            {t('discount:userDiscount.card1.description')}
          </p>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow1')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount1')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow2')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount2')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow3')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount3')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow4')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount4')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow5')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount5')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow6')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount6')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow7')}</p>
            <strong>{t('discount:userDiscount.card1.arrows.discount7')}</strong>
          </div>
          <div className={styles.row}>
            <p>{t('discount:userDiscount.card1.arrows.arrow8')}</p>
            <strong className={classNames(styles.blueText, styles.cta)}>
              <Trans
                defaults={t('discount:userDiscount.card1.arrows.discount8')}
                components={{
                  link1: (
                    <a
                      target="_self"
                      rel="noreferrer"
                      href={t('links:nanosystems.contacts')}
                    ></a>
                  ),
                }}
              />
            </strong>
          </div>
        </div>
      </Card>
      <div className={styles.cardCta}>
        <h4>{t('discount:userDiscount.card2.title')}</h4>
        <p>{t('discount:userDiscount.card2.description')}</p>
        <Button styleBtn={BUTTON_STYLE.SECONDARY}>
          <a
            target="_self"
            rel="noreferrer"
            href={t('links:nanosystems.becomeReseller')}
          >
            {t('discount:userDiscount.card2.cta')}
          </a>
        </Button>
      </div>
    </Modal>
  );
}

import * as React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import styles from './styles.module.scss';
import { SORT_ORDER_LICENSE } from '@utils/types/licenses';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  onChangeSorting: (orderType: string, orderTime: SORT_ORDER_LICENSE) => void;
  currentSortDir: any;
  currentSortField: any;
}

const PurchaseStyleTable = <T extends object>({
  data,
  columns,
  onChangeSorting,
  currentSortDir,
  currentSortField,
}: ReactTableProps<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: currentSortField, desc: currentSortDir === 'desc' ? true : false },
  ]);

  const onChooseOrder = React.useCallback(
    ({ id, order }: { id: string; order: string }) => {
      onChangeSorting(id as string, order as SORT_ORDER_LICENSE);
    },
    [onChangeSorting]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  React.useEffect(() => {
    if (sorting.length > 0) {
      onChooseOrder({
        id: sorting[0]?.id as string,
        order: sorting[0].desc === true ? 'desc' : 'asc',
      });
    }
  }, [sorting, onChooseOrder]);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${styles.smallRows}`}>
        {table && (
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const canSort = header.column.getCanSort();
                    const isSorted = header.column.getIsSorted();

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={`${styles.header} ${
                              header.column.getCanSort() ? styles.sortable : ''
                            } ${styles[isSorted as string] ?? ''}`}
                            onClick={() =>
                              canSort &&
                              header.column.toggleSorting(
                                isSorted === false
                                  ? isSorted
                                  : isSorted === 'asc'
                              )
                            }
                          >
                            <span className={styles.colName}>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </span>
                            {{
                              asc: <i className={'icon-caret-up'}></i>,
                              desc: <i className={'icon-caret-down'}></i>,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          <div className={styles.cell}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PurchaseStyleTable;

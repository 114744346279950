import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';

type Props = WithChildren<{ title?: string; onClick?: () => void }>;
export default function Card({ title, children, onClick }: Props) {
  return (
    <div className={styles.container} onClick={onClick}>
      {title && (
        <div className={styles.header}>
          <strong>{title}</strong>
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

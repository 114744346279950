import { WithChildren } from '@utils/types/helpers';
import { noop } from 'lodash';
import React from 'react';

const UIContext = React.createContext<{
  shouldShowDrawer: boolean;
  setShouldShowDrawer: (shouldShowDrawer: boolean) => void;
}>({
  shouldShowDrawer: false,
  setShouldShowDrawer: noop,
});

const UIProvider = ({ children }: WithChildren) => {
  const [shouldShowDrawer, setShouldShowDrawer] =
    React.useState<boolean>(false);

  return (
    <UIContext.Provider value={{ shouldShowDrawer, setShouldShowDrawer }}>
      {children}
    </UIContext.Provider>
  );
};

export { UIProvider, UIContext };

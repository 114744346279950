import * as React from 'react';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';

import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styles from './styles.module.scss';
import Breadcrumbs from '@components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import License from '@components/License';
import Pagination from '@components/Pagination';
import Filters from '@components/Filters';
import { useLicense } from '@hooks/useLicense';
import {
  ILicense,
  LICENSE_FILTERS,
  SORT_FIELD_LICENSE,
  SORT_ORDER_LICENSE,
} from '@utils/types/licenses';
import Spinner from '@components/Spinner';
import { useEditUserNote } from '@hooks/useEditUserNote';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';

export default function LicenseScreen() {
  const { t } = useTranslation(['common', 'routes', 'license', 'dashboard']);
  const ref = React.useRef<HTMLInputElement>(null);
  const {
    data,
    mutate: onGetLicense,
    isLoading: isLoadingOnGetLicenses,
  } = useLicense();

  const { mutate: onEditUserNote } = useEditUserNote();

  // PAGINATION

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const filters = searchParams.get('filters') ?? 'all';
  const page = searchParams.get('page') ?? 1;
  const sortField = searchParams.get('sortField') ?? 'id';
  const sortDir = searchParams.get('sortDir') ?? 'desc';
  const queryPaymentId = searchParams.get('paymentId') ?? '';
  const query = searchParams.get('query') ?? '';

  const navigate = useNavigate();

  const [currentPaginationIndex, setCurrentPaginationIndex] = React.useState(
    Number(page)
  );
  const [currentQuery, setCurrentQuery] = React.useState(query);
  const [paymentId, setPaymentId] = React.useState<string>(queryPaymentId);
  const [queryValue, setQueryValue] = React.useState(query);
  const [currentFilter, setCurrentFilter] = React.useState<string>(filters);
  const [currentSortField, setCurrentSortField] =
    React.useState<string>(sortField);
  const [currentSortDir, setCurrentSortDir] = React.useState<string>(sortDir);

  //** Update all parameters on query string change */
  React.useEffect(() => {
    setCurrentQuery(query);
    setCurrentFilter(filters as LICENSE_FILTERS);
    setCurrentSortField(sortField);
    setCurrentSortDir(sortDir);
    setCurrentPaginationIndex(Number(page));
    setPaymentId(queryPaymentId);
  }, [filters, page, query, sortDir, sortField, queryPaymentId]);

  React.useEffect(() => {
    navigate(
      {
        pathname: location?.pathname,
        search: `?${createSearchParams({
          filters: currentFilter,
          page: currentPaginationIndex,
          sortField: currentSortField,
          sortDir: currentSortDir,
          query: currentQuery,
          paymentId: paymentId,
        } as any)}`,
      },
      { replace: true }
    );
    onGetLicense({
      query: currentQuery,
      filters: currentFilter as LICENSE_FILTERS,
      itemsPerPage: 10,
      currentPage: Number(currentPaginationIndex),
      sortDir: currentSortDir as SORT_ORDER_LICENSE,
      sortField: currentSortField as SORT_FIELD_LICENSE,
      paymentId: Number(paymentId),
    });
  }, [
    currentQuery,
    currentPaginationIndex,
    onGetLicense,
    currentFilter,
    currentSortField,
    location?.pathname,
    navigate,
    currentSortDir,
    paymentId,
  ]);

  const onChangePaginationFn = React.useCallback((currentIndex: number) => {
    setCurrentPaginationIndex(currentIndex);
  }, []);

  const onChangeFilterFn = React.useCallback((filter: LICENSE_FILTERS) => {
    setCurrentFilter(filter);
    setCurrentPaginationIndex(1);
    setQueryValue('');
    setCurrentQuery('');
  }, []);
  const onChangeSortFieldFn = React.useCallback(
    (sortField: SORT_FIELD_LICENSE) => {
      setCurrentSortField(sortField);
    },
    []
  );

  const onChangeSortDirFn = React.useCallback((sortDir: SORT_ORDER_LICENSE) => {
    setCurrentSortDir(sortDir);
  }, []);

  const debouncedQuery = useDebouncedCallback(
    (query) => {
      setCurrentQuery(query);
    },
    [],
    200
  );

  const onChangeQueryFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newQuery = e.target.value !== '' ? e.target.value : '';

      setCurrentPaginationIndex(1);
      setQueryValue(e?.target?.value ?? '');

      if (currentQuery !== newQuery) {
        debouncedQuery(newQuery);
      }
    },
    [debouncedQuery, currentQuery]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />

      <LayoutPage isCentered>
        <LayoutSection>
          <>
            <div className={styles.header}>
              <div>
                <Breadcrumbs
                  data={[
                    { to: t('routes:dashboard'), label: 'Dashboard' },
                    { label: t('dashboard:licenses.title') },
                  ]}
                />
                <h2>{t('dashboard:licenses.title')}</h2>
              </div>
              <div className={styles.searchContainer}>
                <input
                  placeholder={t('license:inputQueryPlaceholder')}
                  ref={ref}
                  onChange={onChangeQueryFn}
                  value={queryValue}
                />
                <span className="icon-magnifying-glass"></span>
              </div>
            </div>

            <div className={styles.container}>
              <div className={styles.filtersContainer}>
                <Filters
                  currentFilter={currentFilter}
                  currentSortDir={currentSortDir}
                  currentSortField={currentSortField}
                  onChangeFilterFn={onChangeFilterFn}
                  onChangeSortDirFn={onChangeSortDirFn}
                  onChangeSortFieldFn={onChangeSortFieldFn}
                  currentQuery={currentQuery}
                  currentPaymentId={paymentId ?? ''}
                />
              </div>
              {data && !isLoadingOnGetLicenses ? (
                data?.licenses.map((license: ILicense, index: number) => {
                  return (
                    <div className={styles.license} key={index}>
                      <License
                        id={license?.id}
                        type={license?.type}
                        licenseStatus={license?.activationStatus}
                        title={license?.title}
                        isActive={license?.isActive}
                        activationDate={license?.activationDate}
                        expireDate={license?.expirationDate}
                        description={license?.userNotes}
                        isRenewable={license?.isRenewable}
                        isUpgradable={license?.isUpgradable}
                        renewOrSuspendType={license?.renewableStatus}
                        machineCode={license?.machineCode}
                        onUpdateDescriptionFn={onEditUserNote}
                        activationCode={license?.activationCode}
                        isProfessional={license?.isProfessional}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner isGrey isMid />
                </div>
              )}
            </div>
            <div className={styles.pagination}>
              <Pagination
                pages={data?.maxPage ?? 0}
                currentPage={currentPaginationIndex}
                onChangeFn={onChangePaginationFn}
              />
            </div>
          </>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import Footer from '@components/Footer';
import * as React from 'react';
import styles from './styles.module.scss';
import sal from 'sal.js';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  isCentered?: boolean;
  totalWhite?: boolean;
};

export default function LayoutPage({
  totalWhite,
  isCentered = false,
  children,
}: Props) {
  React.useEffect(() => {
    setTimeout(() => {
      document.body.classList.add('loaded');
    }, 100);

    setTimeout(() => {
      sal({
        selector: 'main > section',
        animateClassName: 'scrolled',
        threshold: 0.01,
        once: true,
        rootMargin: '-20% 50%',
      } as any);
    }, 200);
  }, []);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [distanceFromTop, setDistanceFromTop] = React.useState(0);

  const onWindowResize = useWindowResize();
  onWindowResize(() => {
    setHeight(window.innerHeight);
  });

  const contentHeight = height - distanceFromTop;

  const contentRef = React.useRef<any>();
  const footerRef = React.useRef<any>();

  React.useLayoutEffect(() => {
    const content = contentRef?.current?.getBoundingClientRect();
    const footer = footerRef?.current?.getBoundingClientRect();

    setDistanceFromTop(content?.top + footer?.height);
  }, [contentRef]);

  return (
    <>
      <main
        ref={contentRef}
        style={{ minHeight: contentHeight }}
        className={classNames(
          [totalWhite ? styles.totalWhite : '', styles.container, 'main'],
          {
            centered: isCentered,
          }
        )}
      >
        {children}
      </main>
      <Footer ref={footerRef} />
    </>
  );
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Breadcrumb = { to?: string; label: string };
type Props = {
  data: Breadcrumb[];
};

export default function Breadcrumbs({ data }: Props) {
  const renderItem = React.useCallback(
    (item: Breadcrumb, index: number) => {
      if (index === data?.length - 1) {
        return <span key={index}>{item?.label}</span>;
      } else {
        return (
          <Link key={index} to={item?.to ?? ''}>
            {item?.label}
          </Link>
        );
      }
    },
    [data]
  );

  return <div className={styles.content}>{data?.map(renderItem)}</div>;
}

import { useQuery } from '@tanstack/react-query';
import { checkPayPalAgreement, LicenseAgreementResponse } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'check-paypal-agreement';

export const useCheckPaypalAgreement = (isEnabled: boolean, onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<LicenseAgreementResponse, Error>(
      [KEY],
      async (): Promise<LicenseAgreementResponse> => {
        return await checkPayPalAgreement(currentLanguage);
      },

      {
        enabled: isEnabled,
        onError,
        onSuccess,
      }
    ),
  };
};

import { WithChildren } from '@utils/types/helpers';
import { CartProductItem } from '@utils/types/store';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { filter, first, noop, omit, without } from 'lodash';
import React from 'react';
import CryptoJS from 'crypto-js';

const CartContext = React.createContext<{
  cartItems: CartProductItem[];
  setCartItems: (item: CartProductItem) => void;
  onDeleteCartItem: (localId: string) => void;
  onUpdateQuantity: (localId: string) => (quantity: number) => void;
  onEmptyCart: () => void;
  totalQuantity: number;
  discountCode: string;
  setDiscountCode: (code: string) => void;
}>({
  totalQuantity: 0,
  discountCode: '',
  cartItems: [],
  onEmptyCart: noop,
  onUpdateQuantity: () => noop,
  setCartItems: noop,
  onDeleteCartItem: noop,
  setDiscountCode: noop,
});

const CartProvider = ({ children }: WithChildren) => {
  const [cartItems = [], setCartItems] = useLocalStorage<CartProductItem[]>(
    'cart-items',
    []
  );
  const [discountCode, setDiscountCode] = React.useState('');

  const onEmptyCartFn = React.useCallback(() => {
    setCartItems([]);
  }, [setCartItems]);
  const onUpdateQuantityFn = React.useCallback(
    (localId: string) => (quantity: number) => {
      setCartItems(
        (cartItems || []).map((item) =>
          item?.localId === localId ? { ...item, quantity } : item
        )
      );
    },
    [cartItems, setCartItems]
  );

  const setCartItemsFn = React.useCallback(
    (cartItem: CartProductItem) => {
      // get current stored item with a comprarison without quantity param.
      const localId = CryptoJS.MD5(
        JSON.stringify(omit(cartItem, 'quantity'))
      ).toString();
      const alreadyStoredItem = first(filter(cartItems, { localId }));
      if (!Boolean(alreadyStoredItem)) {
        // check if is a renew/upgrade and there is another renew/upgrade of the same license - only if is a renew/upgrade
        if (cartItem.fromLicense !== 0 || cartItem.renewOrUpgrade !== undefined) {
          const alreadyStoredRenewUpgradeItemIndex = (cartItems || []).findIndex((p: CartProductItem) => (p.fromLicense === cartItem.fromLicense && p.renewOrUpgrade === cartItem.renewOrUpgrade));
          // if one is found, it must be removed before the new one is added
          if (alreadyStoredRenewUpgradeItemIndex > -1) {
            (cartItems || []).splice(alreadyStoredRenewUpgradeItemIndex, 1);
          }
        }
        // if it's not stored add it to the array
        setCartItems([
          ...(cartItems || []),
          {
            ...cartItem,
            localId,
          },
        ]);
      } else {
        if (!alreadyStoredItem) {
          return;
        }
        setCartItems([
          // exclude from the current items the already stored item.
          ...without(cartItems, alreadyStoredItem),
          // save the stored item and add quantity
          {
            ...alreadyStoredItem,
            quantity: alreadyStoredItem?.quantity + cartItem?.quantity,
          },
        ]);
      }
    },
    [cartItems, setCartItems]
  );

  const onDeleteCartItemFn = React.useCallback(
    (localId: string) => {
      const newItems = (cartItems || []).filter((item) => item.localId !== localId);
      setCartItems(newItems);
    },
    [cartItems, setCartItems]
  );

  const totalQuantity =
    React.useMemo(() => {
      const newListItems = (cartItems || []).reduce(
        (acc: any, item: any) => [
          ...acc,
          {
            ...item,
            quantity: item.fromLicense > 0 ? 1 : item?.quantity,
          },
        ],
        []
      );
      return newListItems?.reduce(
        (acc: number, item: CartProductItem) => acc + item.quantity,
        0
      );
    }, [cartItems]) ?? 0;

  return (
    <CartContext.Provider
      value={{
        totalQuantity,
        cartItems: (cartItems || []),
        setDiscountCode,
        discountCode,
        onEmptyCart: onEmptyCartFn,
        onUpdateQuantity: onUpdateQuantityFn,
        setCartItems: setCartItemsFn,
        onDeleteCartItem: onDeleteCartItemFn,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };

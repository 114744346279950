import * as React from 'react';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

function SupremoTableHeader() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const hideColumn = windowWidth < 1200;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const columnHelper = createColumnHelper<any>();
  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.solo, {
      id: 'solo',
      cell: (info) => info.getValue(),
      header: () => <span>Solo</span>,
    }),
    columnHelper.accessor((row) => row.business, {
      id: 'business',
      cell: (info) => info.getValue(),
      header: () => <span>Business</span>,
    }),
    columnHelper.accessor((row) => row.professional, {
      id: 'professional',
      cell: (info) => info.getValue(),
      header: () => <span>Professional</span>,
    }),
  ];

  return (
    <BasicTable
      isBodyHidden
      isHeaderHidden={hideColumn}
      isTitleRow
      data={[]}
      columns={versionColumns}
    />
  );
}

export default SupremoTableHeader;

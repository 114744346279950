import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import AccordionWrapper from '@components/AccordionWrapper';
import AccountForm from '@components/AccountForm';
import PersonalDataForm from '@components/PersonalDataForm';
import ContactForm from '@components/ContactForm';
import { useRegister } from '@hooks/useRegister';
import { UserContext } from '@contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '@contexts/CartContext';

export default function SignUpForm() {
  const [formStep, setFormStep] = React.useState(0);
  const [userInfo, setUserInfo] = React.useState<any>();
  const { cartItems } = React.useContext(CartContext);
  const navigate = useNavigate();

  const { setIsLoggedIn } = React.useContext(UserContext);

  const onSuccess = React.useCallback(() => {
    setFormStep((currentStep) => currentStep + 1);
  }, []);

  const { mutate, isLoading, isError, error } = useRegister(onSuccess);

  const onSubmitForm = React.useCallback(
    (data: any) => {
      setUserInfo({ ...userInfo, ...data });
      mutate({ ...userInfo, ...data });
    },
    [mutate, userInfo]
  );

  const { t } = useTranslation(['forms', 'routes']);

  React.useEffect(() => {
    if (userInfo && Object.keys(userInfo).length && formStep === 3) {
      setIsLoggedIn(true);
      if (cartItems.length > 0) {
        navigate(t('routes:checkout'));
      } else {
        navigate(t('routes:dashboard'));
      }
    }
  }, [formStep, navigate, setIsLoggedIn, t, userInfo, cartItems]);

  const handleClickStep0 = () => {
    setFormStep(formStep === 0 ? -1 : 0);
  };
  const handleClickStep1 = () => {
    setFormStep(formStep === 1 ? -1 : 1);
  };
  const handleClickStep2 = () => {
    setFormStep(formStep === 2 ? -1 : 2);
  };

  return (
    <>
      <div className={`${styles.container} accordionWrapForm container`}>
        <AccordionWrapper
          isOpen={formStep === 0}
          title={
            <p
              style={{ padding: '0.8rem 1.2rem', display: 'flex', flex: '1' }}
              onClick={handleClickStep0}
            >
              {t('forms:formHeader.title.account')}
            </p>
          }
          noBorderBottom={false}
          formId={1}
          formStep={formStep}
        >
          <div
            style={{
              display: formStep === 0 ? 'block' : 'none',
            }}
          >
            <AccountForm
              errorsFromApi={error}
              isLoading={isLoading}
              onSubmit={onSubmitForm}
            />
          </div>
        </AccordionWrapper>

        <AccordionWrapper
          isOpen={formStep === 1}
          title={
            <p
              style={{ padding: '0.8rem 1.2rem', display: 'flex', flex: '1' }}
              onClick={handleClickStep1}
            >
              {t('forms:formHeader.title.personalData')}
            </p>
          }
          noBorderBottom={false}
          formId={2}
          formStep={formStep}
        >
          <div
            style={{
              display: formStep === 1 ? 'block' : 'none',
            }}
          >
            <PersonalDataForm
              errorsFromApi={error}
              isLoading={isLoading}
              onSubmit={onSubmitForm}
            />
          </div>
        </AccordionWrapper>

        <AccordionWrapper
          isOpen={formStep === 2}
          title={
            <p
              style={{ padding: '0.8rem 1.2rem', display: 'flex', flex: '1' }}
              onClick={handleClickStep2}
            >
              {t('forms:formHeader.title.contacts')}
            </p>
          }
          noBorderBottom={false}
          formId={3}
          formStep={formStep}
        >
          <div
            style={{
              display: formStep === 2 ? 'block' : 'none',
            }}
          >
            <ContactForm
              errorsFromApi={error}
              isCountryItaly={userInfo?.country === '1'}
              isCountryAmerica={userInfo?.country === '785'}
              isCountryCanada={userInfo?.country === '595'}
              isInEurope={userInfo?.isInEurope}
              isBusiness={userInfo?.isBusiness}
              onSubmit={onSubmitForm}
              isLoading={isLoading}
              isError={isError}
            />
          </div>
        </AccordionWrapper>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getDashboardDiscount } from '@services/api';
import { UserDashboardDiscount } from '@utils/types/user';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
export const KEY = 'user-discount';

export default function useUserDiscount(
  enabled = false,
  onSuccess?: typeof noop,
  onError?: typeof noop
) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<UserDashboardDiscount, Error>(
      [KEY],
      async () => {
        return await getDashboardDiscount(currentLanguage);
      },
      {
        enabled,
        onSuccess,
        onError,
        retry: false,
        refetchOnWindowFocus: false,
      }
    ),
  };
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { CartContext } from '@contexts/CartContext';
import {
  ERRORS_TYPE,
  normalizeCumulableCartItemsForCheckout,
  normalizeNotCumulableCartItemsForCheckout,
} from '@services/normalize';
import { ProductVersion, PRODUCT_TYPE } from '@utils/types/store';
import { filter } from 'lodash';
import * as React from 'react';
import useSupremoProduct from './useSupremoProduct';
import useUraniumProduct from './useUraniumProduct';
import { useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getUpgradeRenewProduct } from '@services/api';
import { useUpgradeAddToCart } from './useUpgradeAddToCart';

export default function useNormalizeCartProductsForCheckout() {
  const { data: supremoProducts, isLoading: isLoadingSupremoProduct } =
    useSupremoProduct();
  const { data: uraniumProduct, isLoading: isLoadingUraniumProduct } =
    useUraniumProduct();

  const { cartItems, onDeleteCartItem } = React.useContext(CartContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isLoading = isLoadingSupremoProduct || isLoadingUraniumProduct;
  const [allUpgradedProducts, setAllUpgradedProducts] = React.useState<any>([]);

  const { mutate: getCartForUpgrade, isLoading: isLoadingGetCartForUpgrade } =
    useUpgradeAddToCart(
      (data) => {
        setAllUpgradedProducts((current: any) => [...current, data]);
      },
      (err, data) => {
        if (err?.type === ERRORS_TYPE.LICENSE_NOT_UPGRADABLE || err?.type === ERRORS_TYPE.INVALID_UPGRADE) {
          onDeleteCartItem(data?.product?.localId);
        }
      }
    );

  const onGetUpgradedProductForCart = React.useCallback(
    (product: any, connections: any, teamId: any) => {
      getCartForUpgrade({
        product,
        connections: connections - 3,
        teamId,
      });
    },
    [getCartForUpgrade]
  );

  const flatAllProductsInArray = (prod: any, data: any) => {
    if (prod.productType === PRODUCT_TYPE.SUPREMO) {
      return [
        ...data.products?.yearly,
        ...data.products?.quarterly,
        ...data.products?.biennial,
        ...data.products?.triennial,
      ];
    } else if (prod.productType === PRODUCT_TYPE.URANIUM) {
      return [...data.products];
    } else {
      return [];
    }
  };
  const upgradedProducts = cartItems.filter((el) => el.upgradeId);

  const results = useQueries({
    queries: upgradedProducts.map((upgradedProduct) => {
      return {
        queryKey: ['upgraded-product', upgradedProduct.localId],
        refetchOnWindowFocus: false,
        queryFn: () =>
          getUpgradeRenewProduct(
            currentLanguage,
            String(upgradedProduct.upgradeId)
          ),

        enabled: !!upgradedProducts.length,
        onSuccess: (data: any) => {
          const allItems = flatAllProductsInArray(upgradedProduct, data);

          const IdToMatch =
            upgradedProduct?.productType === PRODUCT_TYPE.SUPREMO
              ? upgradedProduct?.matchedId
              : upgradedProduct?.id;

          const product = allItems.find((el) => el.id === IdToMatch);

          const productWithId = {
            ...product,
            ...{ localId: upgradedProduct?.localId },
          };

          if (upgradedProduct?.productType === PRODUCT_TYPE.URANIUM) {
            setAllUpgradedProducts((current: any) => [
              ...current,
              productWithId,
            ]);
          }
          if (upgradedProduct?.productType === PRODUCT_TYPE.SUPREMO) {
            onGetUpgradedProductForCart(
              productWithId,
              upgradedProduct?.connections,
              upgradedProduct?.teamAssistanceProfileId
            );
          }
        },
        onError: (error: any) => {
          if (error.type === ERRORS_TYPE.NOT_LOGGED) {
            // TODO: manage invalid upgrades here
          }
        },
      };
    }),
  });

  const isLoadingUseQueries = results.some((result) => result.isLoading);

  const isLoadingAllProducts = isLoading || isLoadingUseQueries;

  const allProducts: ProductVersion[] = React.useMemo(() => {
    if (upgradedProducts.length > 0) {
      return !isLoadingAllProducts &&
        supremoProducts &&
        uraniumProduct &&
        allUpgradedProducts
        ? [
            ...supremoProducts?.products?.quarterly,
            ...supremoProducts?.products?.yearly,
            ...supremoProducts?.products?.biennial,
            ...supremoProducts?.products?.triennial,
            ...uraniumProduct?.products,
            ...allUpgradedProducts,
          ]
        : [];
    } else {
      return !isLoading && supremoProducts && uraniumProduct
        ? [
            ...supremoProducts?.products?.quarterly,
            ...supremoProducts?.products?.yearly,
            ...supremoProducts?.products?.biennial,
            ...supremoProducts?.products?.triennial,
            ...uraniumProduct?.products,
          ]
        : [];
    }
  }, [
    isLoadingAllProducts,
    isLoading,
    supremoProducts,
    uraniumProduct,
    allUpgradedProducts,
    upgradedProducts.length,
  ]);

  // normalize supremo item for checkout api
  const data = React.useMemo(() => {
    return Boolean(allProducts.length)
      ? [
          ...normalizeCumulableCartItemsForCheckout(
            filter(cartItems, { isCumulable: true }),
            allProducts
          ),
          ...normalizeNotCumulableCartItemsForCheckout(
            filter(cartItems, { isCumulable: false }),
            allProducts
          ),
        ]
      : [];
  }, [cartItems, allProducts]);

  return {
    isLoading,
    allProducts,
    data,
  };
}

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

function TeamWarningTooltip() {
    const { t } = useTranslation('supremoControl');
    return (
        <Tooltip
            placement="top"
            overlay={<Trans defaults={t('tooltip.teamWarning.professional')} />}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
            <i className={`icon-warning-fill ${styles.warningIcon} `}></i>
        </Tooltip>
    );
}

export default TeamWarningTooltip;

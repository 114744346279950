import { Trans, useTranslation } from 'react-i18next';

function TeamInfoTooltip(profiles: any) {
  const { t } = useTranslation('supremoControl');
  return (
    <>
      <p>{t('tooltip.team.header')}</p>
      <ul>
        {profiles?.profiles &&
          profiles?.profiles.map((el: any, key: number) => (
            <li key={key}>{el.description}</li>
          ))}
      </ul>
      <br />
      <p>
        <Trans
          defaults={t('tooltip.team.footer')}
          components={{
            link1: (
              <a style={{ fontWeight: 600, textDecoration: 'underline' }}
                target="_blank"
                rel="noreferrer"
                href={`${t('tooltip.team.urlContact')}`}
              >
                ''
              </a>
            ),
          }}
        />
      </p>
    </>
  );
}

export default TeamInfoTooltip;

import { WithChildren } from '@utils/types/helpers';
import Spinner from '@components/Spinner';
import styles from './styles.module.scss';
import classNames from 'classnames';

export enum BUTTON_STYLE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT_BG = 'light_bg',
  WHITE = 'white',
  TERTIARY = 'tertiary',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
}

type Props = WithChildren<{
  onClick?: () => void;
  styleBtn?: BUTTON_STYLE;
  size?: BUTTON_SIZE;
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  style?: typeof styles;
}>;

function Button({
  isLoading,
  onClick,
  children,
  size = BUTTON_SIZE.MEDIUM,
  styleBtn = BUTTON_STYLE.PRIMARY,
  fullWidth,
  disabled = false,
  style,
}: Props) {
  const spinnerProps =
    styleBtn === BUTTON_STYLE.PRIMARY ? { isWhite: true } : {};
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'cta',
        styles.container,
        styleBtn === BUTTON_STYLE.PRIMARY || styleBtn === BUTTON_STYLE.SECONDARY
          ? styleBtn
          : styles[styleBtn],
        styles[size],
        {
          full: fullWidth,
        }
      )}
      style={style}
    >
      {!isLoading ? (
        children
      ) : (
        <div style={{ position: 'relative' }}>
          <span style={{ opacity: 0 }}>{children}</span>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50% ,-50%)',
            }}
          >
            <Spinner {...spinnerProps} isSmall />
          </span>
        </div>
      )}
      {}
    </button>
  );
}

export default Button;

import * as React from 'react';
import Card from '@components/Card';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import usePaymentRecap from '@hooks/usePaymentRecap';
import SkeletonBasic from '@components/SkeletonBasic';
import { ProductVersion } from '@utils/types/store';
import SyncLoader from '@components/SyncLoader';
import useGetUserInfo from '@hooks/useUserInfo';
import { dataLayerPushPurchase } from '@utils/tagManagerHelper';
import Price from '@components/Price';
import { forceToFloat } from '@utils/types/helpers';

type Props = {
  isSide?: boolean;
};
export default function OrderRecap({ isSide }: Props) {
  const { data, isLoading } = usePaymentRecap();
  const { data: userInfo, isLoading: isLoadingUserInfo } = useGetUserInfo();
  const { t } = useTranslation(['common', 'payments']);

  const renderItems = React.useCallback(
    (item: Partial<ProductVersion>, key: number) => {
      return (
        <div className={styles.productItem} key={key}>
          <div>
            {item?.name && (
              <strong
                dangerouslySetInnerHTML={{
                  __html: item?.name,
                }}
              ></strong>
            )}
            <p>
              {t('common:shops.quantityLong')}: {item?.quantity}
            </p>
          </div>
          <strong className={styles.currency}>
            {item?.price && item?.quantity && <Price value={item?.price * item?.quantity} />}
          </strong>
        </div>
      );
    },
    [t]
  );

  const [pushDataLayer, setPushDataLayer] = React.useState(true);
  React.useEffect(() => {
    if (pushDataLayer && data && data?.products && data?.products.length && data?.total) {
      dataLayerPushPurchase(data.id, forceToFloat(data?.total), data?.products);
      setPushDataLayer(false);
    }
  }, [pushDataLayer, data]);

  return (
    <div className={styles.container}>
      {isSide ? (
        <h4>{t('payments:orderRecap.title')}</h4>
      ) : (
        <h2>{t('payments:orderRecap.title')}</h2>
      )}
      <div className={styles.item}>
        <Card title={t('payments:orderRecap.purchases')}>
          <div className={styles.cardWrapper}>
            <div className={styles.inline}>
              <strong>{t('common:shops.total')}</strong>
              {isLoading ? (
                <SkeletonBasic width={80} height={32} />
              ) : (
                <strong className={styles.total}>
                  {data?.total && <Price value={data?.total} />}
                </strong>
              )}
            </div>
            {isLoading ? (
              <SyncLoader size={10} color={'rgba(0,0,0,0.2)'} />
            ) : (
              data?.products?.map(renderItems)
            )}
          </div>
        </Card>
      </div>
      <div className={styles.item}>
        <Card title={t('payments:orderRecap.account')}>
          <div className={styles.cardWrapper}>
            {isLoading ? (
              <SkeletonBasic width={120} height={16} />
            ) : (
              <strong>{data?.userInfo?.fullname}</strong>
            )}
            {isLoadingUserInfo ? (
              <div>
                <SkeletonBasic width={200} height={16} />
              </div>
            ) : (
              <p style={{ marginTop: 8 }}>{userInfo?.email}</p>
            )}
          </div>
        </Card>
      </div>
      <div className={styles.item}>
        <Card title={t('payments:orderRecap.billing')}>
          <div className={styles.cardWrapper}>
            {isLoading ? (
              <SkeletonBasic width={120} height={16} />
            ) : (
              <strong>{data?.userInfo.fullname}</strong>
            )}
            {isLoading ? (
              <SkeletonBasic width={120} height={16} />
            ) : (
              <>
                <p style={{ marginTop: 8, marginBottom: 8 }}>
                  {data?.userInfo.address}
                </p>
                <p style={{ marginTop: 8, marginBottom: 8 }}>
                  {data?.userInfo.city}
                </p>
                {data?.userInfo.fiscalCode && (
                  <p>{data?.userInfo.fiscalCode} </p>
                )}

                {data?.userInfo.taxNumber && <p>{data?.userInfo.taxNumber} </p>}
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

import * as React from 'react';
import ChooseProductBox from '@components/ChooseProductBox';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import SupremoControlLogo from '@assets/images/supremo.svg';

export default function ProductBoxSupremo() {
  const navigate = useNavigate();

  const onBuyFn = React.useCallback(() => {
    navigate(t('routes:supremoControl'));
  }, [navigate]);

  return (
    <ChooseProductBox
      description={t('shop:remoteControl')}
      onClick={onBuyFn}
      image={SupremoControlLogo}
      price={'5€'}
      priceType={t('common:shops.perConnectionMonth')}
    />
  );
}

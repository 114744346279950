import { autologUser } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { Token } from '@utils/types/user';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import useGetUserInfo from './useUserInfo';
const KEY = 'user-autologin';

export const useAutologinUser = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const data = useMutation<
    Token,
    Error,
    { token: string }
  >(
    [KEY],
    async ({
      token
    }): Promise<Token> => {
      return await autologUser(currentLanguage, token);
    },
    {
      onSuccess,
      onError
    }
  );

  const query = useGetUserInfo(!!data?.data, onSuccess, onError);

  return {
    isLoading: data?.isLoading || query?.isLoading,
    data: query.data,
    mutate: data?.mutate,
    isError: data?.isError || query?.isError,
    error: data?.error || query.error,
  };
};

import { BUTTON_STYLE } from '@components/Button';
import { PRODUCT_TYPE } from '@utils/types/store';
import { t } from 'i18next';
//import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  logo: string;
  title: string;
  description: string;
  labelBtn: string;
  image: string;
  labelBadge: string;
  isInverted?: boolean;
  isButtonSecondary?: boolean;
  destinationBtn: string;
};

function TwoBlockLayout({
  logo,
  title,
  description,
  labelBadge,
  labelBtn,
  isInverted,
  isButtonSecondary,
  image,
  destinationBtn,
}: Props) {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.leftColumn} ${
          isInverted ? styles.isInverted : ''
        }`}
      >
        <img src={logo && logo} alt="logo" />
        <h2>{title && title}</h2>
        <p>{description && description}</p>

        <Link
          className={`${
            isButtonSecondary ? BUTTON_STYLE.SECONDARY : BUTTON_STYLE.PRIMARY
          } cta`}
          to={
            destinationBtn === PRODUCT_TYPE.SUPREMO
              ? t('routes:supremoControl')
              : t('routes:uraniumBackup')
          }
        >
          {labelBtn}
        </Link>
      </div>
      <div className={styles.rightColumn}>
        <img src={image && image} alt="background" />
        {/*
        <div className="badge badge--success">
          <i className="badge__status"></i>
          <p>{<Trans defaults={labelBadge && labelBadge} />}</p>
        </div>
        */}
      </div>
    </div>
  );
}

export default TwoBlockLayout;

import { useMutation } from '@tanstack/react-query';
import { getPurchaseDocumentDownload } from '@services/api';
import { noop } from 'lodash';
import { PAYMENT_DOWNLOAD_DOCUMENT } from '@utils/types/purchase';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-purchase-document';

type Props = {
  paymentId: string;
  type: PAYMENT_DOWNLOAD_DOCUMENT;
};
export const useDownloadPurchaseDocument = (
  onSuccess = noop,
  onError = noop
) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<string, Error, Props>(
      [KEY],
      async ({ paymentId, type }: Props): Promise<string> => {
        return await getPurchaseDocumentDownload(
          currentLanguage,
          paymentId,
          type
        );
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

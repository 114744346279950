import styles from './styles.module.scss';

type Props = {
  min: number;
  max: number;
  value: number;
  onChange: (val: number) => void;
};

export default function ControlsSelect({ value, onChange, min, max }: Props) {
  function validateInput(e: any) {
    if (Number(e.target.value) > max) {
      e.preventDefault();
      e.target.value = String(max);
    }
    onChange(e.target.value);
  }

  function validateInputOnkeyDown(e: any) {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault();
    }
  }

  const onBlurHandler = (e: any) => {
    if (e.target.value === '' || Number(e.target.value) < min) {
      e.target.value = String(min);
    }
    onChange(+e.target.value);
  };
  const arr = Array.from({ length: max - min + 1 }, (_, i) => i + min);

  return (
    <div className={styles.selectWrapper}>
      <select
        onBlur={onBlurHandler}
        className={styles.container}
        onChange={validateInput}
        onKeyDown={validateInputOnkeyDown}
        value={value}
      >
        {arr.map((el, key) => {
          return <option key={key} value={el}>{el}</option>;
        })}
      </select>
    </div>
  );
}

import React from 'react';
import { SupremoFeatures, SupremoFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import Tooltip from 'rc-tooltip';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

export function renderSupremoFeaturesColumn(
  columns: SupremoFeaturesList[]
): SupremoFeatures[] {
  return columns.map((el: SupremoFeaturesList) => {
    return {
      feature: (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {el?.name}
          {el?.tooltip && (
            <Tooltip
              placement="top"
              overlay={el?.tooltip}
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <i
                className={'icon-info'}
                style={{
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
              ></i>
            </Tooltip>
          )}
        </p>
      ),
      solo: (
        <i
          style={{ color: el?.solo ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.solo ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      business: (
        <i
          style={{ color: el?.business ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.business ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),

      professional: (
        <i
          style={{ color: el?.pro ? '#3CC13B' : 'red', fontSize: '32px' }}
          className={`${el?.pro ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
    };
  });
}

function SupremoAccessAndControlTable() {
  const { t } = useTranslation('supremoControl');

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const hideColumn = windowWidth < 1200;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.solo, {
      id: 'solo',
      cell: (info) => info.getValue(),
      header: () => <span>Solo</span>,
    }),
    columnHelper.accessor((row) => row.business, {
      id: 'business',
      cell: (info) => info.getValue(),
      header: () => <span>Business</span>,
    }),
    columnHelper.accessor((row) => row.professional, {
      id: 'professional',
      cell: (info) => info.getValue(),
      header: () => <span>Professional</span>,
    }),
  ];
  const featuresNameAccessAndControl: SupremoFeaturesList[] = [
    {
      name: t(
        'compareSubscription.table.accessAndControl.enabledDevices.title'
      ),
      tooltip: (
        <p>
          {t(
            'compareSubscription.table.accessAndControl.enabledDevices.tooltip'
          )}
        </p>
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.remoteEndpoints.title'
      ),
      tooltip: (
        <p>
          {t(
            'compareSubscription.table.accessAndControl.remoteEndpoints.tooltip'
          )}
        </p>
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.unattendedAccess.title'
      ),
      tooltip: (
        <p>
          {t(
            'compareSubscription.table.accessAndControl.unattendedAccess.tooltip'
          )}
        </p>
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.multiplatform.title'),
      tooltip: (
        <p>
          {t(
            'compareSubscription.table.accessAndControl.multiplatform.tooltip'
          )}
        </p>
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.automaticUpdates.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.automaticUpdates.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.multimonitorSupport.title'
      ),
      tooltip: t(
        'compareSubscription.table.accessAndControl.multimonitorSupport.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.remotePrint.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.remotePrint.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.shortcutFunctionality.title'
      ),
      tooltip: t(
        'compareSubscription.table.accessAndControl.shortcutFunctionality.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.commandLineConfiguration.title'
      ),
      tooltip: t(
        'compareSubscription.table.accessAndControl.commandLineConfiguration.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.mobileSupport.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.mobileSupport.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.onlineReport.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.onlineReport.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.chat.title'),
      tooltip: t('compareSubscription.table.accessAndControl.chat.tooltip'),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.fileTransfer.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.fileTransfer.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.accessAndControl.unlimitedDevices.title'
      ),
      tooltip: t(
        'compareSubscription.table.accessAndControl.unlimitedDevices.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.contactBook.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.contactBook.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t('compareSubscription.table.accessAndControl.integrationApi.title'),
      tooltip: t(
        'compareSubscription.table.accessAndControl.integrationApi.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
  ];

  const featuresAccessAndControl = [
    ...renderSupremoFeaturesColumn(featuresNameAccessAndControl),
  ];

  return (
    <BasicTable
      isHeaderHidden={!hideColumn}
      isTitleRow
      data={featuresAccessAndControl}
      columns={versionColumns}
    />
  );
}

export default SupremoAccessAndControlTable;

import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input, { INPUT_SIZE } from '@components/Input';
import styles from './styles.module.scss';
import Button from '@components/Button';
import { usernameRegex, emailRegex } from '@utils/formUtils';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '@components/Spinner';

type Props = {
  isLoading: boolean;
  isError?: boolean;
  errorsFromApi?: string[] | null;
  forcedEmail?: string;

  onSubmit: ({
    username,
    email,
    password,
    recaptcha = '',
  }: {
    username: string;
    email: string;
    password: string;
    recaptcha: string;
  }) => void;
};

export default function ShortSignUpForm({
  isLoading,
  isError,
  errorsFromApi,
  forcedEmail = '',
  onSubmit
}: Props) {
  const { t } = useTranslation(['forms', 'links']);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [captchaToken, setCaptchaToken] = React.useState<string>('');

  const [isDirty, setIsDirty] = React.useState<{
    username: boolean;
    email: boolean;
    password: boolean;
  }>({
    username: false,
    email: false,
    password: false,
  });

  const [showRecaptcha, setShowRecaptcha] = React.useState(true);
  function handleOnChange(value: any) {
    setCaptchaToken(value);
  }

  // Fix to show the recaptcha when is not initialized well
  React.useEffect(() => {
    setTimeout(() => {
      if (showRecaptcha) {
        if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue) {
          if (recaptchaRef.current.getValue() === null) {
            setShowRecaptcha(false);
          }
        }
      } else {
        setShowRecaptcha(true);
      }
    }, 100);
  }, [showRecaptcha]);

  const { ref: usernameRef, ...usernameInputProps } = register('username', {
    required: t('forms:errorsState.required.username'),
    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.passwordMinLength'),
    },
    pattern: {
      value: usernameRegex,
      message: t('forms:errorsState.invalid.username'),
    }
  });

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('forms:errorsState.required.email'),
    pattern: {
      value: emailRegex,
      message: t('forms:errorsState.invalid.email'),
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('forms:errorsState.required.password'),

    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.passwordMinLength'),
    },
  });

  React.useEffect(() => {
    setValue('email', forcedEmail, { shouldDirty: true });
  }, [setValue, forcedEmail]);

  React.useEffect(() => {
    errorsFromApi
      ? errorsFromApi?.forEach((element: string) => {
        if (['username', 'email', 'password'].indexOf(element) > -1) {
          const message = element
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            (t(`forms:errorsState.errorsFromApi.${element}`) as string)
            : t('forms:errorsState.errorsFromApi.generic');
          setError(element, { type: 'error', message });
        }
      })
      : clearErrors();
  }, [setError, errorsFromApi, t, clearErrors]);

  const handleOnSubmit = ({
    username,
    email,
    password,
  }: {
    username: string;
    email: string;
    password: string;
  }) => {
    setIsDirty({ username: username.length > 0, email: email.length > 0, password: password.length > 0 });

    if (captchaToken) {
      onSubmit({ username, email, password, ...{ recaptcha: captchaToken } });
    } else {
      onSubmit({ username, email, password, recaptcha: '' });
    }
  };

  return (
    <>
      <div className={`${styles.container} container`}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <Input
            label={t('forms:label.username')}
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.username ?? false}
            type="text"
            placeholder={t('forms:placeholder.username')}
            inputRef={usernameRef}
            {...usernameInputProps}
            error={errors?.username?.message as any}
            isRequired
          />
          <Input
            label={t('forms:label.email')}
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.email ?? false}
            type="email"
            placeholder={t('forms:placeholder.email')}
            inputRef={emailRef}
            {...emailInputProps}
            error={errors?.email?.message as any}
            disabled={forcedEmail.length > 0}
            isRequired
          />
          <Input
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.password ?? false}
            type="password"
            label={t('forms:label.password')}
            placeholder={t('forms:placeholder.password')}
            inputRef={passwordRef}
            {...passwordInputProps}
            error={errors?.password?.message as any}
            isRequired
          />

          <div className={`${styles.inputWrapperContainer} ${styles.privacy}`}>
            <div className={styles.inputWrapper}>
              <div className={`${styles.inputRadio} ${styles.isPa}`}>
                <div className="input-checkbox">
                  <label htmlFor="privacy">
                    <input
                      {...register('privacy', {
                        required: t('forms:errorsState.required.generic'),
                      })}
                      type="checkbox"
                      name="privacy"
                      value="1"
                      id="privacy"
                    />
                    <span className={`${styles.privacyPolicy} input-list-label`}>
                      <p>
                        {
                          <Trans
                            defaults={t('forms:placeholder.privacyAgreementText')}
                            components={{
                              link1: (
                                <a
                                  target="_self"
                                  rel="noreferrer"
                                  href={t('links:nanosystems.privacy')}
                                >
                                  ''
                                </a>
                              ),
                            }}
                          />
                        }
                      </p>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              {errors.privacy && (
                <small className={styles.error}>
                  {errors.privacy?.message as any}
                </small>
              )}
            </div>
          </div>

          {showRecaptcha &&
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
              onChange={handleOnChange}
              className={styles.recaptcha}
            />
          }

          <Button style={{ marginTop: '10px' }} fullWidth disabled={!captchaToken}>
            {isLoading ? (
              <div className={styles.spinnerContainer}>
                <Spinner isWhite isSmall />
              </div>
            ) : (
              t('forms:startFreeTrial')
            )}
          </Button>
        </form>
      </div>
    </>
  );
}

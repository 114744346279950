import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';

import { loadInvite, acceptInvite } from '@services/api';
import { UserInvite } from '@utils/types/user';

export const KEY = 'invite';

export const useLoadInvite = (onSuccess = noop, onError = noop) => {
    const data = useMutation<any, string[], string>(
        [KEY],
        async (token: string): Promise<UserInvite> => {
            return await loadInvite(token);
        },
        {
            onError,
            onSuccess,
        }
    );

    return {
        isLoading: data?.isLoading,
        mutate: data.mutate,
        isError: data?.isError,
        error: data?.error,
    };
};

export const useAcceptInvite = (onSuccess = noop, onError = noop) => {
    const data = useMutation<any, string[], string>(
        [KEY],
        async (token: string): Promise<any> => {
            await acceptInvite(token);
        },
        {
            onError,
            onSuccess,
        }
    );

    return {
        isLoading: data?.isLoading,
        mutate: data.mutate,
        isError: data?.isError,
        error: data?.error,
    };
};

import React from 'react';
import styles from './styles.module.scss';

type Props<T> = {
  options: T[];
  onChange: (v: T) => void;
  value?: T;
};

export default function CustomSelect<T extends { id: any; label: any }>({
  onChange,
  options,
  value,
}: Props<T>) {
  const onChangeFn = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const currentSelection = options.find(
        (option) => option.label === e.target.value
      );

      if (currentSelection) {
        onChange({
          id: currentSelection?.id,
          label: currentSelection?.label,
        } as T);
      }
    },
    [onChange, options]
  );

  const renderItems = React.useCallback(
    (el: T) => (
      <option key={el.id} value={el.label}>
        {el.label}
      </option>
    ),
    []
  );

  return (
    <div className={styles.selectWrapper}>
      <select
        multiple={false}
        onChange={onChangeFn}
        value={value?.label}
        className={styles.container}
      >
        {options.map(renderItems)}
      </select>
    </div>
  );
}

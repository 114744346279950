import React from 'react';
import { useForm } from 'react-hook-form';
import { /*Trans,*/ useTranslation } from 'react-i18next';
import { get } from 'lodash';

import styles from './styles.module.scss';

import Button from '@components/Button';
import Input, { INPUT_SIZE } from '@components/Input';
import Spinner from '@components/Spinner';

import { emailRegex, zipCodeITARegex, telRegex } from '@utils/formUtils';
import { registerPhase3Response } from '@utils/types/user';

type Props = {
  onSubmit: any;
  isCountryItaly: boolean;
  isLoading: boolean;
  isError: boolean;
  errorsFromApi?: string[] | null;
  userInfoPhase3?: registerPhase3Response | null;
};

export default function MyAccountFormContacts({
  onSubmit,
  isCountryItaly,
  isLoading,
  isError,
  errorsFromApi,
  userInfoPhase3
}: Props) {
  const { t } = useTranslation(['forms', 'routes']);

  const {
      watch,
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  React.useEffect(() => {
    if (userInfoPhase3) {
      setValue('city', userInfoPhase3.city);
      setValue('zipCode', userInfoPhase3.zipCode);
      setValue('streetAndNumber', userInfoPhase3.streetAndNumber);
      setValue('tel', userInfoPhase3.tel);
      setValue('adminEmail', userInfoPhase3.adminEmail);
      setValue('newsletter', userInfoPhase3.newsletter);
      setValue('codiceConcessionario', userInfoPhase3.codiceConcessionario);
    }
  }, [userInfoPhase3, setValue]);

  const city = watch('city');
  const zipCode = watch('zipCode');
  const streetAndNumber = watch('streetAndNumber');
  const tel = watch('tel');
  const adminEmail = watch('adminEmail');
  const newsletter = watch('newsletter');
  const codiceConcessionario = watch('codiceConcessionario');

  function isValidZipCodeITACode(itaZipCode: string): boolean {
    if (itaZipCode) {
      return zipCodeITARegex.test(itaZipCode);
    } else {
      return true;
    }
  }

  function isValidTelCode(telCode: string): boolean {
    if (telCode) {
      return telRegex.test(telCode);
    } else {
      return true;
    }
  }

  const [isDirty, setIsDirty] = React.useState<{
    city: boolean;
    zipCode: boolean;
    tel: boolean;
    streetAndNumber: boolean;
    adminEmail: boolean;
    newsletter: boolean;
    privacyAgreement: boolean;
  }>({
    city: false,
    zipCode: false,
    tel: false,
    streetAndNumber: false,
    adminEmail: false,
    newsletter: false,
    privacyAgreement: false,
  });

  const { ref: cityRef, ...cityInputProps } = register('city', {
    required: t('forms:errorsState.required.city'),
  });

  const { ref: streetAndNumberRef, ...streetAndNumberInputProps } = register(
    'streetAndNumber',
    {
      required: t('forms:errorsState.required.streetAndNumber'),
    }
  );

  const { ref: adminEmailRef, ...adminEmailInputProps } = register(
    'adminEmail',
    {
      required: false,
      pattern: {
        value: emailRegex,
        message: t('forms:errorsState.invalid.email'),
      },
    }
  );

  const { ref: telRef, ...telInputProps } = register('tel', {
    required: false,
    validate: {
      value: (value) => {
        return isValidTelCode(value) || t('forms:errorsState.invalid.tel');
      },
    },
  });

  const { ref: zipCodeRef, ...zipCodeInputProps } = register('zipCode', {
    required: true,
    validate: {
      value: (value) => {
        if (value && isCountryItaly) {
          return (
            isValidZipCodeITACode(value) ||
            t('forms:errorsState.invalid.zipCodeITA')
          );
        }
      },
    },
  });

  const normalizeFormData = (formData: any) => {
    if (city === userInfoPhase3?.city) {
      delete formData.city;
    }
    if (streetAndNumber === userInfoPhase3?.streetAndNumber) {
      delete formData.streetAndNumber;
    }
    if (zipCode === userInfoPhase3?.zipCode) {
      delete formData.zipCode;
    }
    if (tel === userInfoPhase3?.tel) {
      delete formData.tel;
    }
    if (adminEmail === userInfoPhase3?.adminEmail) {
      delete formData.adminEmail;
    }
    if (newsletter === userInfoPhase3?.newsletter) {
      delete formData.newsletter;
    }
  };

  const onSubmitForm = (formStep3Data: any) => {
    setIsDirty({
      city: formStep3Data?.city?.length > 0,
      streetAndNumber: formStep3Data?.streetAndNumber?.length > 0,
      zipCode: formStep3Data?.zipCode?.length > 0,
      tel: formStep3Data?.tel?.length > 0,
      adminEmail: formStep3Data?.adminEmail?.length > 0,
      newsletter: formStep3Data?.newsletter?.length > 0,
      privacyAgreement: formStep3Data?.privacyAgreement?.length > 0,
    });

    const formData = { ...formStep3Data };
    normalizeFormData(formData);
    onSubmit(formData);
  };

  React.useEffect(() => {
    errorsFromApi
      ? errorsFromApi?.forEach((element: string) => {
        const message = element
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (t(`forms:errorsState.errorsFromApi.${element}`) as string)
          : t('forms:errorsState.errorsFromApi.generic');
        setError(element, { type: 'error', message });
      })
      : clearErrors();
  }, [setError, errorsFromApi, t, clearErrors]);

  return (
    <div className={styles.container}>
      <form key={3} onSubmit={handleSubmit(onSubmitForm as any)}>
        <Input
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          label={t('forms:label.city')}
          hasValue={isDirty?.city ?? false}
          type="text"
          placeholder={t('forms:placeholder.city')}
          inputRef={cityRef}
          {...cityInputProps}
          error={errors?.city?.message as any}
          isRequired
          isHorizontal
          defaultValue={get(userInfoPhase3, 'city')}
        />

        <Input
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          label={t('forms:label.streetAndNumber')}
          hasValue={isDirty?.streetAndNumber ?? false}
          type="text"
          placeholder={t('forms:placeholder.streetAndNumber')}
          inputRef={streetAndNumberRef}
          {...streetAndNumberInputProps}
          error={errors?.streetAndNumber?.message as any}
          isRequired
          isHorizontal
          defaultValue={get(userInfoPhase3, 'streetAndNumber')}
        />

        <Input
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          label={t('forms:label.zipCode')}
          hasValue={isDirty?.zipCode ?? false}
          type="text"
          placeholder={t('forms:placeholder.zipCode')}
          inputRef={zipCodeRef}
          {...zipCodeInputProps}
          error={errors?.zipCode?.message as any}
          isHorizontal
          defaultValue={get(userInfoPhase3, 'zipCode')}
        />

        <Input
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          label={t('forms:label.tel')}
          hasValue={isDirty?.tel ?? false}
          type="text"
          placeholder={t('forms:placeholder.tel')}
          inputRef={telRef}
          {...telInputProps}
          error={errors?.tel?.message as any}
          isHorizontal
          defaultValue={get(userInfoPhase3, 'tel')}
        />

        <Input
          inputSize={INPUT_SIZE.BIG}
          isFullWidth
          label={t('forms:label.adminEmail')}
          hasValue={isDirty?.adminEmail ?? false}
          type="email"
          placeholder={t('forms:placeholder.adminEmail')}
          inputRef={adminEmailRef}
          {...adminEmailInputProps}
          error={errors?.adminEmail?.message as any}
          isHorizontal
          defaultValue={get(userInfoPhase3, 'adminEmail')}
        />

        <div className={`${styles.inputWrapperContainer} ${styles.horizontal}`}>
          <label className={styles.inputLabel}>
            {t('forms:placeholder.newsletter')}
          </label>
          <div className={styles.inputWrapper}>
            <div className={`${styles.inputRadio} ${styles.isPa}`}>
              <div className="input-radio">
                <label htmlFor="noNewsletter">
                  <input
                    {...register('newsletter', {
                      required: false,
                    })}
                    type="radio"
                    name="newsletter"
                    value="0"
                    id="noNewsletter"
                  />
                  <span className="input-list-label">
                    {t('forms:placeholder.no')}
                  </span>
                </label>
              </div>
            </div>
            <div className={`${styles.inputRadio} ${styles.isPa}`}>
              <div className="input-radio">
                <label htmlFor="yesNewsletter">
                  <input
                    {...register('newsletter', {
                      required: false,
                    })}
                    type="radio"
                    name="newsletter"
                    value="1"
                    id="yesNewsletter"
                    defaultChecked
                  />
                  <span className="input-list-label">
                    {t('forms:placeholder.yes')}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/*newsletter === '1' && (
          <div className={`${styles.inputWrapperContainer} ${styles.privacy}`}>
            <div className={styles.inputWrapper}>
              <div className={`${styles.inputRadio} ${styles.isPa}`}>
                <div className="input-checkbox">
                  <label htmlFor="privacy">
                    <input
                      {...register('privacy', {
                        required:
                          newsletter === '1'
                            ? t('forms:errorsState.required.generic')
                            : false,
                      })}
                      type="checkbox"
                      name="privacy"
                      value="1"
                      id="privacy"
                    />
                    <span
                      className={`${styles.privacyPolicy} input-list-label`}
                    >
                      <p>
                        {
                          <Trans
                            defaults={t(
                              'forms:placeholder.privacyAgreementText'
                            )}
                            components={{
                              link1: (
                                <a
                                  target="_self"
                                  rel="noreferrer"
                                  href={t('links:nanosystems.privacy')}
                                >
                                  ''
                                </a>
                              ),
                            }}
                          />
                        }
                      </p>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              {errors.privacy && (
                <small className={styles.error}>
                  {errors.privacy?.message as any}
                </small>
              )}
            </div>
          </div>
        )*/}

        <Button
          disabled={
            userInfoPhase3?.city === city &&
            userInfoPhase3?.zipCode === zipCode &&
            userInfoPhase3?.streetAndNumber === streetAndNumber &&
            userInfoPhase3?.tel === tel &&
            userInfoPhase3?.adminEmail === adminEmail &&
            userInfoPhase3?.newsletter === newsletter &&
            userInfoPhase3?.codiceConcessionario === codiceConcessionario
          }
        >
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner isWhite isSmall />
            </div>
          ) : (
            t('forms:update')
          )}
        </Button>
      </form>
      {isError && (
        <p className={styles.error}>{t('forms:errorsState.general.submit')}</p>
      )}
    </div>
  );
}

import classNames from 'classnames';
import * as React from 'react';
import styles from './styles.module.scss';
type Props = {
  pages: number;
  currentPage: number;
  onChangeFn: (num: number) => void;
};

export default function Pagination({
  pages = 0,
  currentPage,
  onChangeFn,
}: Props) {
  const renderItem = React.useCallback(
    (_: unknown, index: number) => (
      <div
        key={index}
        onClick={() => onChangeFn(index + 1)}
        className={classNames(styles.element, {
          [styles.active]: currentPage === index + 1,
        })}
      >
        {index + 1}
      </div>
    ),
    [currentPage, onChangeFn]
  );

  return (
    <div className={styles.container}>
      {Boolean(currentPage) && currentPage > 1 && (
        <div
          onClick={() => onChangeFn(currentPage - 1)}
          className={classNames(styles.element, styles.prev)}
        >
          <span className="icon-caret-down"></span>
        </div>
      )}
      {[...new Array(pages)].map(renderItem)}
      {currentPage < pages && (
        <div
          onClick={() => onChangeFn(currentPage + 1)}
          className={classNames(styles.element, styles.next)}
        >
          <span className="icon-caret-down"></span>
        </div>
      )}
    </div>
  );
}

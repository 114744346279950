/* eslint-disable @typescript-eslint/no-unused-vars */
import { registerUser } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { UserSignupInfo } from '@utils/types/user';

import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

const KEY = 'user-register';

export const useRegister = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const data = useMutation<any, string[], UserSignupInfo>(
    [KEY],
    async (data: UserSignupInfo): Promise<any> => {
      await registerUser(currentLanguage, data);
    },
    {
      onError,
      onSuccess,
    }
  );

  return {
    isLoading: data?.isLoading,
    mutate: data.mutate,
    isError: data?.isError,
    error: data?.error,
  };
};

import { useMutation } from '@tanstack/react-query';
import { editUserNote } from '@services/api';
import { noop } from 'lodash';
import { IUserNoteRequest } from '@utils/types/licenses';
import { useTranslation } from 'react-i18next';

export const KEY = 'edit-user-note';

export const useEditUserNote = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<void, Error, IUserNoteRequest>(
      [KEY],
      async ({ id, note }: IUserNoteRequest): Promise<void> => {
        return await editUserNote(currentLanguage, id, note);
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

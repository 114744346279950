import Modal from '@components/Modal';
import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import useGetUserInfo from '@hooks/useUserInfo';
import { UserContext } from '@contexts/UserContext';
import React from 'react';
import Button, { BUTTON_SIZE, BUTTON_STYLE } from '@components/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  expirationData: string;
  id: string;
  activationDate: string;
  showSuspendedMessage: boolean;
  note: string;
  daysLeft: number | undefined;
  suspendFn: () => void;
  reactivateFn: () => void;
  isLoadingDeactivateAutoRenew: boolean;
};
export default function ModalSuspendAutoRenew({
  note,
  isOpen,
  onClose,
  title,
  expirationData,
  activationDate,
  showSuspendedMessage,
  id,
  daysLeft,
  isLoadingDeactivateAutoRenew,
  suspendFn,
  reactivateFn,
}: Props) {
  const { t } = useTranslation(['license']);

  const { isLoggedIn } = React.useContext(UserContext);
  const { data } = useGetUserInfo(isLoggedIn);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!showSuspendedMessage ? (
        <>
          <div className={styles.header}>
            <h2>{t('license:suspendAutoRenew')}</h2>
            <p>
              {t('license:suspendAutoRenewMessage', {
                name: data?.username,
                daysLeft,
              })}
            </p>
          </div>

          <div className={styles.content}>
            <p className={styles.title}>
              {t('license:yourSubscription').toUpperCase()}
            </p>
            <p
              className={styles.productName}
              dangerouslySetInnerHTML={{ __html: title }}
            ></p>
            <p className={styles.note}>{note}</p>

            <div className={styles.details}>
              <div>
                <span>{t('license:code')}</span>
                <span>{id}</span>
              </div>
              <div>
                <span>{t('license:activation')}</span>
                <span>{activationDate}</span>
              </div>
              <div>
                <span>{t('license:expire')}</span>
                <span>{expirationData}</span>
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              fullWidth
              onClick={onClose}
              size={BUTTON_SIZE.MEDIUM}
              styleBtn={BUTTON_STYLE.PRIMARY}
            >
              {t('license:keepAutoRenew')}
            </Button>

            <Button
              fullWidth
              onClick={suspendFn}
              size={BUTTON_SIZE.MEDIUM}
              styleBtn={BUTTON_STYLE.SECONDARY}
              isLoading={isLoadingDeactivateAutoRenew}
            >
              {t('license:suspendNowAutoRenew')}
            </Button>
          </div>
        </>
      ) : (
        <div className={`${styles.header} ${styles.suspend}`}>
          <h2>{t('license:autoRenewSuspended')}</h2>
          <p>
            <Trans
              defaults={t('license:reactivateAutoRenewMessage', {
                daysLeft,
              })}
              components={{
                link1: <span onClick={reactivateFn}></span>,
              }}
            />
          </p>
        </div>
      )}
    </Modal>
  );
}

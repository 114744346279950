export enum RENEW_STATUS {
  NOT_RENEWABLE = 'NOT_RENEWABLE',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  AGREEMENT_ISSUE = 'AGREEMENT_ISSUE',
}
export enum LICENSE_FILTERS {
  ALL = 'all',
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
  TO_EXPIRE = 'to_expire',
  ARCHIVE = 'archive',
  AUTO_RENEW = 'auto_renew',
}

export enum LICENSE_STATUS {
  // se la licenza è attiva
  ACTIVE = 'ACTIVE',
  //  se la licenza non è attiva
  NON_ACTIVE = 'NON_ACTIVE',
  //se la licenza non è attiva ed è scaduta
  EXPIRED = 'EXPIRED',
  // se la licenza non è attiva ed è stata bloccata dagli admin
  BLOCKED = 'BLOCKED',
  // boh non sò cosa sia successo
  UNKNOWN = 'UNKNOWN',
}

export enum SORT_ORDER_LICENSE {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SORT_FIELD_LICENSE {
  ID = 'id',
  PURCHASE_ID = 'purchase_id',
  REGISTRATION_TIME = 'registration_time',
  NAME = 'name',
  ACTIVATION_CODE = 'activation_code',
  CONTROLS = 'controls',
  ACTIVE = 'active',
  EXPIRE_DATE = 'expire_date',
}

export enum SORT_FIELD_PURCHASE {
  ID = 'id',
  PURCHASE_DATE = 'purchase_date',
  AMOUNT_NO_VAT = 'amount_no_vat',
  AMOUNT = 'amount',
  PAY_TYPE = 'pay_type',
}

export type ILicense = {
  id: string;
  activationStatus: LICENSE_STATUS;
  updatedLicenseId: number;
  activationDate: string;
  isActive: boolean;
  expirationDate?: string;
  type: string;
  title: string;
  userNotes: string;
  renewableStatus: RENEW_STATUS;
  machineCode: string;
  activationCode: string;
  isUpgradable: boolean;
  paymentId: number;
  isRenewable: boolean;
  isProfessional: boolean;
};

export type ILicenseCSVRequest = {
  query: string;
  filters: LICENSE_FILTERS;
  paymentId?: number;
};

export type IUserNoteRequest = {
  id: string;
  note: string;
};

export type IActivationCodeRequest = {
  id: string;
  machineCode?: string;
};

export type ILicenseResponse = {
  licenses: ILicense[];
  maxPage: number;
  currentPage: number;
  itemsPerPage: number;
};

export type ILicenseRequest = {
  query: string;
  paymentId?: number;
  filters: LICENSE_FILTERS;
  currentPage: number;
  itemsPerPage: number;
  sortField: SORT_FIELD_LICENSE;
  sortDir: SORT_ORDER_LICENSE;
};

export type IUpgradeProductForEvalPriceResponse = {
  price: number;
};

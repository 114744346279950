import { useMutation } from '@tanstack/react-query';
import { getLicenseCSV } from '@services/api';
import { noop } from 'lodash';
import { ILicenseCSVRequest } from '@utils/types/licenses';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-license-csv';

export const useGetLicenseCSV = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<string, Error, ILicenseCSVRequest>(
      [KEY],
      async ({
        query,
        filters,
        paymentId,
      }: ILicenseCSVRequest): Promise<string> => {
        return await getLicenseCSV(currentLanguage, query, filters, paymentId);
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

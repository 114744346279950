import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';

import { CartContext } from '@contexts/CartContext';

import {
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION_ID,
} from '@utils/types/store';

import useUpgradeRenewProduct from '@hooks/useUpgradeRenewProduct';

import SupremoSoloProductCard from '@components/SupremoSoloProductCard';
import SupremoBusinessProductCard from '@components/SupremoBusinessProductCard';
import SupremoProfessionalProductCard from '@components/SupremoProfessionalProductCard';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { BUTTON_STYLE } from '@components/Button';
import { SUBSCRIPTION_DURATION } from '@services/normalize';
import UraniumBaseProductCard from '@components/UraniumBaseProductCard';
import UraniumProProductCard from '@components/UraniumProProductCard';
import UraniumProVirtualProductCard from '@components/UraniumProVirtualProductCard';
import UraniumGoldProductCard from '@components/UraniumGoldProductCard';
import SupremoConsoleProductCard from '@components/SupremoConsoleProductCard';

const UpgradeRenew = () => {
  const { data, isSuccess, isError } = useUpgradeRenewProduct();
  const [showPromoDescUranium, setShowPromoDescUranium] = React.useState(false);
  const [subscription, setSubscription] = React.useState<number>(3);
  const [downgradesCount, setDowngradesCount] = React.useState<number>(0);
  const [showDowngrades, setShowDowngrades] = React.useState<boolean>(false);
  const { setCartItems } = React.useContext(CartContext);

  const showYearlyDataFn = React.useCallback(() => {
    setSubscription(12);
  }, []);

  const showQuarterlyDataFn = React.useCallback(() => {
    setSubscription(3);
  }, []);

  const showBiennialDataFn = React.useCallback(() => {
    setSubscription(24);
  }, []);

  const showTriennialDataFn = React.useCallback(() => {
    setSubscription(36);
  }, []);

  const [showPromoTriennial, setShowPromoTriennial] = React.useState<boolean>(false);
  const [showPromoBiennial, setShowPromoBiennial] = React.useState<boolean>(false);
  const [showPromoYearly, setShowPromoYearly] = React.useState<boolean>(false);
  const [showPromoQuarterly, setShowPromoQuarterly] = React.useState<boolean>(false);

  const checkForPromo = React.useCallback(() => {
    if (data) {
      if (subscription === 36) {
        setShowPromoTriennial(
          data?.products?.triennial?.some((el: ProductVersion) => el?.promoDesc)
        );
      } else if (subscription === 24) {
        setShowPromoBiennial(
          data?.products?.biennial?.some((el: ProductVersion) => el?.promoDesc)
        );
      } else if (subscription === 12) {
        setShowPromoYearly(
          data?.products?.yearly?.some((el: ProductVersion) => el?.promoDesc)
        );
      } else {
        setShowPromoQuarterly(
          data?.products?.quarterly?.some((el: ProductVersion) => el?.promoDesc)
        );
      }
    }
  }, [subscription, data]);

  const { t } = useTranslation([
    'common',
    'routes',
    'supremoControl',
    'license',
  ]);
  const location = useLocation();

  const navigate = useNavigate();

  const { query } = queryString?.parseUrl(location.search);
  const licenseId: any = query?.licenseId ?? null;

  React.useEffect(() => {
    if (data && isSuccess && data?.software === PRODUCT_TYPE.SUPREMO) {
      const quarterlyLength = data?.products?.quarterly.length > 0;
      const yearlyLength = data?.products?.yearly.length > 0;
      const biennialLength = data?.products?.biennial.length > 0;
      const triennialLength = data?.products?.triennial.length > 0;

      const productsAvailable = [
        { value: quarterlyLength, id: 3 },
        { value: yearlyLength, id: 12 },
        { value: biennialLength, id: 24 },
        { value: triennialLength, id: 36 }
      ];
      const firstElementToShow = productsAvailable.find(
        (el) => el.value === true
      );

      setSubscription(firstElementToShow?.id as number);
      setDowngradesCount(
        data?.products?.quarterly.filter((p: ProductVersion) => p.isDowngrade === true).length +
        data?.products?.yearly.filter((p: ProductVersion) => p.isDowngrade === true).length +
        data?.products?.biennial.filter((p: ProductVersion) => p.isDowngrade === true).length +
        data?.products?.triennial.filter((p: ProductVersion) => p.isDowngrade === true).length
      );

      if (downgradesCount > 0) {
        setShowDowngrades(data?.type === 'UPGRADE');
      }
    } else if (data && isSuccess && data?.software === PRODUCT_TYPE.URANIUM) {
      const onlyClassicUranium = data.products.filter(
        (el: ProductVersion) =>
          el.id !== PRODUCT_VERSION_ID.AUSILIO &&
          el.id !== PRODUCT_VERSION_ID.GOLD
      );
      setShowPromoDescUranium(
        onlyClassicUranium.some((el: ProductVersion) => el?.promoDesc)
      );
    }
  }, [data, isSuccess]);

  React.useEffect(() => {
    if (data) {
      checkForPromo();
    }
  }, [checkForPromo, data]);

  React.useEffect(() => {
    if (!Boolean(licenseId) || isError) {
      navigate(t('routes:home'));
    }
  }, [licenseId, isError, navigate, t]);

  const renderSupremoProductItem = React.useCallback(
    (renewOrUpgrade: string) => (product: ProductVersion) => {
      if (
        product?.id === PRODUCT_VERSION_ID.SOLO_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.SOLO_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.SOLO_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.SOLO_TWO_YEAR
      ) {
        return (
          <SupremoSoloProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                  ? showPromoBiennial
                  : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                    ? showPromoYearly
                    : showPromoQuarterly
            }
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.BUSINESS_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.BUSINESS_TWO_YEAR
      ) {
        return (
          <SupremoBusinessProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                  ? showPromoBiennial
                  : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                    ? showPromoYearly
                    : showPromoQuarterly
            }
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.PRO_THREE_MONTHS ||
        product?.id === PRODUCT_VERSION_ID.PRO_ONE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.PRO_THREE_YEAR ||
        product?.id === PRODUCT_VERSION_ID.PRO_TWO_YEAR
      ) {
        return (
          <SupremoProfessionalProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={
              subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                ? showPromoTriennial
                : subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                  ? showPromoBiennial
                  : subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                    ? showPromoYearly
                    : showPromoQuarterly
            }
          />
        );
      }
    },
    [
      setCartItems,
      showPromoTriennial,
      showPromoBiennial,
      subscription,
      showPromoQuarterly,
      showPromoYearly,
    ]
  );

  const renderUraniumProductItem = React.useCallback(
    (renewOrUpgrade: string) => (product: ProductVersion, key: number) => {
      if (product?.id === PRODUCT_VERSION_ID.BASE) {
        return (
          <UraniumBaseProductCard
            handleAddCart={setCartItems}
            key={key}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={showPromoDescUranium}
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_TAPE

        // CHECK IF ONLY "URANIUM UPGRADE BASE TO PRO TAPE" (ID: 11) PRODUCT IS
        // IN PRODUCTS LIST, TO AVOID SHOWING THE SAME CARD SEVERAL TIMES. IN THIS
        // WAY WE WOULD HAVE A SINGLE CARD THAT WILL TAKE THE 3 'URANIUM PRO' PRODUCTS
        // AS PROPS AND WILL SHOW THEM DYNAMICALLY WHEN THE SELECT IS CHANGED
      ) {
        const onlyProVersion =
          data &&
          isSuccess &&
          data.products.filter(
            (el: any) =>
              el.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_TAPE ||
              el.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_DB ||
              el.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_SHADOW
          );

        return (
          <UraniumProProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            renewOrUpgrade={renewOrUpgrade}
            onlyProVersionProducts={onlyProVersion}
            showPromoDesc={showPromoDescUranium}
          />
        );
      }
      if (
        product?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_VIRTUAL ||
        product?.id === PRODUCT_VERSION_ID.UPGRADE_PRO_SHADOW_TO_PRO_VIRTUAL
      ) {
        return (
          <UraniumProVirtualProductCard
            handleAddCart={setCartItems}
            key={product?.id}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={showPromoDescUranium}
          />
        );
      }

      if (
        // product?.id === PRODUCT_VERSION_ID.GOLD ||
        product?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_GOLD ||
        product?.id === PRODUCT_VERSION_ID.UPGRADE_PRO_TO_GOLD ||
        product?.id === PRODUCT_VERSION_ID.UPGRADE_VIRTUAL_TO_GOLD
      ) {
        return (
          <UraniumGoldProductCard
            windowWidth={1}
            productDetails={product}
            handleAddCart={setCartItems}
            key={key}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={showPromoDescUranium}
          />
        );
      }
      if (product?.id === PRODUCT_VERSION_ID.AUSILIO) {
        return (
          <SupremoConsoleProductCard
            windowWidth={1}
            handleAddCart={setCartItems}
            key={key}
            productDetails={product}
            renewOrUpgrade={renewOrUpgrade}
            showPromoDesc={showPromoDescUranium}
          />
        );
      }
    },
    [setCartItems, data, showPromoDescUranium, isSuccess]
  );

  const titleForSupremo = data && data?.type === 'UPGRADE' ? 'doUpgrade' : 'doRenew';

  const formattedTitle = data && isSuccess && data?.titlePage.replace(/<\/?[^>]+(>|$)/g, '');
  const renewOrUpgrade = data && isSuccess && data?.type;

  let quarterlyProducts, yearlyProducts, biennialProducts, triennialProducts;
  if (data && isSuccess && data?.software === PRODUCT_TYPE.SUPREMO) {
    quarterlyProducts = showDowngrades ? data?.products?.quarterly : data?.products?.quarterly.filter((p: ProductVersion) => p.isDowngrade === false);
    yearlyProducts = showDowngrades ? data?.products?.yearly : data?.products?.yearly.filter((p: ProductVersion) => p.isDowngrade === false);
    biennialProducts = showDowngrades ? data?.products?.biennial : data?.products?.biennial.filter((p: ProductVersion) => p.isDowngrade === false);
    triennialProducts = showDowngrades ? data?.products?.triennial : data?.products?.triennial.filter((p: ProductVersion) => p.isDowngrade === false);
  }

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection isCentered>
          <div className={styles.header}>
            <div>
              {data?.software === PRODUCT_TYPE.SUPREMO ? (
                <>
                  <h1>
                    {t(`license:supremoUpgrade.${titleForSupremo}`)}
                  </h1>
                  <p><strong>{formattedTitle}</strong></p>
                </>
              ) : data?.software === PRODUCT_TYPE.URANIUM ? (
                <>
                  <h1>
                    {t('license:uraniumUpgrade.doUpgrade')}
                  </h1>
                  <p><strong>{formattedTitle}</strong></p>
                </>
              ) : (
                <>
                  <h1>
                    {t('license:consoleUpgrade.doRenew')}
                  </h1>
                  <p><strong>{formattedTitle}</strong></p>
                </>
              )}
            </div>
            <div className={styles.selectDuration}>
              {data?.software === PRODUCT_TYPE.SUPREMO && (
                <>
                  {data?.products?.quarterly.length > 0 && (
                    <Button
                      styleBtn={
                        subscription === SUBSCRIPTION_DURATION.THREE_MONTH
                          ? BUTTON_STYLE.PRIMARY
                          : BUTTON_STYLE.SECONDARY
                      }
                      onClick={showQuarterlyDataFn}
                    >
                      {t('common:shops.quarterly')}
                    </Button>
                  )}
                  {data?.products?.yearly.length > 0 && (
                    <Button
                      styleBtn={
                        subscription === SUBSCRIPTION_DURATION.ONE_YEAR
                          ? BUTTON_STYLE.PRIMARY
                          : BUTTON_STYLE.SECONDARY
                      }
                      onClick={showYearlyDataFn}
                    >
                      {t('common:shops.yearly')}
                    </Button>
                  )}
                  {data?.products?.biennial.length > 0 && (
                    <Button
                      styleBtn={
                        subscription === SUBSCRIPTION_DURATION.TWO_YEAR
                          ? BUTTON_STYLE.PRIMARY
                          : BUTTON_STYLE.SECONDARY
                      }
                      onClick={showBiennialDataFn}
                    >
                      {t('common:shops.biennial')}
                    </Button>
                  )}
                  {data?.products?.triennial.length > 0 && (
                    <Button
                      styleBtn={
                        subscription === SUBSCRIPTION_DURATION.THREE_YEAR
                          ? BUTTON_STYLE.PRIMARY
                          : BUTTON_STYLE.SECONDARY
                      }
                      onClick={showTriennialDataFn}
                    >
                      {t('common:shops.triennial')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          {data?.software === PRODUCT_TYPE.SUPREMO && (
            <>
              <div
                className={`${styles.cardWrap} ${subscription === SUBSCRIPTION_DURATION.THREE_YEAR &&
                  triennialProducts?.length <= 3
                  ? styles[`grid${triennialProducts?.length}`]
                  : subscription === SUBSCRIPTION_DURATION.TWO_YEAR &&
                    biennialProducts?.length <= 3
                    ? styles[`grid${biennialProducts?.length}`]
                    : subscription === SUBSCRIPTION_DURATION.ONE_YEAR &&
                      yearlyProducts?.length <= 3
                      ? styles[`grid${yearlyProducts?.length}`]
                      : subscription === SUBSCRIPTION_DURATION.THREE_MONTH &&
                        quarterlyProducts?.length <= 3
                        ? styles[`grid${quarterlyProducts?.length}`]
                        : styles.grid3
                  }
             `}
              >
                {isSuccess && (
                  <>
                    {subscription === 3 ? quarterlyProducts?.map(renderSupremoProductItem(renewOrUpgrade))
                      : subscription === 12 ? yearlyProducts?.map(renderSupremoProductItem(renewOrUpgrade))
                        : subscription === 24 ? biennialProducts?.map(renderSupremoProductItem(renewOrUpgrade))
                          : triennialProducts?.map(renderSupremoProductItem(renewOrUpgrade))}
                  </>
                )}
              </div>
              {renewOrUpgrade === 'RENEW' && downgradesCount > 0 &&
                <span className={styles.showDowngradesBtn} onClick={() => setShowDowngrades(!showDowngrades)}>
                  {t(showDowngrades ? 'common:shops.hideDowngrades' : 'common:shops.showDowngrades')}
                </span>}
            </>
          )}

          {(data?.software === PRODUCT_TYPE.URANIUM || data?.software === PRODUCT_TYPE.CONSOLE) && (
            <div className={`${styles.cardWrap}  ${data?.products?.length === 1
              ? styles.grid1
              : data?.products?.length === 2
                ? styles.grid2
                : styles.grid3
              }`}
            >
              {data && isSuccess && data?.products?.map(renderUraniumProductItem(renewOrUpgrade))}
            </div>
          )}
        </LayoutSection>
      </LayoutPage>
    </>
  );
};

export default UpgradeRenew;

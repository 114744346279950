import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): { copiedText: CopiedValue; copy: CopyFn } {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const { t } = useTranslation(['common']);
  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn(t('common:clipboardNotSupported'));
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn(t('common:copyFailed'), error);
      setCopiedText(null);
      return false;
    }
  };

  return { copiedText, copy };
}

export default useCopyToClipboard;

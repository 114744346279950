import ButtonTab from '@components/ButtonTab';
import Select from '@components/Select';
import Spinner from '@components/Spinner';
import { useGetLicenseCSV } from '@hooks/useGetLicenseCSV';
import {
  LICENSE_FILTERS,
  SORT_FIELD_LICENSE,
  SORT_ORDER_LICENSE,
} from '@utils/types/licenses';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import useGetLicenseToRenew from '@hooks/useGetLicenseToRenew';

type Props = {
  currentFilter: string;
  currentSortDir: any;
  currentSortField: any;
  currentQuery: string;
  currentPaymentId: string;
  onChangeFilterFn: (filter: LICENSE_FILTERS) => void;
  onChangeSortFieldFn: (orderType: SORT_FIELD_LICENSE) => void;
  onChangeSortDirFn: (orderTime: SORT_ORDER_LICENSE) => void;
};

export default function Filters({
  currentFilter,
  currentSortDir,
  currentSortField,
  currentQuery,
  currentPaymentId,
  onChangeFilterFn,
  onChangeSortFieldFn,
  onChangeSortDirFn,
}: Props) {
  const { t } = useTranslation(['license']);

  const ordersField = [
    { id: SORT_FIELD_LICENSE.ID, label: t('license:filters.id') },
    {
      id: SORT_FIELD_LICENSE.ACTIVATION_CODE,
      label: t('license:filters.activationCode'),
    },
    { id: SORT_FIELD_LICENSE.ACTIVE, label: t('license:filters.active') },
    { id: SORT_FIELD_LICENSE.CONTROLS, label: t('license:filters.controls') },
    { id: SORT_FIELD_LICENSE.NAME, label: t('license:filters.name') },
    /*{
      id: SORT_FIELD_LICENSE.PURCHASE_ID,
      label: t('license:filters.purchaseId'),
    },*/
    {
      id: SORT_FIELD_LICENSE.REGISTRATION_TIME,
      label: t('license:filters.registrationTime'),
    },
    {
      id: SORT_FIELD_LICENSE.EXPIRE_DATE,
      label: t('license:filters.expireDate'),
    },
  ].sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else if (a.label > b.label) {
      return 1;
    } else {
      return 0;
    }
  });
  const ordersDir = [
    { id: SORT_ORDER_LICENSE.DESC, label: t('license:filters.desc') },
    { id: SORT_ORDER_LICENSE.ASC, label: t('license:filters.asc') },
  ];

  const onChooseOrderType = React.useCallback(
    ({ id }: { id: string }) => {
      onChangeSortFieldFn(id as SORT_FIELD_LICENSE);
    },
    [onChangeSortFieldFn]
  );

  const onChooseOrderTime = React.useCallback(
    ({ id }: { id: string }) => {
      onChangeSortDirFn(id as SORT_ORDER_LICENSE);
    },
    [onChangeSortDirFn]
  );

  const {
    mutate: onGetLicenseCSV,
    isLoading: isLoadingOnGetLicenseCSV,
    error: errorOnGetLicenseCSV,
  } = useGetLicenseCSV((data) => onSuccessGetLicenseCSV(data));

  const onSuccessGetLicenseCSV = React.useCallback((urlCSV: string) => {
    urlCSV && window.open(urlCSV, '_blank');
  }, []);

  const { data: licenseToRenew, isFetching } = useGetLicenseToRenew(true);

  return (
    <div className={styles.container}>
      <div className={styles.topSide}>
        <div className={styles.buttons}>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.ALL)}
            isActive={currentFilter === LICENSE_FILTERS.ALL}
          >
            {t('license:filters.all')}
          </ButtonTab>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.ACTIVE)}
            isActive={currentFilter === LICENSE_FILTERS.ACTIVE}
          >
            {t('license:filters.active')}
          </ButtonTab>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.AUTO_RENEW)}
            isActive={currentFilter === LICENSE_FILTERS.AUTO_RENEW}
          >
            {t('license:filters.autoRenewal')}
          </ButtonTab>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.NOT_ACTIVE)}
            isActive={currentFilter === LICENSE_FILTERS.NOT_ACTIVE}
          >
            {t('license:filters.toActivate')}
          </ButtonTab>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.TO_EXPIRE)}
            isActive={currentFilter === LICENSE_FILTERS.TO_EXPIRE}
          >
            {t('license:filters.toRenew')}{' '}
            {!isFetching && licenseToRenew !== 0 ? (
              <span className={styles.licenseToRenew}>{licenseToRenew}</span>
            ) : (
              ''
            )}
          </ButtonTab>
          <ButtonTab
            onClick={() => onChangeFilterFn(LICENSE_FILTERS.ARCHIVE)}
            isActive={currentFilter === LICENSE_FILTERS.ARCHIVE}
          >
            {t('license:filters.history')}
          </ButtonTab>
        </div>

        <div
          className={styles.exportCsv}
          onClick={() =>
            onGetLicenseCSV({
              query: currentQuery ?? '',
              filters: currentFilter as LICENSE_FILTERS,
              paymentId: Number(currentPaymentId),
            })
          }
        >
          {isLoadingOnGetLicenseCSV ? (
            <div className={styles.spinnerWrap}>
              <Spinner isGrey isSmall />
            </div>
          ) : (
            <>
              <span className="icon-microsoft-excel-logo"></span>
              <p>{t('license:filters.exportCsv')}</p>
            </>
          )}
          {errorOnGetLicenseCSV && (
            <div className={styles.errors}>
              <p>{errorOnGetLicenseCSV.message}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bottomSide}>
        <p>{t('license:filters.order')}</p>
        <div className={styles.selectWrapper}>
          <Select
            onChange={onChooseOrderType}
            options={ordersField?.map((order) => ({
              ...order,
              label: order?.label,
            }))}
            value={{
              id: currentSortField ?? '',
              label:
                ordersField?.find((el) => el.id === currentSortField)?.label ??
                '',
            }}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Select
            onChange={onChooseOrderTime}
            options={ordersDir?.map((order) => ({
              ...order,
              label: order?.label,
            }))}
            value={{
              id: currentSortDir ?? '',
              label:
                ordersDir?.find((el) => el.id === currentSortDir)?.label ?? '',
            }}
          />
        </div>
      </div>
    </div>
  );
}

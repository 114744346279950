import * as React from 'react';
import styles from './styles.module.scss';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { useTranslation } from 'react-i18next';

type Props = {
  children: string;
  style?: any;
};
export default function CopyToClipboard({ children, style }: Props) {
  const { copy } = useCopyToClipboard();
  const { t } = useTranslation(['common']);
  const [copied, setCopied] = React.useState(false);
  const onCopyFN = React.useCallback(() => {
    copy(children);
    setCopied(true);
  }, [copy, children]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div className={styles.container}>
      <p style={style}>{children}</p>
      <span onClick={onCopyFN} className="icon-copy"></span>
      {copied && <p>{t('common:copied').toUpperCase()}</p>}
    </div>
  );
}

import React from 'react';

import styles from './styles.module.scss';

export type IBadge = {
  type?: BADGE_TYPE;
  children: React.ReactNode;
  showLeadingStatus?: boolean;
  showTrailingStatus?: boolean;
  status?: BADGE_STATUS;
  onClick?: () => void;
};

export enum BADGE_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREEN = 'green',
  GREY = 'grey',
  YELLOW = 'yellow',
}

export enum BADGE_STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  NEW = 'new',
}

const Badge = ({
  onClick,
  status = BADGE_STATUS.SUCCESS,
  type = BADGE_TYPE.PRIMARY,
  showLeadingStatus,
  showTrailingStatus,
  children,
}: IBadge) => {
  const badgeClassName = `${styles.container} ${styles[type]}`;

  return (
    <span onClick={onClick} className={badgeClassName}>
      {showLeadingStatus && (
        <span
          className={`${styles.status} ${styles.leading} ${styles[status]}`}
        ></span>
      )}
      {children}
      {showTrailingStatus && (
        <span
          className={`${styles.status}  ${styles.trailing} ${styles[status]}`}
        ></span>
      )}
    </span>
  );
};

export default Badge;

import * as React from 'react';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import CartItem from '@components/CartItem';
import UpsellingBanner from '@components/UpsellingBanner';
import { CartContext } from '@contexts/CartContext';
import { CartItemType, PRODUCT_TYPE } from '@utils/types/store';

import SkeletonBasic from '@components/SkeletonBasic';
import useCartProducts from '@hooks/useCartProducts';
import { UserContext } from '@contexts/UserContext';
import { Link } from 'react-router-dom';
import SyncLoader from '@components/SyncLoader';
import Button, { BUTTON_STYLE } from '@components/Button';
import Price from '@components/Price';

export default function CartScreen() {
  const { t } = useTranslation(['cart', 'routes', 'common']);
  const { data, isLoadingCheckoutFromApi, isLoadingGettingProduct } = useCartProducts();

  const navigate = useNavigate();
  const { cartItems, onDeleteCartItem, onUpdateQuantity } = React.useContext(CartContext);
  const { isLoggedIn, registrationCompleted } = React.useContext(UserContext);

  const shouldShowSupremoBanner = cartItems.find(
    (c) => c.productType === PRODUCT_TYPE.SUPREMO
  );
  const shouldShowUraniumBanner = cartItems.find(
    (c) => c.productType === PRODUCT_TYPE.URANIUM
  );

  const onDeleteAllItemsFn = React.useCallback(
    (id: string) => {
      onDeleteCartItem(id);
    },
    [onDeleteCartItem]
  );

  const proceed = React.useCallback(() => {
    if (isLoggedIn) {
      if (registrationCompleted) {
        if (data?.products && data?.products?.length > 0) {
          navigate(t('routes:checkout'));
        }
      } else {
        navigate(t('routes:signup'));
      }
    } else {
      navigate(t('routes:signinPayment'));
    }

  }, [navigate, t, isLoggedIn, registrationCompleted, data]);

  const renderItems = React.useCallback(
    (item: CartItemType, key: number) => {
      return (
        <div key={key} className={styles.item}>
          <CartItem
            title={item.title}
            fromLicense={item?.fromLicense}
            description={item.description}
            price={item?.price}
            quantity={item.quantity}
            onAddItem={onUpdateQuantity(item?.localId)}
            onChangeItem={onUpdateQuantity(item?.localId)}
            onRemoveItem={onUpdateQuantity(item.localId)}
            onDeleteAllItems={() => onDeleteAllItemsFn(item?.localId)}
            hideCounter={item?.hideCounter}
            renewOrUpgrade={item?.renewOrUpgrade}
            subProducts={item?.subProducts}
          />
        </div>
      );
    },
    [onUpdateQuantity, onDeleteAllItemsFn]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <LayoutSection hasNarrowWrap>
          <h2 className={styles.title}>{t('cart:title')}</h2>

          <div className={styles.content}>
            {cartItems.length > 0 ? (
              <>
                {isLoadingGettingProduct ? (
                  <div
                    style={{
                      marginTop: -24,
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SyncLoader size={10} color={'rgba(0,0,0,0.2)'} />
                  </div>
                ) : (
                  data?.products?.map(renderItems)
                )}
                {!isLoadingGettingProduct && (
                  <div className={styles.item}>
                    {shouldShowSupremoBanner && shouldShowUraniumBanner ? (
                      <></>
                    ) : (
                      <>
                        {!shouldShowSupremoBanner ? (
                          <UpsellingBanner
                            title={t('cart:upsellingBanner.supremo.title')}
                            description={t(
                              'cart:upsellingBanner.supremo.description'
                            )}
                            goTo={t('routes:supremoControl')}
                          />
                        ) : (
                          <UpsellingBanner
                            title={t('cart:upsellingBanner.uranium.title')}
                            description={t(
                              'cart:upsellingBanner.uranium.description'
                            )}
                            goTo={t('routes:uraniumBackup')}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className={styles.summary}>
                  <div className={styles.summaryTitle}>
                    <p>{t('cart:summary.summary')}</p>
                  </div>
                  {isLoggedIn && registrationCompleted ? (
                    <>
                      {(!(data?.total?.totalWithoutTax && data?.total?.totalWithoutTax !== '0') || (data?.total?.discounts && data?.total?.discounts?.length > 0)) && (
                        <div className={styles.summaryRow}>
                          <p>{t('cart:summary.articles')}</p>
                          {isLoadingCheckoutFromApi ? (
                            <SkeletonBasic width={150} height={19} />
                          ) : (
                            <p>
                              <strong>
                                {data?.total?.totalCount} {data?.total?.currency}
                              </strong>
                            </p>
                          )}
                        </div>
                      )}

                      {data?.total?.discounts?.map((discount, key) => (
                        <div key={key} className={styles.summaryRow}>
                          <p>
                            {discount?.description} {discount?.percent}
                          </p>
                          <p>
                            <strong>
                              {discount.total} {data?.total?.currency}
                            </strong>
                          </p>
                        </div>
                      ))}

                      {data?.total?.totalWithoutTax &&
                        data?.total?.totalWithoutTax !== '0' && (
                          <div className={styles.summaryRow}>
                            <p>{t('cart:summary.totalWithoutTax')}</p>
                            {isLoadingCheckoutFromApi ? (
                              <SkeletonBasic width={120} height={19} />
                            ) : (
                              <p>
                                <strong>
                                  {data?.total?.totalWithoutTax}{' '}
                                  {data?.total?.currency}
                                </strong>
                              </p>
                            )}
                          </div>
                        )}

                      {data?.total?.tax?.map((tax, key) => (
                        <div key={key} className={styles.summaryRow}>
                          {isLoadingCheckoutFromApi ? (
                            <SkeletonBasic width={120} height={19} />
                          ) : (
                            <p>
                              {tax?.description} {tax?.percent}
                            </p>
                          )}

                          {isLoadingCheckoutFromApi ? (
                            <SkeletonBasic width={120} height={19} />
                          ) : (
                            <p>
                              <strong>
                                {tax.total} {data?.total?.currency}
                              </strong>
                            </p>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={styles.signupOrLogin}>
                      <div className={styles.text}>
                        <p>{t(isLoggedIn ? 'cart:summary.completeSignUp' : 'cart:summary.signInOrSignUp')}</p>
                      </div>
                      <Button fullWidth onClick={proceed} styleBtn={BUTTON_STYLE.SECONDARY}>
                        {t(isLoggedIn ? 'cart:summary.completeSignUpBtn' : 'common:navigation.access')}
                      </Button>
                    </div>
                  )}
                  <div className={styles.summaryRowTotal}>
                    <p>{t('cart:summary.total')}</p>
                    {isLoadingCheckoutFromApi && !data?.total?.total ? (
                      <SkeletonBasic width={200} height={31} />
                    ) : (
                      <p>
                        <strong>{data?.total?.total && <Price value={data?.total?.total} />}</strong>
                      </p>
                    )}
                  </div>
                  <button
                    style={{ width: '100%' }}
                    onClick={proceed}
                  >
                    {t('cart:ctaLabel')}
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.containerEmpty}>
                <h2>{t('cart:emptyCart')}</h2>
                <button>
                  <Link to={t('routes:home')}>{t('common:shops.goToShop')}</Link>
                </button>
              </div>
            )}
          </div>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

//import styles from './styles.module.scss';

import Button from '@components/Button';
import Input, { INPUT_SIZE } from '@components/Input';

import { usernameRegex, emailRegex } from '@utils/formUtils';
import { registerPhase1Response } from '@utils/types/user';

type Props = {
  onSubmit: any;
  isLoading: boolean;
  errorsFromApi?: string[] | null;
  userInfoPhase1?: registerPhase1Response | null;
};

export default function AccountForm({
  onSubmit,
  isLoading,
  errorsFromApi,
  userInfoPhase1
}: Props) {
  const { t } = useTranslation(['forms', 'routes']);
  const readOnly = !!userInfoPhase1 && userInfoPhase1?.email.length > 0 && userInfoPhase1?.username.length > 0;

  const {
    watch,
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
    password: boolean;
    username: boolean;
    confirmPassword: boolean;
    confirmEmail: boolean;
  }>({
    email: false,
    password: false,
    username: false,
    confirmPassword: false,
    confirmEmail: false,
  });

  const password = React.useRef({});
  password.current = watch('password', '');

  const email = React.useRef({});
  email.current = watch('email', '');

  React.useEffect(() => {
    if (userInfoPhase1) {
      setValue('email', userInfoPhase1.email, { shouldDirty: true });
      setValue('username', userInfoPhase1.username, { shouldDirty: true });
    }
  }, [userInfoPhase1, setValue]);

  const { ref: usernameRef, ...usernameInputProps } = register('username', {
    required: t('forms:errorsState.required.username'),
    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.passwordMinLength'),
    },
    pattern: {
      value: usernameRegex,
      message: t('forms:errorsState.invalid.username'),
    }
  });

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('forms:errorsState.required.email'),
    pattern: {
      value: emailRegex,
      message: t('forms:errorsState.invalid.email'),
    },
  });

  const { ref: confirmEmailRef, ...confirmEmailInputProps } = register(
    'confirmEmail',
    {
      required: t('forms:errorsState.required.email'),
      validate: (value) =>
        value === email.current || t('forms:errorsState.invalid.emailMismatch'),
      pattern: {
        value: emailRegex,
        message: t('forms:errorsState.invalid.email'),
      },
    }
  );

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('forms:errorsState.required.password'),
    minLength: {
      value: 6,
      message: t('forms:errorsState.invalid.passwordMinLength'),
    },
  });

  const { ref: confirmPasswordRef, ...confirmPasswordInputProps } = register(
    'confirmPassword',
    {
      required: t('forms:errorsState.required.password'),
      validate: (value) => value === password.current || t('forms:errorsState.invalid.passwordMismatch'),
      minLength: {
        value: 6,
        message: t('forms:errorsState.invalid.passwordMinLength'),
      },
    }
  );

  React.useEffect(() => {
    errorsFromApi
      ? errorsFromApi?.forEach((element: string) => {
        const message = element
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (t(`forms:errorsState.errorsFromApi.${element}`) as string)
          : t('forms:errorsState.errorsFromApi.generic');
        setError(element, { type: 'error', message });
      })
      : clearErrors();
  }, [setError, errorsFromApi, t, clearErrors]);

  const onSubmitForm = ({
    email,
    password,
    username,
    confirmPassword,
    confirmEmail,
  }: {
    email: string;
    password: string;
    username: string;
    confirmPassword: string;
    confirmEmail: string;
  }) => {
    setIsDirty({
      email: email.length > 0,
      password: password.length > 0,
      username: username.length > 0,
      confirmPassword: confirmPassword.length > 0,
      confirmEmail: confirmEmail.length > 0,
    });

    const formData = { email, password, username };
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm as any)}>
      <Input
        label={t('forms:label.username')}
        inputSize={INPUT_SIZE.BIG}
        isFullWidth
        hasValue={isDirty?.username ?? false}
        type="text"
        placeholder={t('forms:placeholder.username')}
        inputRef={usernameRef}
        {...usernameInputProps}
        error={errors?.username?.message as any}
        isRequired
        readOnly={readOnly}
      />

      <Input
        label={t('forms:label.email')}
        inputSize={INPUT_SIZE.BIG}
        isFullWidth
        hasValue={isDirty?.email ?? false}
        type="email"
        placeholder={t('forms:placeholder.email')}
        inputRef={emailRef}
        {...emailInputProps}
        error={errors?.email?.message as any}
        isRequired
      />

      {!readOnly && (
        <>
          <Input
            label={t('forms:label.confirmEmail')}
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.confirmEmail ?? false}
            type="email"
            placeholder={t('forms:placeholder.confirmEmail')}
            inputRef={confirmEmailRef}
            {...confirmEmailInputProps}
            error={errors?.confirmEmail?.message as any}
            isRequired
          />

          <Input
            label={t('forms:label.password')}
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.password ?? false}
            type="password"
            placeholder={t('forms:placeholder.password')}
            inputRef={passwordRef}
            {...passwordInputProps}
            error={errors?.password?.message as any}
            isRequired
          />

          <Input
            label={t('forms:label.confirmPassword')}
            inputSize={INPUT_SIZE.BIG}
            isFullWidth
            hasValue={isDirty?.confirmPassword ?? false}
            type="password"
            placeholder={t('forms:placeholder.confirmPassword')}
            inputRef={confirmPasswordRef}
            {...confirmPasswordInputProps}
            error={errors?.confirmPassword?.message as any}
            isRequired
          />

          <Button isLoading={isLoading} fullWidth>
            {t('forms:continue')}
          </Button>
        </>
      )}
    </form>
  );
}

import { PRODUCT_VERSION } from '@utils/types/store';

export const getCssVariable = (variableName: string): string => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    `--${variableName}`
  );
  if (!value) {
    console.log(`${variableName} not found.`);
    return '';
  }
  return value;
};

export const concatStrings = (strings: any, separator = ' ') => {
  return strings.filter(Boolean).join(separator);
};

export function fromIdToProductVersion(id: number): PRODUCT_VERSION {
  switch (id) {
    case 2:
      return PRODUCT_VERSION.BASE;
    case 3:
      return PRODUCT_VERSION.PRO_TAPE;
    case 5:
      return PRODUCT_VERSION.GOLD;
    case 6:
      return PRODUCT_VERSION.PRO_DB;
    case 21:
      return PRODUCT_VERSION.PRO_SHADOW;
    case 23:
      return PRODUCT_VERSION.PRO_VIRTUAL;
    case 27:
    case 28:
      return PRODUCT_VERSION.SOLO;
    case 29:
    case 30:
      return PRODUCT_VERSION.BUSINESS;
    case 31:
    case 32:
      return PRODUCT_VERSION.EXTRA_CONTROL;
    case 63:
    case 64:
      return PRODUCT_VERSION.PRO;
    case 51:
      return PRODUCT_VERSION.AUSILIO;
    default:
      return PRODUCT_VERSION.DEFAULT;
  }
}

export function truncate(input: string, length: number) {
  if (input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getProvinceListFromApi } from '@services/api';
import { UserInfo } from '@utils/types/user';
import { useTranslation } from 'react-i18next';

export const KEY = 'province-list';

export default function useProvince() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<any, Error>([KEY], async (): Promise<any> => {
      return await getProvinceListFromApi(currentLanguage);
    }),
  };
}

export interface IPurchase {
  id: string;
  activationDate: string;
  price: number;
  priceWithVAT: number;
  paymentType: string;
  status: PURCHASE_STATUS;
  licenses: ILicenseThumbnail[];
  documents: IPurchaseDocument[];
}

export enum PURCHASE_STATUS {
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum PAYMENT_DOWNLOAD_DOCUMENT {
  INVOICE = 'INVOICE',
  REFUND = 'REFUND',
}

export type IPurchaseDocument = {
  type: PAYMENT_DOWNLOAD_DOCUMENT;
};

export type IPurchaseResponse = {
  purchases: IPurchase[];
  maxPage: number;
  itemsPerPage: number;
  currentPage: number;
};

export type IPurchaseRequest = {
  query: string;
  itemsPerPage: number;
  currentPage: number;
  sortField: string;
  sortDir: string;
};

export type ILicenseThumbnail = {
  iconUrl: string;
  description: string;
};

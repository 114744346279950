import * as React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import styles from './styles.module.scss';
interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  isTitleRow?: boolean;
  isHeaderHidden?: boolean;
  isBodyHidden?: boolean;
  product?: string;
}

const BasicTable = <T extends object>({
  data,
  columns,
  product,
  isHeaderHidden = false,
  isBodyHidden = false,
  isTitleRow = false,
}: ReactTableProps<T>) => {
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const onWindowResize = useWindowResize();

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
  });

  const hideColumn = windowWidth < 1200;

  return (
    <div className={styles.container}>
      <div className={styles.tableWrapper}>
        <table
          className={`${styles.basicTable} ${
            product === 'uranium' ? styles.uranium : ''
          } ${hideColumn ? styles.noBorder : ''}`}
          style={{
            boxShadow: isTitleRow
              ? 'none'
              : '0px 2px 0px rgba(11, 40, 78, 0.05)',
          }}
        >
          {!isHeaderHidden && (
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  style={{
                    boxShadow: isTitleRow
                      ? 'none'
                      : '0px 2px 0px rgba(11, 40, 78, 0.05)',
                  }}
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        display:
                          header.id === 'feature' && hideColumn
                            ? 'none'
                            : 'table-cell',
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {!isBodyHidden && (
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    {hideColumn &&
                      row
                        .getVisibleCells()
                        .slice(0, 1)
                        .map((cell) => {
                          return (
                            <tr key={cell.id} className={styles.titleRow}>
                              <td>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    <tr>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className={hideColumn ? styles.hideFirstColumn : ''}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default BasicTable;

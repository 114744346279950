import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import Card from '@components/Card';
import Header, { HEADER_TYPE } from '@components/Header';
import LayoutPage from '@components/LayoutPage';
import Spinner from '@components/Spinner';
import { UserContext } from '@contexts/UserContext';
import { useAutologinUser } from '@hooks/useAutologinUser';
import { useLogout } from '@hooks/useLogout';
import styles from './styles.module.scss';

export default function Autologin() {
  const { t } = useTranslation(['routes']);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';
  //const goToUrl = searchParams.get('goToUrl') ?? '';

  const navigate = useNavigate();

  const { setIsLoggedIn, isLoggedIn } = React.useContext(UserContext);

  const onSuccess = React.useCallback(() => {
    setIsLoggedIn(true);
    navigate(t('routes:home'));
  }, [setIsLoggedIn, navigate, t]);

  const onError = () => {
    navigate(t('routes:home'));
  };

  const {
    //data,
    mutate: onAutologinUser,
    //isLoading,
  } = useAutologinUser(onSuccess, onError);

  const { mutate: logout } = useLogout();

  React.useEffect(() => {
    if (token.length > 0) {
      if (isLoggedIn) {
        logout();
      }
      onAutologinUser({ token });
    } else {
      navigate(t('routes:home'));
    }
  }, []);

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage isCentered>
        <div className={styles.container}>
          <Card>
            <Spinner isMid />
          </Card>
        </div>
      </LayoutPage>
      <Outlet />
    </>
  );
}

import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';
import SidebarWrapper from '@components/SidebarWrapper';
import MiniCart from '@components/MiniCart';
import { CartContext } from '@contexts/CartContext';
import Card from '@components/Card';
import Input from '@components/Input';
import classNames from 'classnames';
import Button, { BUTTON_STYLE } from '@components/Button';
import { useLogInUser } from '@hooks/useLogInUser';
import SkeletonBasic from '@components/SkeletonBasic';
import useCartProducts from '@hooks/useCartProducts';
import { isUndefined, noop } from 'lodash';
import { useDiscountCheck } from '@hooks/useDiscountCheck';
import { useB2BPecCheck } from '@hooks/useB2BPecCheck';
import PaymentSelection from '@components/PaymentSelection';
import {
  AddPurchaseResponse,
  CheckoutPrice,
  PAYMENT_METHODS,
  PRODUCT_TYPE,
} from '@utils/types/store';
import { useAddPurchase } from '@hooks/useAddPurchase';
import Checkbox from '@components/Checkbox';
import { Link } from 'react-router-dom';
import { dataLayerPushBeginCheckout } from '@utils/tagManagerHelper';
import { forceToFloat } from '@utils/types/helpers';

export default function Checkout() {
  const { t } = useTranslation(['checkout', 'routes', 'common', 'forms']);

  const navigate = useNavigate();
  const [discountCode, setDiscountCode] = React.useState('');
  const [B2BPec, setB2BPec] = React.useState('');
  const [paymentMethod, setPaymentMethod] = React.useState<PAYMENT_METHODS>(
    PAYMENT_METHODS.CREDIT_CARD
  );
  const [agreement, setAgreement] = React.useState(false);

  const [showDiscountCodeCheck, setShowDiscountCodeCheck] =
    React.useState(false);
  const [showB2BPecCheck, setShowB2BPecCheck] = React.useState(false);

  const { cartItems, setDiscountCode: setDiscountCodeOnContext } =
    React.useContext(CartContext);

  const isThereAnUraniumProduct = cartItems?.some(
    (el) => el?.productType === PRODUCT_TYPE.URANIUM
  );
  const isThereASupremoProduct = cartItems?.some(
    (el) => el?.productType === PRODUCT_TYPE.SUPREMO
  );

  const onChangeDiscountCodeFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscountCode(e?.target.value);
    },
    []
  );

  const onChangeB2BPecFn = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setB2BPec(e?.target.value);
    },
    []
  );

  const onB2BPecCheckSuccessFn = React.useCallback(() => {
    setShowB2BPecCheck(true);
  }, []);
  const onB2BPecCheckErrorFn = React.useCallback(() => {
    setShowB2BPecCheck(true);
  }, []);

  const {
    mutate: onCheckB2BPec,
    data: isB2BPecValid,
    isLoading: isLoadingCheckB2BPec,
  } = useB2BPecCheck(onB2BPecCheckSuccessFn, onB2BPecCheckErrorFn);

  const onCheckB2BPecFn = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault();
      onCheckB2BPec(B2BPec);
    },
    [onCheckB2BPec, B2BPec]
  );
  const onDiscountCheckSuccessFn = React.useCallback(
    (res: boolean) => {
      setShowDiscountCodeCheck(true);
      if (res) {
        setDiscountCodeOnContext(discountCode);
      }
    },
    [discountCode, setDiscountCodeOnContext]
  );

  const {
    data: isDiscountCodeValid,
    mutate: discountCheck,
    isLoading: isLoadingDiscountCodeCheck,
  } = useDiscountCheck(onDiscountCheckSuccessFn);

  const { isLoggedIn } = React.useContext(UserContext);

  const onCheckPromoCodeFn = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault();
      discountCheck(discountCode);
    },
    [discountCheck, discountCode]
  );

  const {
    data: { billingInformation, B2BPecNeeded, products, total },
    isLoadingCheckoutFromApi,
    isLoadingGettingProduct,
  } = useCartProducts();

  const { data: userLoginData, isLoading: isLoadingUserLoginData } = useLogInUser();
  React.useEffect(() => {
    if (!Boolean(cartItems?.length) || !isLoggedIn) {
      navigate(t('routes:signin'));
    }
  }, [cartItems, navigate, isLoggedIn, t]);

  const onAddPurchaseSuccessFn = React.useCallback(
    (res: AddPurchaseResponse) => {
      res?.redirectUrl && window.open(res?.redirectUrl, '_self');
    },
    []
  );

  const {
    mutate: onAddPurchase,
    isLoading: isLoadingOnAddPurchase,
    isError,
    error,
  } = useAddPurchase(onAddPurchaseSuccessFn);
  const [autoRenew, setAutoRenew] = React.useState(false);

  const onAddPurchaseFn = React.useCallback(() => {
    onAddPurchase({
      autoRenew,
      paymentMethod,
      code: discountCode,
      b2bPec: B2BPec,
    });
  }, [B2BPec, discountCode, onAddPurchase, paymentMethod, autoRenew]);

  React.useEffect(() => {
    if (
      (paymentMethod === PAYMENT_METHODS.PAYPAL && !isThereAnUraniumProduct) ||
      (paymentMethod === PAYMENT_METHODS.PAYPAL &&
        isThereAnUraniumProduct &&
        isThereASupremoProduct)
    ) {
      setAutoRenew(true);
    } else {
      setAutoRenew(false);
    }
  }, [paymentMethod, isThereAnUraniumProduct, isThereASupremoProduct]);

  const [agreementError, setAgreementError] = React.useState(false);

  const handleAgreementChange = (checked: boolean) => {
    setAgreement(checked);
    setAgreementError(!checked);
  };

  const onBeforeAddPurchase = () => {
    if (agreement) {
      onAddPurchaseFn();
    } else {
      setAgreementError(true);
    }
  };

  const [pushDataLayer, setPushDataLayer] = React.useState(true);
  React.useEffect(() => {
    if (pushDataLayer && products && products.length && total && total.billingInformation) {
      dataLayerPushBeginCheckout(products, forceToFloat((total as CheckoutPrice).total));
      setPushDataLayer(false);
    }
  }, [pushDataLayer, products, total]);

  return (
    <>
      <Header type={HEADER_TYPE.CHECKOUT} />
      <LayoutPage>
        <LayoutSection noMarginBottom={true} hasSide isCentered>
          <div className={styles.container}>
            <h1 style={{ marginBottom: '44px', fontSize: '2.4rem' }}>
              {t('checkout:title')}
            </h1>
            <div className={styles.checkoutItem}>
              <Card title={t('common:shops.account')}>
                <div className={styles.cardContent}>
                  {isLoadingCheckoutFromApi ? (
                    <strong>
                      <SkeletonBasic width={150} height={16} />
                    </strong>
                  ) : (
                    <strong>{billingInformation?.fullname}</strong>
                  )}
                  {isLoadingUserLoginData ? (
                    <p>
                      <SkeletonBasic width={150} height={16} />
                    </p>
                  ) : (
                    <p>{userLoginData?.email}</p>
                  )}
                  <Link to={t('routes:editAccount')} className={'linkBlue'}>
                    {t('common:shops.edit')}
                  </Link>
                </div>
              </Card>
            </div>
            <div className={styles.checkoutItem}>
              <Card title={t('common:shops.invocing')}>
                <div className={styles.cardContent}>
                  {isLoadingCheckoutFromApi ? (
                    <strong>
                      <SkeletonBasic width={150} height={16} />
                    </strong>
                  ) : (
                    <strong>{billingInformation?.fullname}</strong>
                  )}
                  {isLoadingCheckoutFromApi ? (
                    <p>
                      <SkeletonBasic width={150} height={16} />
                    </p>
                  ) : (
                    <p>
                      {billingInformation?.address}
                      <br />
                      {billingInformation?.city}
                    </p>
                  )}
                </div>
                <div className={styles.cardContent}>
                  {isLoadingCheckoutFromApi ? (
                    <p>
                      <SkeletonBasic width={150} height={16} />
                    </p>
                  ) : (
                    <>
                      {!isUndefined(billingInformation?.fiscalCode) ? (
                        <p>{billingInformation?.fiscalCode}</p>
                      ) : (
                        <></>
                      )}
                      {!isUndefined(billingInformation?.taxNumber) ? (
                        <p>{billingInformation?.taxNumber}</p>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <Link to={t('routes:editContact')} className={'linkBlue'}>
                    {t('common:shops.edit')}
                  </Link>
                </div>
              </Card>
            </div>
            {B2BPecNeeded && (
              <div className={styles.checkoutItem}>
                <Card title={t('common:shops.electronicInvocing')}>
                  <div className={styles.cardContent}>
                    <p>{t('common:shops.invoicingContent')}</p>
                  </div>
                  <div className={classNames([styles.cardContent])}>
                    <div className={classNames([styles.cardContentInline])}>
                      <form
                        style={{ minWidth: 320 }}
                        onSubmit={B2BPec ? onCheckB2BPecFn : noop}
                      >
                        <Input
                          value={B2BPec}
                          placeholder={t('common:shops.sdiCode')}
                          onChange={onChangeB2BPecFn}
                        />
                      </form>{' '}
                      <Button
                        isLoading={isLoadingCheckB2BPec}
                        onClick={B2BPec ? onCheckB2BPecFn : noop}
                        styleBtn={BUTTON_STYLE.SECONDARY}
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {t('common:shops.add')}
                      </Button>
                    </div>
                    {showB2BPecCheck &&
                      B2BPec &&
                      (isLoadingCheckB2BPec ? (
                        <p style={{ marginTop: 8 }}>
                          <SkeletonBasic width={150} height={16} />
                        </p>
                      ) : (
                        <p style={{ marginTop: 8 }}>
                          {isB2BPecValid ? (
                            <span
                              className={classNames(
                                styles.validCheck,
                                styles.valid
                              )}
                            >
                              {t('common:shops.validB2BPec')}{' '}
                              <span className="icon-check-circle"></span>
                            </span>
                          ) : (
                            <span
                              className={classNames(
                                styles.validCheck,
                                styles.invalid
                              )}
                            >
                              {t('common:shops.invalidB2BPec')}
                              <span className="icon-x-circle"></span>
                            </span>
                          )}
                        </p>
                      ))}
                  </div>
                </Card>
              </div>
            )}
            <div className={styles.checkoutItem}>
              <Card title={t('common:shops.discount')}>
                <div className={classNames([styles.cardContent])}>
                  <div className={classNames([styles.cardContentInline])}>
                    <form
                      onSubmit={discountCode ? onCheckPromoCodeFn : noop}
                    >
                      <Input
                        value={discountCode}
                        placeholder={t('common:shops.discountCode')}
                        onChange={onChangeDiscountCodeFn}
                      />
                    </form>{' '}
                    <Button
                      isLoading={isLoadingDiscountCodeCheck}
                      onClick={discountCode ? onCheckPromoCodeFn : noop}
                      styleBtn={BUTTON_STYLE.SECONDARY}
                      style={{ alignSelf: 'flex-start' }}
                    >
                      {t('common:shops.add')}
                    </Button>
                  </div>
                  {showDiscountCodeCheck &&
                    discountCode &&
                    (isLoadingDiscountCodeCheck ? (
                      <p style={{ marginTop: 8 }}>
                        <SkeletonBasic width={150} height={16} />
                      </p>
                    ) : (
                      <p style={{ marginTop: 8 }}>
                        {isDiscountCodeValid ? (
                          <span
                            className={classNames(
                              styles.validCheck,
                              styles.valid
                            )}
                          >
                            {t('common:shops.validCode')}{' '}
                            <span className="icon-check-circle"></span>
                          </span>
                        ) : (
                          <span
                            className={classNames(
                              styles.validCheck,
                              styles.invalid
                            )}
                          >
                            {t('common:shops.invalidCode')}{' '}
                            <span className="icon-x-circle"></span>
                          </span>
                        )}
                      </p>
                    ))}
                </div>
              </Card>
            </div>
            <div className={classNames(styles.checkoutItem, styles.mobile)}>
              <MiniCart
                products={products}
                isLoadingCheckoutFromApi={isLoadingCheckoutFromApi}
                isLoadingGettingProduct={isLoadingGettingProduct}
                total={total as CheckoutPrice}
                hasStaticQuantity
              />
            </div>
            <div className={styles.checkoutItem}>
              <Card title={t('common:shops.payment')}>
                <div className={classNames([styles.cardContent])}>
                  <PaymentSelection
                    onSelect={setPaymentMethod}
                    selection={paymentMethod}
                  />
                  <div style={{ marginBottom: 16 }}>
                    <Checkbox
                      disabled={paymentMethod !== PAYMENT_METHODS.PAYPAL}
                      onCheck={setAutoRenew}
                      isChecked={autoRenew}
                    >
                      <p>{t('checkout:paypalRenew')}</p>
                    </Checkbox>
                  </div>
                  <div style={{ marginBottom: 16 }}>
                    <Checkbox onCheck={handleAgreementChange} isChecked={agreement} error={agreementError && !agreement}>
                      <p>
                        {t('checkout:termsConditions.part1')}{' '}
                        <a href={t('links:nanosystems.termsAndConditions')} target="_blanck" className="link">
                          {t('checkout:termsConditions.part2')}
                        </a>
                      </p>
                    </Checkbox>
                    {agreementError && (
                      <p className={classNames('error', styles.agreementError)}>
                        {t('checkout:termsConditions.error')}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classNames([styles.cardContent])}>
                  <Button
                    /*disabled={!agreement}*/
                    isLoading={isLoadingOnAddPurchase}
                    onClick={onBeforeAddPurchase}
                  >
                    {t('common:shops.proceedPayment')}
                  </Button>
                  {isError && (
                    <p className={classNames('error', styles.error)}>
                      {error?.message || t('common:errors.generic')}
                    </p>
                  )}
                </div>
              </Card>
            </div>
          </div>

          <SidebarWrapper hideOnMobile>
            <MiniCart
              products={products}
              isLoadingCheckoutFromApi={isLoadingCheckoutFromApi}
              total={total as CheckoutPrice}
              isLoadingGettingProduct={isLoadingGettingProduct}
              hasStaticQuantity
            />
          </SidebarWrapper>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}

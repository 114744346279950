import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ProductCard from '@components/ProductCard';
import {
  CartProductItem,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION,
} from '@utils/types/store';
import ControlsSelect from '@components/ControlsSelect';
import LabelWithTooltip from '@components/LabelWithTooltip';
//import RadioButton from '@components/RadioButton';
import TeamInfoTooltip from '@components/TeamInfoTooltip';
import Select from '@components/Select';
import Counter from '@components/Counter';
import { filter, first, multiply, noop } from 'lodash';
import { calculateProductPriceFn } from '@utils/types/helpers';
import { toast } from 'react-toastify';
import ToastCart from '@components/ToastCart';
import { IUpgradeProductForEvalPriceResponse } from '@utils/types/licenses';
import { useUpgradePriceEval } from '@hooks/useUpgradePriceEval';
import { useUpgradeAddToCart } from '@hooks/useUpgradeAddToCart';
import { SUBSCRIPTION_DURATION } from '@services/normalize';
import { dataLayerPushAddToCart } from '@utils/tagManagerHelper';

type Props = {
  productDetails: ProductVersion;
  handleAddCart: (CartProductItem: CartProductItem) => void;
  showPromoDesc: boolean;
  renewOrUpgrade?: string;
};

const SupremoBusinessProductCard = ({
  productDetails,
  handleAddCart,
  showPromoDesc,
  renewOrUpgrade,
}: Props) => {
  const { t } = useTranslation(['common', 'supremoControl']);

  // STATE

  const [isUpgrade, setIsUpgrade] = React.useState<string | boolean>();

  const [upgradedPrice, setUpgradedPrice] =
    React.useState<IUpgradeProductForEvalPriceResponse>();

  const [, setUpgradedProductForCart] = React.useState<any>();

  const [connectionsValue, setConnectionsValue] = React.useState({
    productID: productDetails?.id,
    connection:
      (renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW') &&
        productDetails?.startingControl &&
        productDetails?.startingControl >= 3
        ? productDetails?.startingControl
        : productDetails.minAmount,
  });

  const [teamValue, setTeamValue] = React.useState({
    productID: productDetails?.id,
    teamID: productDetails?.profiles && productDetails?.profiles[0]?.id,
    label: productDetails?.profiles && productDetails?.profiles[0]?.name,
  });
  const [quantity, setQuantity] = React.useState(1);

  // USE EFFECT

  React.useEffect(() => {
    const isUpgradeRenew =
      renewOrUpgrade === 'UPGRADE'
        ? true
        : renewOrUpgrade === 'RENEW'
          ? false
          : 'shop';

    setIsUpgrade(isUpgradeRenew);
  }, [renewOrUpgrade]);

  // ON SUCCESS CALLBACK

  const onSuccessUpgradePriceEval = React.useCallback(
    (res: IUpgradeProductForEvalPriceResponse) => {
      setUpgradedPrice(res);
    },
    []
  );

  const onSuccessGetCartForUpgrade = React.useCallback(
    (res: ProductVersion) => {
      setUpgradedProductForCart(res);

      handleAddCart({
        productType: PRODUCT_TYPE.SUPREMO,
        id: res?.id, // id upgraded product form /addUpgradeToCart
        quantity: res?.quantity,
        isCumulable: true,
        connections: connectionsValue?.connection,
        connectionsForUpgradeCart: res?.subProducts[0]?.quantity, // connections for /addUpgradeToCart
        teamAssistanceProfileId: teamValue?.teamID,
        upgradeId: res.upgradeId,
        matchedId: productDetails?.id, //id upgraded product choosen by user (from /upgrade)
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: res.upgradeId,
      });
      const name = `${PRODUCT_TYPE.SUPREMO} ${res?.name}`;
      toast(<ToastCart />, {
        data: {
          quantity: quantity,
          price: res.price,
          title: name,
        },
      });
      dataLayerPushAddToCart(res?.id, name, res.price, quantity);
    },
    [
      renewOrUpgrade,
      connectionsValue?.connection,
      productDetails?.id,
      handleAddCart,
      teamValue?.teamID,
      quantity,
    ]
  );

  // ON ERROR CALLBACK

  const onErrorUpgradePriceEval = React.useCallback(() => {
    console.log('error UpgradePriceEval');
  }, []);

  const onErrorGetCartForUpgrade = React.useCallback(() => {
    console.log('error getCartForUpgrade');
  }, []);

  // HOOKS

  const { mutate: calculateUpgradePrice } = useUpgradePriceEval(
    (res) => onSuccessUpgradePriceEval(res),
    onErrorUpgradePriceEval
  );

  const { mutate: getCartForUpgrade } = useUpgradeAddToCart(
    (res) => onSuccessGetCartForUpgrade(res),
    onErrorGetCartForUpgrade
  );

  // ON CHANGE SELECT

  const onChooseTeam = React.useCallback(
    (productID: number) =>
      ({ id, label }: { id: number; label: string }) => {
        setTeamValue({ productID, teamID: id, label });
      },
    []
  );

  const onChooseConnections = React.useCallback(
    (productID: number) => (connection: number) => {
      setConnectionsValue({ productID, connection });
    },
    []
  );

  const supremoBusinessFeatures = React.useMemo(
    () => [
      t('supremoControl:features.business.list.1')
    ],
    [t]
  );

  const currentProfile = React.useMemo(
    () => filter(productDetails?.profiles, { id: teamValue?.teamID }),
    [productDetails?.profiles, teamValue?.teamID]
  );

  const subProductsPrices = React.useMemo(
    () => productDetails?.subProducts.map((pr) => pr?.price ?? 0) ?? [],
    [productDetails?.subProducts]
  );

  // HOOK FN CALLBACK

  const onCalculatePrice = React.useCallback(() => {
    calculateUpgradePrice({
      product: productDetails,
      connections: (connectionsValue.connection as number) - 3,
      teamId: teamValue.teamID as number,
    });
  }, [
    calculateUpgradePrice,
    productDetails,
    connectionsValue.connection,
    teamValue.teamID,
  ]);

  const onGetUpgradedProductForCart = React.useCallback(() => {
    getCartForUpgrade({
      product: productDetails,
      connections: (connectionsValue.connection as number) - 3,
      teamId: teamValue.teamID as number,
    });
  }, [
    getCartForUpgrade,
    productDetails,
    connectionsValue.connection,
    teamValue.teamID,
  ]);

  // CALCULATED PRICE

  const startingControlsOrMinAmount =
    productDetails.startingControl === 0
      ? productDetails.minAmount
      : productDetails.startingControl;

  const priceCalculated = React.useMemo(() => {
    if (
      (isUpgrade === true &&
        productDetails?.profiles &&
        productDetails?.profiles[0]?.id !== teamValue.teamID) ||
      (isUpgrade === true &&
        startingControlsOrMinAmount !== Number(connectionsValue.connection) &&
        startingControlsOrMinAmount &&
        Number(connectionsValue.connection) > startingControlsOrMinAmount) ||
      isUpgrade === true
    ) {
      onCalculatePrice();

      return upgradedPrice?.price as number;
    } else if (
      (isUpgrade === false &&
        productDetails?.profiles &&
        productDetails?.profiles[0]?.id !== teamValue.teamID) ||
      (isUpgrade === false &&
        startingControlsOrMinAmount !== Number(connectionsValue.connection) &&
        startingControlsOrMinAmount &&
        Number(connectionsValue.connection) > startingControlsOrMinAmount) ||
      isUpgrade === false
    ) {
      return calculateProductPriceFn({
        productPrice: productDetails?.price,
        profilePrice: Number(first(currentProfile)?.price ?? 0),
        connections: connectionsValue?.connection as number,
        subProductsPrices,
        minAmount: productDetails?.minAmount,
      });
    } else if (isUpgrade === 'shop') {
      return calculateProductPriceFn({
        productPrice: productDetails?.price,
        profilePrice: Number(first(currentProfile)?.price ?? 0),
        connections: connectionsValue?.connection as number,
        subProductsPrices,
        minAmount: productDetails?.minAmount,
      });
    } else {
      return productDetails?.price;
    }
  }, [
    isUpgrade,
    upgradedPrice?.price,
    onCalculatePrice,
    productDetails?.profiles,
    productDetails?.price,
    productDetails?.minAmount,
    teamValue.teamID,
    startingControlsOrMinAmount,
    currentProfile,
    connectionsValue?.connection,
    subProductsPrices,
  ]);

  const finalPrice = multiply(priceCalculated, quantity)?.toLocaleString(
    'it-IT'
  );

  const handleAddCartFn = React.useCallback(() => {
    if (isUpgrade === true) {
      onGetUpgradedProductForCart();
    } else {
      handleAddCart({
        productType: PRODUCT_TYPE.SUPREMO,
        id: productDetails?.id,
        quantity,
        isCumulable: productDetails?.isCumulable,
        connections: connectionsValue?.connection,
        teamAssistanceProfileId: teamValue?.teamID,
        hideCounter: renewOrUpgrade === 'UPGRADE' || renewOrUpgrade === 'RENEW',
        renewOrUpgrade: renewOrUpgrade,
        fromLicense: productDetails.upgradeId,
      });
      const name = `${PRODUCT_TYPE.SUPREMO} ${productDetails?.name}`;
      toast(<ToastCart />, {
        data: {
          quantity,
          price: finalPrice,
          title: name,
        },
      });
      dataLayerPushAddToCart(productDetails?.id, name, priceCalculated, quantity);
    }
  }, [
    productDetails.upgradeId,
    renewOrUpgrade,
    handleAddCart,
    onGetUpgradedProductForCart,
    isUpgrade,
    productDetails?.id,
    productDetails?.isCumulable,
    productDetails?.name,
    quantity,
    connectionsValue?.connection,
    teamValue?.teamID,
    priceCalculated,
    finalPrice,
  ]);

  const monthlyPrice =
    priceCalculated && priceCalculated / productDetails?.subscriptionDuration;
  return (
    <ProductCard
      subscription={
        productDetails?.subscriptionDuration as SUBSCRIPTION_DURATION
      }
      showPromoDesc={showPromoDesc}
      onAddCart={handleAddCartFn}
      isActive
      isUpgrade={isUpgrade}
      promoDesc={productDetails?.promoDesc}
      description={t('supremoControl:features.business.description')}
      key={productDetails?.id}
      upgradeId={productDetails?.upgradeId}
      id={productDetails?.id}
      price={finalPrice}
      priceType={t('common:shops.startingFrom')}
      subscriptionDurationType={t('common:shops.perMonth')}
      version={PRODUCT_VERSION.BUSINESS}
      monthlyPrice={monthlyPrice}
      showPricePerYear
      currency={<span>&euro;</span>}
      features={supremoBusinessFeatures}
      priceDesc={t('supremoControl:prices.business.description')}
      connectionsLabel={
        <LabelWithTooltip
          text={t('common:shops.simultaneousConnections')}
          tooltipOverlay={t('common:shops.simultaneousConnectionsTooltip')}
        />
      }
      expirationDate={productDetails?.newExpire}
      connections={
        productDetails?.maxAmount === 0 ? (
          <p className={styles.single}> {t('common:shops.single')}</p>
        ) : (
          <ControlsSelect
            min={
              renewOrUpgrade === 'UPGRADE' && productDetails?.startingControl
                ? productDetails?.startingControl
                : productDetails?.minAmount
            }
            max={productDetails?.maxAmount}
            onChange={onChooseConnections(productDetails?.id)}
            value={connectionsValue?.connection}
          />
        )
      }
      teamLabel={
        <LabelWithTooltip
          text={t('common:shops.assistanceTeam')}
          tooltipOverlay={
            productDetails?.profiles && (
              <TeamInfoTooltip profiles={productDetails?.profiles} />
            )
          }
        />
      }
      teamAssistance={
        /* 12-10-2023: removed to use always the select
        productDetails?.profiles && productDetails?.profiles.length <= 4 ? (
          <RadioButton<{ id: number; label: string }>
            onChange={onChooseTeam(productDetails?.id)}
            options={productDetails?.profiles?.map((profile) => ({
              ...profile,
              label: profile?.name,
            }))}
            value={{
              id: teamValue?.teamID ?? 0,
              label: teamValue?.label ?? '',
            }}
          />
        ) : 
        */
        productDetails?.profiles && productDetails?.profiles.length > 0 ? (
          <Select<{ id: number; label: string }>
            onChange={onChooseTeam(productDetails?.id)}
            options={productDetails?.profiles?.map((profile) => ({
              ...profile,
              label: profile?.description,
            }))}
            value={{
              id: teamValue?.teamID ?? 0,
              label: teamValue?.label ?? '',
            }}
          />
        ) : (
          <p> {t('common:shops.notActive')}</p>
        )
      }
      counter={
        <Counter
          count={quantity}
          onAdd={setQuantity}
          onChange={setQuantity}
          onRemove={quantity > 1 ? setQuantity : noop}
        />
      }
    />
  );
};

export default SupremoBusinessProductCard;

import { Trans } from 'react-i18next';
import styles from './styles.module.scss';

type IProps = {
  title: string;
  onClick?: () => void;
  number: string;
  url?: string;
  target?: string;
};

const OrderedTitle = ({ title, url, number, onClick, target = '_blank'}: IProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.number}>{number}</span>
      <p className={styles.title}>
        <Trans
          defaults={title}
          components={{
            link1: (
              <a
                onClick={onClick}
                target={target}
                rel="noreferrer"
                href={url}
              > </a>
            ),
          }}
        />
      </p>
    </div>
  );
};

export default OrderedTitle;

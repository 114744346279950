import { useQuery } from '@tanstack/react-query';
import { getPaymentRecap } from '@services/api';
import { noop } from 'lodash';
import { PaymentRecap } from '@utils/types/store';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
export const KEY = 'payment-recap';

export default function usePaymentRecap(onSuccess = noop, onError = noop) {
  const location = useLocation();
  const { query } = queryString?.parseUrl(location.search);
  const paymentId: any = query?.paymentId ?? null;
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<PaymentRecap, Error>(
      [KEY, paymentId],
      () => getPaymentRecap(currentLanguage, paymentId ?? ''),
      {
        enabled: Boolean(paymentId),
        onError,
        onSuccess,
        retry: 0,
      }
    ),
  };
}

import { useMutation } from '@tanstack/react-query';
import { getExpirationDate } from '@services/api';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

export const KEY = 'get-activation-date';
type Props = {
  id: string;
  isFromModal?: boolean;
};

export type checkActivationDateResponse = {
  expirationDate: string;
  isFromModal?: boolean;
};
export const useCheckActivationDate = (onSuccess = noop, onError = noop) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useMutation<checkActivationDateResponse, Error, Props>(
      [KEY],
      async ({
        id,
        isFromModal = false,
      }): Promise<checkActivationDateResponse> => {
        return await getExpirationDate(currentLanguage, id, isFromModal);
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

import styles from './styles.module.scss';
import { SyncLoader as SL } from 'react-spinners';
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props';

export default function SyncLoader(props: LoaderSizeMarginProps) {
  return (
    <div className={styles.container}>
      <SL size={10} color={'rgba(0,0,0,0.2)'} {...props} />
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getCountryListFromApi } from '@services/api';
import { UserInfo } from '@utils/types/user';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
export const KEY = 'country-list';

export default function useCountry() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return {
    ...useQuery<any, Error>([KEY], async (): Promise<any> => {
      return await getCountryListFromApi(currentLanguage);
    }),
  };
}

/* eslint-disable react/jsx-key */
import styles from './styles.module.scss';
import Collapsible from 'react-collapsible';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRODUCT_TYPE } from '@utils/types/store';

type Props = {
  productType: string;
};

export default function FaqListAccordion({ productType }: Props) {
  const { t } = useTranslation(['common', 'links', 'routes', 'supremoControl']);

  const faqListUranium: any[] = [
    {
      question: t('common:faq.faq1.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq1.uranium.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('routes:uraniumBackup')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq2.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq2.uranium.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('routes:uraniumBackup')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq3.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq3.uranium.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('routes:uraniumBackup')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq4.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq4.uranium.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('routes:uraniumBackup')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq5.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq5.uranium.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('routes:uraniumBackup')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq6.uranium.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq6.uranium.answer')}
          components={{
            link1: (
              <a
                style={{ display: 'block' }}
                target="_self"
                rel="noreferrer"
                href={t('links:uranium.gdpr.guide')}
              ></a>
            ),
            link2: (
              <a
                style={{ display: 'block' }}
                target="_self"
                rel="noreferrer"
                href={t('links:uranium.gdpr.backupsBestPractices')}
              ></a>
            ),
            link3: (
              <a
                style={{ display: 'block' }}
                target="_self"
                rel="noreferrer"
                href={t('links:uranium.gdpr.encryption')}
              ></a>
            ),
          }}
        />
      ),
    },
  ];

  const faqListSupremo: any[] = [
    {
      question: t('common:faq.faq1.supremo.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq1.supremo.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.faqs.simultaneousConnection')}
              ></a>
            ),
            link2: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.tutorials.activateLicese')}
              ></a>
            ),
            link3: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.supremoConsole')}
              ></a>
            ),
            link4: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.pricing')}
              ></a>
            ),
            link5: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.pricing')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq2.supremo.question'),
      answer: t('common:faq.faq2.supremo.answer'),
    },
    {
      question: t('common:faq.faq3.supremo.question'),
      answer: t('common:faq.faq3.supremo.answer'),
    },
    {
      question: t('common:faq.faq4.supremo.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq4.supremo.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.tutorials.customizedLogo')}
              ></a>
            ),
          }}
        />
      ),
    },
    {
      question: t('common:faq.faq5.supremo.question'),
      answer: t('common:faq.faq5.supremo.answer'),
    },
    {
      question: t('common:faq.faq6.supremo.question'),
      answer: (
        <Trans
          defaults={t('common:faq.faq6.supremo.answer')}
          components={{
            link1: (
              <a
                target="_self"
                rel="noreferrer"
                href={t('links:supremo.tutorialsConsole.configureAndInstall')}
              ></a>
            ),
          }}
        />
      ),
    },
  ];

  const faqListToRender = productType === PRODUCT_TYPE.SUPREMO ? faqListSupremo : faqListUranium;
  const seeAllFaqUrl = productType === PRODUCT_TYPE.SUPREMO ? t('links:supremo.faq') : t('links:uranium.support');

  return (
    <div className={styles.faqContainer}>
      <ul className="faqList">
        {faqListToRender &&
          faqListToRender.map((el: any, mainKey: number) => {
            return (
              <li key={mainKey}>
                <Collapsible trigger={el?.question} className={styles.question}>
                  <p className={styles.answer}>{el?.answer}</p>
                </Collapsible>
              </li>
            );
          })}
      </ul>
      <Link to={seeAllFaqUrl} target="_blank" className={styles.seeAll}>
        {t('common:faq.seeAllFaq')}
      </Link>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { recoveryPassword } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { ResponseError } from '@utils/types/generic';

const KEY = 'recovery-password';

export const usePasswordRecovery = (onSuccess = noop, onError = noop) => {
  const data = useMutation<void, ResponseError, { email: string; recaptcha: string }>(
    [KEY],
    async ({
      email,
      recaptcha,
    }: {
      email: string;
      recaptcha: string;
    }): Promise<void> => {
      await recoveryPassword(email, recaptcha);
    },
    {
      onError,
      onSuccess
    }
  );

  return {
    isLoading: data?.isLoading,
    data: data,
    mutate: data?.mutate,
    isError: data?.isError,
    error: data?.error,
  };
};

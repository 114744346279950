import styles from './styles.module.scss';
import ReactModal from 'react-modal';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  children: any;
};

export default function Modal({ onClose, isOpen, title, children }: Props) {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          inset: 0,
          position: 'relative',
          backgroundColor: 'transparent',
          padding: 0,
          border: 0,
          borderRadius: 0,
        },
      }}
    >
      <div className={styles.container}>
        <div className={`${styles.header} ${!title ? styles.noMargin : ''}`}>
          {title && <strong>{title}</strong>}{' '}
          <i onClick={onClose} className="icon-x"></i>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </ReactModal>
  );
}

import { UraniumFeatures, UraniumFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';

export function renderUraniumFeaturesColumn(
  columns: UraniumFeaturesList[]
): UraniumFeatures[] {
  return columns.map((el: UraniumFeaturesList) => {
    return {
      feature: (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {el?.name}
        </p>
      ),
      free: (
        <i
          style={{ color: el?.free ? 'green' : 'red' }}
          className={`${el?.free ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      base: (
        <i
          style={{ color: el?.base ? 'green' : 'red' }}
          className={`${el?.base ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      proTape: (
        <i
          style={{ color: el?.proTape ? 'green' : 'red' }}
          className={`${el?.proTape ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      proDB: (
        <i
          style={{ color: el?.proDB ? 'green' : 'red' }}
          className={`${el?.proDB ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      proShadow: (
        <i
          style={{ color: el?.proShadow ? 'green' : 'red' }}
          className={`${el?.proShadow ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      proVirtual: (
        <i
          style={{ color: el?.proVirtual ? 'green' : 'red' }}
          className={`${el?.proVirtual ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
      gold: (
        <i
          style={{ color: el?.gold ? 'green' : 'red' }}
          className={`${el?.gold ? 'icon-check' : 'icon-x'}`}
        ></i>
      ),
    };
  });
}

function UraniumBasicFeaturesTable() {
  const { t } = useTranslation('uraniumBackup');

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.free, {
      id: 'free',
      cell: (info) => info.getValue(),
      header: () => <span>Free</span>,
    }),
    columnHelper.accessor((row) => row.base, {
      id: 'base',
      cell: (info) => info.getValue(),
      header: () => <span>Base</span>,
    }),
    columnHelper.accessor((row) => row.proTape, {
      id: 'proTape',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Tape</span>,
    }),
    columnHelper.accessor((row) => row.proDB, {
      id: 'proDB',
      cell: (info) => info.getValue(),
      header: () => <span>Pro DB</span>,
    }),
    columnHelper.accessor((row) => row.proShadow, {
      id: 'proShadow',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Shadow</span>,
    }),
    columnHelper.accessor((row) => row.proVirtual, {
      id: 'proVirtual',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Virtual</span>,
    }),
    columnHelper.accessor((row) => row.gold, {
      id: 'gold',
      cell: (info) => info.getValue(),
      header: () => <span style={{ color: '#F2C94C' }}>Gold</span>,
    }),
  ];

  const featuresNameSpecific: UraniumFeaturesList[] = [
    {
      name: t('compareLicenses.table.specificFeatures.backupToTape.title'),

      free: false,
      base: false,
      proTape: true,
      proDB: false,
      proShadow: false,
      proVirtual: false,
      gold: true,
    },
    {
      name: t(
        'compareLicenses.table.specificFeatures.mySqlMariaDbMsSqlExchange.title'
      ),

      free: false,
      base: false,
      proTape: false,
      proDB: true,
      proShadow: false,
      proVirtual: false,
      gold: true,
    },
    {
      name: t('compareLicenses.table.specificFeatures.shadowCopyVss.title'),

      free: false,
      base: false,
      proTape: false,
      proDB: false,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.specificFeatures.esXiBackupHyperV.title'),

      free: false,
      base: false,
      proTape: false,
      proDB: false,
      proShadow: false,
      proVirtual: true,
      gold: true,
    },
  ];

  const featuresSpecific = [
    ...renderUraniumFeaturesColumn(featuresNameSpecific),
  ];

  return (
    <BasicTable
      product="uranium"
      isHeaderHidden
      isTitleRow
      data={featuresSpecific}
      columns={versionColumns}
    />
  );
}

export default UraniumBasicFeaturesTable;

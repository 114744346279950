import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { get } from 'lodash';

import styles from './styles.module.scss';

import Button from '@components/Button';
import Input, { INPUT_SIZE } from '@components/Input';
import Spinner from '@components/Spinner';

import useCountry from '@hooks/useCountry';
import useProvince from '@hooks/useProvince';

import { isEmailValid } from '@services/validations';

import { taxCodeRegex, taxCodeCorporateRegex, getSelectCountryStyle, getSelectProvinceStyle, getVatNumberPrefix } from '@utils/formUtils';
import { registerPhase2Response } from '@utils/types/user';

type Props = {
  onSubmit: any;
  isLoading: boolean;
  errorsFromApi?: string[] | null;
  userInfoPhase2?: registerPhase2Response | null;
  isError: boolean;
};

export default function MyAccountFormPersonalData({
  onSubmit,
  isLoading,
  errorsFromApi,
  userInfoPhase2,
  isError,
}: Props) {
  const { t } = useTranslation(['forms', 'routes']);

  const readOnly = !!userInfoPhase2 && userInfoPhase2?.country?.length > 0 && Number(userInfoPhase2?.country) > 0;

  const { data: countries, isLoading: isLoadingCountries } = useCountry();
  const { data: provinces, isLoading: isLoadingProvinces } = useProvince();

  const {
    watch,
    register,
    handleSubmit,
    control,
    clearErrors,
    setError,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  React.useEffect(() => {
    if (userInfoPhase2) {
      setValue('codeOrPec', userInfoPhase2.codeOrPec);
      setValue('country', userInfoPhase2.country);
      setValue('firstnameAndLastname', userInfoPhase2.firstnameAndLastname);
      setValue('fiscalCode', userInfoPhase2.fiscalCode);
      setValue('vatNumber', userInfoPhase2.vatNumber);
      setValue('provinceState', userInfoPhase2.provinceState);
      if (userInfoPhase2.country === '1') {
        setValue('province', userInfoPhase2.province);
        setValue('paCode', userInfoPhase2.paCode);
        setValue('fiscalCode', userInfoPhase2.fiscalCode);
        setValue('codeOrPec', userInfoPhase2.codeOrPec);
      } else {
        setValue('provinceState', userInfoPhase2.provinceState);
      }
      setValue('userType', userInfoPhase2.userType);
    }
  }, [userInfoPhase2, setValue]);

  const sdiOrPec = watch('codeOrPec');
  const firstName = watch('firstnameAndLastname');
  const countryRef = watch('country');
  const province = watch('province');
  const paCode = watch('paCode');
  const provinceState = watch('provinceState');
  const vat = watch('vatNumber');

  React.useEffect(() => {
    setSdiOrPecValue(sdiOrPec);
  }, [watch, sdiOrPec]);

  const [isDirty, setIsDirty] = React.useState<{
    userType: boolean;
    firstnameAndLastname: boolean;
    country: boolean;
    fiscalCode: boolean;
    paCode: boolean;
    vatNumber: boolean;
    province: boolean;
    isPublicAmministration: boolean;
    codeOrPec: boolean;
    provinceState: boolean;
  }>({
    userType: false,
    firstnameAndLastname: false,
    country: false,
    fiscalCode: false,
    paCode: false,
    vatNumber: false,
    province: false,
    isPublicAmministration: false,
    codeOrPec: false,
    provinceState: false,
  });

  const [selectedCountryIsInEurope, setSelectedCountryIsInEurope] = React.useState(false);
  const [selectedCountryIsItaly, setSelectedCountryIsItaly] = React.useState(get(userInfoPhase2, 'country') === '1' ?? false);
  const [selectedCountryIsAmerica, setSelectedCountryIsAmerica] = React.useState(get(userInfoPhase2, 'country') === '785' ?? false);
  const [selectedCountryIsCanada, setSelectedCountryIsCanada] = React.useState(get(userInfoPhase2, 'country') === '595' ?? false);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState(get(userInfoPhase2, 'country'));
  const [selectedProvinceCode, setSelectedProvinceCode] = React.useState(get(userInfoPhase2, 'province'));
  const [sdiOrPecValue, setSdiOrPecValue] = React.useState(get(userInfoPhase2, 'codeOrPec'));
  const [isCompany, setIsCompany] = React.useState<boolean | undefined>(userInfoPhase2?.userType === '1');
  const [isPrivate, setIsPrivate] = React.useState<boolean>(userInfoPhase2?.userType === '0');
  const [isPA, setIsPA] = React.useState<boolean | undefined>(get(userInfoPhase2, 'paCode') !== '' ? true : false);
  const [vatNumberPrefix, setVatNumberPrefix] = React.useState('');

  function showCompany() {
    setIsCompany(true);
    setIsPrivate(false);
  }

  function showPrivate() {
    setIsCompany(false);
    setIsPrivate(true);
  }

  function showPACode() {
    setIsPA(true);
  }

  function hidePACode() {
    setIsPA(false);
    resetField('paCode');
  }

  const onCountrySelection = (code: string, isInEurope: boolean): void => {
    setSelectedCountryCode(code);
    isInEurope
      ? setSelectedCountryIsInEurope(true)
      : setSelectedCountryIsInEurope(false);
    code === '1'
      ? setSelectedCountryIsItaly(true)
      : setSelectedCountryIsItaly(false);
    code === '785'
      ? setSelectedCountryIsAmerica(true)
      : setSelectedCountryIsAmerica(false);
    code === '595'
      ? setSelectedCountryIsCanada(true)
      : setSelectedCountryIsCanada(false);

    setVatNumberPrefix(getVatNumberPrefix(code));
  };

  const onProvinceSelection = (code: string): void => {
    setSelectedProvinceCode(code);
  };

  function isValidFiscalCode(taxCode: string): boolean {
    let result = false;
    if (taxCode) {
      result = taxCodeRegex.test(taxCode) || taxCodeCorporateRegex.test(taxCode);
    }
    return result;
  }

  const { ref: firstnameAndLastnameRef, ...firstnameAndLastnameInputProps } =
    register('firstnameAndLastname', {
      required: t('forms:errorsState.required.firstnameAndLastname'),
    });

  const { ref: fiscalCodeRef, ...fiscalCodeInputProps } = register(
    'fiscalCode',
    {
      required:
        (selectedCountryIsItaly && !isCompany) || isPA
          ? t('forms:errorsState.required.fiscalCode')
          : false,

      validate: {
        value: (value) => {
          if (value && selectedCountryIsItaly) {
            return isValidFiscalCode(value.toUpperCase()) ? true : t('forms:errorsState.invalid.fiscalCode');
          } else {
            return true;
          }
        },
      },
    }
  );

  function isValidVatNumber(vatNumber: string): boolean {
    let result = false;
    if (vatNumber) {
      result = taxCodeCorporateRegex.test(vatNumber);
    }
    return result;
  }

  const { ref: vatNumberRef, ...vatNumberInputProps } = register('vatNumber', {
    required:
      (selectedCountryIsItaly || selectedCountryIsInEurope) && isCompany &&
      t('forms:errorsState.required.vatNumber'),

    minLength: {
      value: 7,
      message: t('forms:errorsState.invalid.vatNumber'),
    },
    validate: {
      value: (value) => {
        if (value && selectedCountryIsItaly && isCompany) {
          return isValidVatNumber(value.toUpperCase()) ? true : t('forms:errorsState.invalid.vatNumber');
        } else {
          return true;
        }
      },
    },
  });

  const { ref: codeOrPecRef, ...codeOrPecInputProps } = register('codeOrPec', {
    required: false,
    maxLength: {
      value: sdiOrPecValue && sdiOrPecValue.includes('@') ? 320 : 7,
      message:
        sdiOrPecValue && sdiOrPecValue.includes('@') === false
          ? t('forms:errorsState.invalid.codeOrPec')
          : '',
    },
    minLength: {
      value: sdiOrPecValue && sdiOrPecValue.includes('@') ? 3 : 7,
      message:
        sdiOrPecValue && sdiOrPecValue.includes('@') === false
          ? t('forms:errorsState.invalid.codeOrPec')
          : '',
    },
    validate: {
      value: (value) => {
        if (sdiOrPecValue && sdiOrPecValue.includes('@')) {
          return (
            isEmailValid(sdiOrPecValue) || t('forms:errorsState.invalid.email')
          );
        }
      },
    },
  });

  const { ref: provinceStateRef, ...provinceStateInputProps } = register(
    'provinceState',
    {
      required:
        selectedCountryIsAmerica || selectedCountryIsCanada
          ? t('forms:errorsState.required.generic')
          : false,
    }
  );

  const { ref: paCodeRef, ...paCodeInputProps } = register('paCode', {
    required: isPA && t('forms:errorsState.required.generic'),
    maxLength: {
      value: 6,
      message: t('forms:errorsState.invalid.paCode'),
    },
  });

  const normalizeFormData = (formData: any) => {
    if (sdiOrPec === userInfoPhase2?.codeOrPec) {
      delete formData.codeOrPec;
    }
    if (firstName === userInfoPhase2?.firstnameAndLastname) {
      delete formData.firstnameAndLastname;
    }
    if (countryRef === userInfoPhase2?.country) {
      delete formData.country;
    }
    if (countryRef !== '1') {
      delete formData.province;
      delete formData.paCode;
      delete formData.codeOrPec;
    }
    if (
      province === userInfoPhase2?.province &&
      countryRef === userInfoPhase2?.country
    ) {
      delete formData.province;
    }
    if (paCode === userInfoPhase2?.paCode) {
      delete formData.paCode;
    }
    if (provinceState === userInfoPhase2?.provinceState) {
      delete formData.provinceState;
    }
    if (vat === userInfoPhase2?.vatNumber) {
      delete formData.vatNumber;
    }
  };

  const onSubmitForm = (formStep2Data: any) => {
    setIsDirty({
      userType: formStep2Data.userType.length > 0,
      firstnameAndLastname: formStep2Data.firstnameAndLastname.length > 0,
      country: formStep2Data.country && formStep2Data.country.length > 0,
      fiscalCode: formStep2Data.fiscalCode && formStep2Data.fiscalCode.length > 0,
      vatNumber: formStep2Data?.vatNumber?.length > 0,
      paCode: formStep2Data.paCode && formStep2Data.paCode.length > 0,
      province: formStep2Data.province && formStep2Data.province.length > 0,
      isPublicAmministration:
        formStep2Data.isPublicAmministration &&
        formStep2Data.isPublicAmministration.length > 0,
      codeOrPec: formStep2Data.codeOrPec && formStep2Data.codeOrPec.length > 0,
      provinceState: formStep2Data.provinceState.length > 0,
    });
    const formData = {
      ...formStep2Data,
      ...{ isBusiness: !!isCompany },
      country: selectedCountryCode,
      province: selectedProvinceCode,
      isInEurope: selectedCountryIsInEurope,
    };

    normalizeFormData(formData);

    onSubmit(formData);
  };

  React.useEffect(() => {
    errorsFromApi
      ? errorsFromApi?.forEach((element: string) => {
        const message = element
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (t(`forms:errorsState.errorsFromApi.${element}`) as string)
          : t('forms:errorsState.errorsFromApi.generic');
        setError(element, { type: 'error', message });
      })
      : clearErrors();
  }, [setError, errorsFromApi, t, clearErrors]);

  return (
    <div className={styles.container}>
      <form key={2} onSubmit={handleSubmit(onSubmitForm as any)}>
        <div className={`${styles.inputWrapperContainer} ${styles.horizontal}`}>
          <label className={styles.inputLabel}>
            {t('forms:placeholder.userType')}
          </label>
          <div className={`${styles.inputWrapper} ${readOnly ? '' : styles.userType}`}>
            {(isPrivate || !readOnly) && (
              <div className={styles.inputRadio}>
                <div className="input-radio">
                  <label htmlFor="private">
                    <input
                      {...register('userType', {
                        required: false,
                      })}
                      type="radio"
                      name="userType"
                      value="0"
                      id="private"
                      defaultChecked={isPrivate}
                      onClick={showPrivate}
                      readOnly={readOnly}
                    />
                    <span className="input-list-label">
                      {t('forms:placeholder.private')}
                    </span>
                  </label>
                </div>
              </div>
            )}

            {(isCompany || !readOnly) && (
              <div className={styles.inputRadio}>
                <div className="input-radio">
                  <label htmlFor="company">
                    <input
                      {...register('userType', {
                        required: false,
                      })}
                      type="radio"
                      name="userType"
                      value="1"
                      id="company"
                      defaultChecked={isCompany}
                      onClick={showCompany}
                      readOnly={readOnly}
                    />
                    <span className="input-list-label">
                      {t('forms:placeholder.company')}
                    </span>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        {(isPrivate || isCompany) && (
          <>
            <Input
              inputSize={INPUT_SIZE.BIG}
              isFullWidth
              label={t('forms:label.firstnameAndLastname')}
              hasValue={isDirty?.firstnameAndLastname ?? false}
              type="text"
              placeholder={t('forms:placeholder.firstnameAndLastname')}
              inputRef={firstnameAndLastnameRef}
              {...firstnameAndLastnameInputProps}
              error={errors?.firstnameAndLastname?.message as any}
              isRequired
              isHorizontal
              defaultValue={get(userInfoPhase2, 'firstnameAndLastname')}
            />

            <div className={`container-input ${styles.horizontalSelect}`}>
              <div>
                <label className={styles.inputLabel}>
                  {t('forms:label.state')}
                  {'*'}
                </label>

                <Controller
                  control={control}
                  name="country"
                  rules={{
                    required: {
                      value: true,
                      message: t('forms:errorsState.required.country'),
                    },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <Select
                          options={
                            !isLoadingCountries && countries.length > 0
                              ? countries
                              : []
                          }
                          styles={getSelectCountryStyle(errors)}
                          placeholder={t('forms:placeholder.state')}
                          getOptionValue={(option) => option.code}
                          value={
                            value && !isLoadingCountries && countries.length > 0
                              ? countries.find((c: any) => c.code === value)
                              : ''
                          }
                          onChange={(val: any) => {
                            onChange(val?.code);
                            onCountrySelection(val?.code, val?.isInEurope);
                          }}
                          isClearable={!readOnly}
                          isSearchable={!readOnly}
                          menuIsOpen={readOnly ? false : undefined}
                          openMenuOnClick={!readOnly}
                        />
                      </>
                    );
                  }}
                />
              </div>
              <div className={styles.horizontalError}>
                {errors.country && (
                  <small className={styles.error}>
                    {errors.country?.message as any}
                  </small>
                )}
              </div>
            </div>

            {(selectedCountryIsItaly || selectedCountryIsInEurope) &&
              isCompany && (
                <Input
                  inputSize={INPUT_SIZE.BIG}
                  isFullWidth
                  label={t('forms:label.vatNumber')}
                  hasValue={isDirty?.vatNumber ?? false}
                  type="text"
                  placeholder={t('forms:placeholder.vatNumber')}
                  inputRef={vatNumberRef}
                  {...vatNumberInputProps}
                  error={errors?.vatNumber?.message as any}
                  prefix={vatNumberPrefix}
                  isRequired
                  isHorizontal
                  readOnly={readOnly}
                  defaultValue={get(userInfoPhase2, 'vatNumber')}
                />
              )}

            {(selectedCountryIsAmerica || selectedCountryIsCanada) && (
              <>
                <Input
                  inputSize={INPUT_SIZE.BIG}
                  isFullWidth
                  label={t('forms:label.provinceState')}
                  hasValue={isDirty?.provinceState ?? false}
                  type="text"
                  inputRef={provinceStateRef}
                  {...provinceStateInputProps}
                  error={errors?.provinceState?.message as any}
                  isHorizontal
                  defaultValue={get(userInfoPhase2, 'provinceState')}
                />
              </>
            )}

            {selectedCountryIsItaly && (
              <>
                <div className={`container-input ${styles.horizontalSelect}`}>
                  <div>
                    <label className={styles.inputLabel}>
                      {t('forms:label.province')}
                      {'*'}
                    </label>
                    <Controller
                      control={control}
                      name="province"
                      rules={{
                        required: {
                          value: selectedCountryIsItaly ? true : false,
                          message: t('forms:errorsState.required.generic'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <Select
                              options={
                                !isLoadingProvinces && provinces.length > 0
                                  ? provinces
                                  : []
                              }
                              styles={getSelectProvinceStyle(errors)}
                              isClearable={true}
                              isSearchable={true}
                              placeholder={t('forms:placeholder.province')}
                              getOptionValue={(option) => option.code}
                              value={
                                value &&
                                  !isLoadingProvinces &&
                                  provinces.length > 0
                                  ? provinces.find((c: any) => c.code === value)
                                  : 'AG'
                              }
                              onChange={(val: any) => {
                                onChange(val?.code);
                                onProvinceSelection(val?.code);
                              }}
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className={styles.horizontalError}>
                    {errors.province && (
                      <small className={styles.error}>
                        {errors.province?.message as any}
                      </small>
                    )}
                  </div>
                </div>
                <div className={`${styles.inputWrapperContainer} ${styles.horizontal}`}>
                  <label
                    style={{ marginBottom: '0' }}
                    className={styles.inputLabel}
                  >
                    {t('forms:placeholder.publicAmministration')}
                  </label>
                  <div className={styles.inputWrapper}>
                    {(!isPA || !readOnly) && (
                      <div className={`${styles.inputRadio} ${styles.isPa}`}>
                        <div className="input-radio">
                          <label htmlFor="isNotPa">
                            <input
                              {...register('isPublicAmministration', {
                                required: false,
                              })}
                              type="radio"
                              name="isPublicAmministration"
                              value="isNotPa"
                              id="isNotPa"
                              defaultChecked={!isPA}
                              onClick={hidePACode}
                              readOnly={readOnly}
                            />
                            <span className="input-list-label">
                              {t('forms:placeholder.no')}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                    {(isPA || !readOnly) && (
                      <div className={`${styles.inputRadio} ${styles.isPa}`}>
                        <div className="input-radio">
                          <label htmlFor="isPa">
                            <input
                              {...register('isPublicAmministration', {
                                required: false,
                              })}
                              type="radio"
                              name="isPublicAmministration"
                              value="isPa"
                              id="isPa"
                              onClick={showPACode}
                              defaultChecked={isPA}
                              readOnly={readOnly}
                            />
                            <span className="input-list-label">
                              {t('forms:placeholder.yes')}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {isPA && (
                  <Input
                    inputSize={INPUT_SIZE.BIG}
                    isFullWidth
                    label={t('forms:label.paCode')}
                    hasValue={isDirty?.paCode ?? false}
                    type="text"
                    placeholder={t('forms:placeholder.paCode')}
                    inputRef={paCodeRef}
                    {...paCodeInputProps}
                    error={errors?.paCode?.message as any}
                    isRequired
                    readOnly={readOnly}
                    isHorizontal
                    defaultValue={get(userInfoPhase2, 'paCode')}
                  />
                )}

                <Input
                  inputSize={INPUT_SIZE.BIG}
                  isFullWidth
                  label={t('forms:label.fiscalCode')}
                  hasValue={isDirty?.fiscalCode ?? false}
                  type="text"
                  placeholder={t('forms:placeholder.fiscalCode')}
                  inputRef={fiscalCodeRef}
                  {...fiscalCodeInputProps}
                  error={errors?.fiscalCode?.message as any}
                  isRequired={isPrivate && selectedCountryIsItaly}
                  readOnly={readOnly}
                  isHorizontal
                  defaultValue={get(userInfoPhase2, 'fiscalCode')}
                />

                <Input
                  inputSize={INPUT_SIZE.BIG}
                  isFullWidth
                  label={t('forms:label.codeOrPec')}
                  hasValue={
                    Boolean(
                      get(userInfoPhase2, 'codeOrPec') || isDirty?.codeOrPec
                    ) ?? false
                  }
                  type="text"
                  placeholder={t('forms:placeholder.codeOrPec')}
                  inputRef={codeOrPecRef}
                  {...codeOrPecInputProps}
                  error={errors?.codeOrPec?.message as any}
                  isHorizontal
                  defaultValue={get(userInfoPhase2, 'codeOrPec')}
                />
              </>
            )}
          </>
        )}

        <Button
          disabled={
            userInfoPhase2?.vatNumber === vat &&
            userInfoPhase2?.firstnameAndLastname === firstName &&
            userInfoPhase2?.country === countryRef &&
            userInfoPhase2?.codeOrPec === sdiOrPec &&
            userInfoPhase2?.province === province &&
            userInfoPhase2?.paCode === paCode &&
            userInfoPhase2?.provinceState === provinceState &&
            Boolean(userInfoPhase2?.paCode) === isPA
          }
        >
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner isWhite isSmall />
            </div>
          ) : (
            t('forms:update')
          )}
        </Button>
      </form>
      {isError && (
        <p className={styles.error}>{t('forms:errorsState.general.submit')}</p>
      )}
    </div>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getPayWithPaypal } from '@services/api';
import { noop } from 'lodash';
import { CheckPayWithPaypal } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
export const KEY = 'paypal-payment-check';

export default function useCheckPayWithPaypal(paymentId: any, enabled: boolean, onSuccess = noop, onError = noop) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    ...useQuery<CheckPayWithPaypal, Error>(
      [KEY, paymentId],
      () => getPayWithPaypal(currentLanguage, paymentId ?? ''),
      {
        enabled: Boolean(paymentId) && enabled,
        onError,
        onSuccess,
      }
    ),
  };
}

import * as React from 'react';
import styles from './styles.module.scss';
import Header from '@components/Header';
import { useTranslation } from 'react-i18next';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const { t } = useTranslation(['routes', 'common']);
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <LayoutPage>
        <LayoutSection noMarginBottom={true} isCentered>
          <div className={styles.container}>
            <h1>{t('common:notFound.title')}</h1>

            <Button onClick={() => navigate(t('routes:home'))}>
              {t('common:notFound.ctaLabel')}
            </Button>
          </div>
        </LayoutSection>
      </LayoutPage>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { BADGE_TYPE } from '@components/Badge';
import { PURCHASE_STATUS } from '@utils/types/purchase';
import { PAYMENT_METHODS } from '@utils/types/store';
import { SORT_FIELD_PURCHASE } from './types/licenses';

export const formatPurchasesStatus = (purchaseStatus: PURCHASE_STATUS) => {
  switch (purchaseStatus) {
    case PURCHASE_STATUS.ERROR:
      return { color: BADGE_TYPE.YELLOW, labelKey: 'purchases:statuses.canceled' };
    case PURCHASE_STATUS.SUCCESS:
      return { color: BADGE_TYPE.GREEN, labelKey: 'purchases:statuses.paid' };
    case PURCHASE_STATUS.WAITING:
      return { color: BADGE_TYPE.GREY, labelKey: 'purchases:statuses.pending' };
    default:
      return { color: BADGE_TYPE.SECONDARY, labelKey: 'purchases:statuses.unknown' };
  }
};

export const formatPaymentMethod = (paymentMethod: PAYMENT_METHODS) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.TRANSFER:
      return 'Transfer';
    case PAYMENT_METHODS.PAYPAL:
      return 'Pay Pal';
    case PAYMENT_METHODS.CREDIT_CARD:
      return 'Credit card';
    default:
      return 'Unknown';
  }
};

export const renderFieldName = (field: string) => {
  switch (field) {
    case 'id':
      return SORT_FIELD_PURCHASE.ID;
    case 'price':
      return SORT_FIELD_PURCHASE.AMOUNT_NO_VAT;
    case 'priceWithVAT':
      return SORT_FIELD_PURCHASE.AMOUNT;
    case 'paymentType':
      return SORT_FIELD_PURCHASE.PAY_TYPE;
    case 'activationDate':
      return SORT_FIELD_PURCHASE.PURCHASE_DATE;
    default:
      return 'id';
  }
};

import { multiply, sum } from 'lodash';
import { CalculateProductPriceParams } from './store';

export type WithChildren<T = Record<string, unknown>> = T & {
  children?: React.ReactNode;
};

export function calculateProductPriceFn({
  productPrice,
  profilePrice,
  subProductsPrices,
  connections,
  minAmount,
}: CalculateProductPriceParams) {
  //
  // Prezzo Finale =
  // Prezzo base + prezzo del profilo (se l'id è maggiore di zero ed è un profilo valido)
  // + ( prezzo del child * (quantità del child - minAmount del child))
  //
  //
  const sumOfSubproductPrice = subProductsPrices.map((price) =>
    multiply(price ?? 0, connections - minAmount)
  );

  return productPrice + Number(profilePrice) + sum(sumOfSubproductPrice);
}

export const formatDate = (date: Date) => {
  return (
    date?.toLocaleDateString('it', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) || ''
  );
};

export const forceToInt = (value: number | string) => {
  if (typeof value === 'string') {
    return Number(value.replaceAll('.', '').replaceAll(',', '.'));
  } else {
    return Number(value);
  }
};

export const forceToFloat = (value: number | string) => {
  if (typeof value === 'string') {
    return +value.replaceAll('.', '').replaceAll(',', '.');
  } else {
    return +value;
  }
};

export function formatPrice(value: number | string, currency = 'EUR', minDecimas = 2, maxDecimas = 2, showCurrency = true) {
  const formattedPrice = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency,
    minimumFractionDigits: minDecimas,
    maximumFractionDigits: maxDecimas,
  }).format(forceToFloat(value));

  if (showCurrency) {
    return formattedPrice;
  } else {
    return formattedPrice.replaceAll(' ', ' ').split(' ')[0];
  }
}

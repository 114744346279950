import styles from './styles.module.scss';

export enum AVATAR_SIZE {
  DEFAULT = 'default',
  BIG = 'big',
}

export enum AVATAR_TYPE {
  HIGHLIGHT = 'highlight',
  NO_PHOTO = 'noPhoto',
  DEFAULT = 'default',
}

type Props = {
  image: string;
  onClick?: () => void;
  size?: AVATAR_SIZE;
  type?: AVATAR_TYPE;
  name: string;
  isAvatarHighlighted: boolean;
};

export default function Avatar({
  isAvatarHighlighted = false,
  image,
  onClick,
  name,
  size = AVATAR_SIZE.DEFAULT,
  type = isAvatarHighlighted ? AVATAR_TYPE.HIGHLIGHT : AVATAR_TYPE.DEFAULT,
}: Props) {
  const initials =
    name &&
    name
      .split(' ')
      .reduce(
        (result, item, index, arr) =>
          arr?.length > 1
            ? `${result}${item.charAt(0)}`
            : `${item.charAt(0)}${item.charAt(1)}`,
        ''
      );
  return (
    <div
      onClick={onClick}
      className={`${styles.avatarWrapper} ${styles[size]} ${styles[type]}`}
      style={{ cursor: 'pointer' }}
    >
      {image ? (
        <img
          src={image}
          height={34}
          width={34}
          className={styles.avatar}
          alt={initials}
        />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
}

import useGetUserInfo from '@hooks/useUserInfo';
import { WithChildren } from '@utils/types/helpers';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { isEmpty, noop } from 'lodash';
import React from 'react';

const UserContext = React.createContext<{
  isLoggedIn: boolean;
  setIsLoggedIn: (setIsLoggedIn: boolean) => void;
  registrationCompleted: boolean;
}>({
  isLoggedIn: false,
  setIsLoggedIn: noop,
  registrationCompleted: false
});

const UserProvider = ({ children }: WithChildren) => {
  const [isLoggedIn = false, setIsLoggedIn] = useLocalStorage<boolean>(
    'user-logged',
    false
  );

  const [registrationCompleted, setRegistrationCompleted] = React.useState(false);

  const { data, isLoading } = useGetUserInfo(!!isLoggedIn);
  React.useEffect(() => {
    if (
      (isEmpty(data?.username) && !isLoading) ||
      (isEmpty(data?.email) && !isLoading)
    ) {
      setIsLoggedIn(false);
    }
    setRegistrationCompleted(data?.registrationComplete === true);
  }, [data, isLoading, setIsLoggedIn]);

  const setIsLoggedInFn = React.useCallback(
    (value: boolean) => {
      setIsLoggedIn(value);
    },
    [setIsLoggedIn]
  );

  return (
    <UserContext.Provider
      value={{ isLoggedIn: (!!isLoggedIn), setIsLoggedIn: setIsLoggedInFn, registrationCompleted: (!!registrationCompleted) }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };

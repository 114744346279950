import React from 'react';
import { UraniumFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';
import { renderUraniumFeaturesColumn } from '@components/UraniumAdvancedFeaturesTable';

function UraniumBasicFeaturesTable() {
  const { t } = useTranslation('uraniumBackup');

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const onWindowResize = useWindowResize();
  const hideColumn = windowWidth < 1200;

  onWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  const featuresNameBasic: UraniumFeaturesList[] = [
    {
      name: t(
        'compareLicenses.table.basicFunctionality.schedulerLogsAndEmailNotifications.title'
      ),

      free: true,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.basicFunctionality.synchronization.title'),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.basicFunctionality.encryption.title'),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.basicFunctionality.service.title'),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t(
        'compareLicenses.table.basicFunctionality.differentialAndIncrementalBackup.title'
      ),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.basicFunctionality.ftpBackup.title'),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t(
        'compareLicenses.table.basicFunctionality.backupDriveImage.title'
      ),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t('compareLicenses.table.basicFunctionality.cloudBackup.title'),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
    {
      name: t(
        'compareLicenses.table.basicFunctionality.backupToTapeLtfsMode.title'
      ),

      free: false,
      base: true,
      proTape: true,
      proDB: true,
      proShadow: true,
      proVirtual: true,
      gold: true,
    },
  ];

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.free, {
      id: 'free',
      cell: (info) => info.getValue(),
      header: () => <span>Free</span>,
    }),
    columnHelper.accessor((row) => row.base, {
      id: 'base',
      cell: (info) => info.getValue(),
      header: () => <span>Base</span>,
    }),
    columnHelper.accessor((row) => row.proTape, {
      id: 'proTape',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Tape</span>,
    }),
    columnHelper.accessor((row) => row.proDB, {
      id: 'proDB',
      cell: (info) => info.getValue(),
      header: () => <span>Pro DB</span>,
    }),
    columnHelper.accessor((row) => row.proShadow, {
      id: 'proShadow',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Shadow</span>,
    }),
    columnHelper.accessor((row) => row.proVirtual, {
      id: 'proVirtual',
      cell: (info) => info.getValue(),
      header: () => <span>Pro Virtual</span>,
    }),
    columnHelper.accessor((row) => row.gold, {
      id: 'gold',
      cell: (info) => info.getValue(),
      header: () => <span style={{ color: '#F2C94C' }}>Gold</span>,
    }),
  ];

  const featuresBasic = [...renderUraniumFeaturesColumn(featuresNameBasic)];

  return (
    <BasicTable
      product="uranium"
      isHeaderHidden={!hideColumn}
      isTitleRow
      data={featuresBasic}
      columns={versionColumns}
    />
  );
}

export default UraniumBasicFeaturesTable;

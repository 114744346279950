import { FieldErrors } from 'react-hook-form';

export const usernameRegex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ]{3,15}$/;
export const emailRegex = /^[_a-z0-9-]+([._a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,20}$/i;
export const taxCodeRegex = new RegExp(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/);
export const taxCodeCorporateRegex = new RegExp(/^\d{11}$/m);
export const zipCodeITARegex = new RegExp(/^[0-9]{5}$/);
export const telRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);

export function getSelectCountryStyle(errors: FieldErrors) {
  return {
    container: (base: any) => ({
      ...base,
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '0',
      borderColor: errors.country ? '#cd143c' : 'rgba(11, 40, 78, 0.25)',
      borderRadius: '100px',
      color: '#0b284e',
      boxShadow: state.isFocused
        ? 'rgba(11, 40, 78, 0.5) !important'
        : '0 0 0 40px #fff inset',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: !errors.country && '1px solid rgba(11, 40, 78, 0.25)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '45px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '21px',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: '#BDC5CF',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      backgroundColor: '#f4f8f8',
      fontSize: '16px',
      zIndex: '9999 !important',
      position: 'absolute',
    }),
    listBox: (base: any, state: any) => ({
      ...base,
      minHeight: '300px',
    }),
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: state.isSelected ? '#2d9cdb' : '#f4f8f8',
        color: 'rgba(11, 40, 78)',
        '&:hover': {
          backgroundColor: '#fff',
          cursor: 'pointer',
        },
      };
    },
  };
}

export function getSelectProvinceStyle(errors: FieldErrors) {
  return {
    container: (base: any) => ({
      ...base,
      marginTop: '0 !important',
      marginBottom: errors.province ? '0' : '16px !important',
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '0',
      borderColor: errors.province ? '#cd143c' : 'rgba(11, 40, 78, 0.25)',
      borderRadius: '100px',
      color: '#0b284e',
      boxShadow: state.isFocused
        ? 'rgba(11, 40, 78, 0.5) !important'
        : '0 0 0 40px #fff inset',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: !errors.province && '1px solid rgba(11, 40, 78, 0.25)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '45px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '21px',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: '#BDC5CF',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: '#f4f8f8',
      fontSize: '16px',
      zIndex: '9999 !important',
      position: 'absolute',
    }),
    listBox: (base: any) => ({
      ...base,
      minHeight: '300px',
    }),
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: state.isSelected ? '#2d9cdb' : '#f4f8f8',
        color: 'rgba(11, 40, 78)',
        '&:hover': {
          backgroundColor: '#fff',
          cursor: 'pointer',
        }
      };
    }
  };
}

export function getVatNumberPrefix(nationCode: string) {
  let vatNumberPrefix = '';
  switch (nationCode) {
    case '1':
      vatNumberPrefix = 'IT';
      break;
    case '765':
      vatNumberPrefix = 'SE';
      break;
    case '755':
      vatNumberPrefix = 'ES';
      break;
    case '750':
      vatNumberPrefix = 'SI';
      break;
    case '749':
      vatNumberPrefix = 'SK';
      break;
    case '737':
      vatNumberPrefix = 'RO';
      break;
    case '733':
      vatNumberPrefix = 'PT';
      break;
    case '732':
      vatNumberPrefix = 'PL';
      break;
    case '711':
      vatNumberPrefix = 'NL';
      break;
    case '691':
      vatNumberPrefix = 'MT';
      break;
    case '683':
      vatNumberPrefix = 'LU';
      break;
    case '682':
      vatNumberPrefix = 'LT';
      break;
    case '677':
      vatNumberPrefix = 'LV';
      break;
    case '662':
      vatNumberPrefix = 'IE';
      break;
    case '657':
      vatNumberPrefix = 'HU';
      break;
    case '643':
      vatNumberPrefix = 'EL';
      break;
    case '640':
      vatNumberPrefix = 'DE';
      break;
    case '633':
      vatNumberPrefix = 'FR';
      break;
    case '632':
      vatNumberPrefix = 'FI';
      break;
    case '627':
      vatNumberPrefix = 'EE';
      break;
    case '617':
      vatNumberPrefix = 'DK';
      break;
    case '616':
      vatNumberPrefix = 'CZ';
      break;
    case '614':
      vatNumberPrefix = 'CY';
      break;
    case '590':
      vatNumberPrefix = 'BG';
      break;
    case '578':
      vatNumberPrefix = 'BE';
      break;
    case '571':
      vatNumberPrefix = 'AT';
      break;
    case '613':
      vatNumberPrefix = 'HR';
      break;
    default:
      vatNumberPrefix = '';
      break;
  }
  return vatNumberPrefix;
}
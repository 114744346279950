import * as React from 'react';
import CartItem from '@components/CartItem';
import { CartContext } from '@contexts/CartContext';
import { CartItemType, CheckoutPrice } from '@utils/types/store';
import styles from './styles.module.scss';
import stylesCart from '../../pages/Cart/styles.module.scss';
import SkeletonBasic from '@components/SkeletonBasic';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@contexts/UserContext';
import { Link } from 'react-router-dom';
import SyncLoader from '@components/SyncLoader';
import Card from '@components/Card';
import Price from '@components/Price';

type Props = {
  hasStaticQuantity?: boolean;
  isLoadingCheckoutFromApi?: boolean;
  isLoadingGettingProduct?: boolean;
  products?: CartItemType[] | undefined;
  total?: CheckoutPrice | void;
};
export default function MiniCart({
  hasStaticQuantity = false,
  isLoadingCheckoutFromApi,
  total,
  isLoadingGettingProduct,
  products,
}: Props) {
  const { t } = useTranslation(['cart', 'routes', 'common']);
  const { cartItems, onDeleteCartItem, onUpdateQuantity } = React.useContext(CartContext);
  const { isLoggedIn, registrationCompleted } = React.useContext(UserContext);

  const onDeleteAllItemsFn = React.useCallback(
    (id: string) => {
      onDeleteCartItem(id);
    },
    [onDeleteCartItem]
  );

  const renderItems = React.useCallback(
    ({ hasStaticQuantity }: { hasStaticQuantity: boolean }) =>
      (item: CartItemType, key: number) => {
        // const itemPrice = item?.hideCounter
        //   ? item?.price / item?.quantity
        //   : item?.price;
        return (
          <div key={key} className={stylesCart.item}>
            <CartItem
              title={item.title}
              fromLicense={item?.fromLicense}
              description={item.description}
              price={item?.price}
              quantity={item.quantity}
              hasStaticQuantity={hasStaticQuantity}
              onAddItem={onUpdateQuantity(item?.localId)}
              onChangeItem={onUpdateQuantity(item?.localId)}
              onRemoveItem={onUpdateQuantity(item.localId)}
              onDeleteAllItems={() => onDeleteAllItemsFn(item?.localId)}
              hideCounter={item?.hideCounter}
              renewOrUpgrade={item?.renewOrUpgrade}
            />
          </div>
        );
      },
    [onUpdateQuantity, onDeleteAllItemsFn]
  );

  return (
    <div className={styles.container}>
      {cartItems?.length > 0 ? (
        <>
          <p className={styles.title}>{t('cart:title')}</p>
          <div className={styles.listContainer}>
            {isLoadingGettingProduct ? (
              <div style={{ marginTop: -24 }}>
                <SyncLoader size={10} color={'rgba(0,0,0,0.2)'} />
              </div>
            ) : (
              products?.map(renderItems({ hasStaticQuantity }))
            )}
          </div>
          <div className={`${stylesCart.summary} ${styles.summary}`}>
            <div className={stylesCart.summaryTitle}>
              <p className={`${!isLoggedIn ? styles.noMargin : ''}`}>
                {t('cart:summary.summary')}
              </p>
            </div>
            <>
              {isLoggedIn && registrationCompleted ? (
                <>
                  {(!(total?.totalWithoutTax && total?.totalWithoutTax !== '0') || (total?.discounts && total?.discounts?.length > 0)) && (
                    <div className={stylesCart.summaryRow}>
                      <p>{t('cart:summary.articles')}</p>
                      {isLoadingCheckoutFromApi ? (
                        <SkeletonBasic width={150} height={19} />
                      ) : (
                        <p>
                          <strong>
                            {total?.totalCount} {total?.currency}
                          </strong>
                        </p>
                      )}
                    </div>
                  )}

                  {total?.discounts?.map((discount, key) => (
                    <div key={key} className={stylesCart.summaryRow}>
                      <p>
                        {discount?.description} {discount?.percent}
                      </p>
                      <p>
                        <strong>
                          {discount.total} {total?.currency}
                        </strong>
                      </p>
                    </div>
                  ))}

                  {total?.totalWithoutTax && total?.totalWithoutTax !== '0' && (
                    <div className={stylesCart.summaryRow}>
                      <p>{t('cart:summary.totalWithoutTax')}</p>
                      {isLoadingCheckoutFromApi ? (
                        <SkeletonBasic width={120} height={19} />
                      ) : (
                        <p>
                          <strong>
                            {total?.totalWithoutTax} {total?.currency}
                          </strong>
                        </p>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Card>
                  <p className={styles.signupText}>
                    {t(isLoggedIn ? 'cart:completeSignUpText' : 'cart:signInOrSignUpText')}
                  </p>
                </Card>
              )}

              {total?.tax?.map((tax, key) => (
                <div key={key} className={stylesCart.summaryRow}>
                  {isLoadingCheckoutFromApi ? (
                    <SkeletonBasic width={120} height={19} />
                  ) : (
                    <p>
                      {tax?.description} {tax?.percent}
                    </p>
                  )}

                  {isLoadingCheckoutFromApi ? (
                    <SkeletonBasic width={120} height={19} />
                  ) : (
                    <p>
                      <strong>
                        {tax.total} {total?.currency}
                      </strong>
                    </p>
                  )}
                </div>
              ))}
            </>
            <div
              className={`${stylesCart.summaryRowTotal} ${styles.summaryRowTotal
                } ${!isLoggedIn ? styles.noMargin : ''}`}
            >
              <p>{t('cart:summary.total')}</p>
              {isLoadingCheckoutFromApi && !total?.total ? (
                <SkeletonBasic width={200} height={31} />
              ) : (
                <p>
                  <strong>{total?.total && <Price value={total?.total} />}</strong>
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.containerEmpty}>
          <h2>{t('cart:emptyCart')}</h2>
          <button>
            <Link to={t('routes:home')}>{t('common:shops.goToShop')}</Link>
          </button>
        </div>
      )}
    </div>
  );
}
